<template>
  <div
    :class="$style.projectCard"
    @click="$emit('click')"
  >
    <div :class="$style.header">
      <div :class="$style.leftIconsContainer">
        <cf-tooltip v-if="!hideStarIcon">
          <cf-span multiline>
            {{ $t('toStar') }}/{{ $t('unstar') }}
          </cf-span>
          <template #reference>
            <div :class="$style.iconWitheBackground">
              <cf-span
                v-if="project.starred"
                size="xl"
                class="icon-star-selected"
                clickable
                @click.stop="$emit('setStarred', !project.starred)"
              />
              <cf-span
                v-else
                size="xl"
                class="icon-star"
                color="grey"
                clickable
                @click.stop="$emit('setStarred', !project.starred)"
              />
            </div>
          </template>
        </cf-tooltip>
      </div>
      <div :class="$style.rightIconsContainer">
        <cf-tooltip>
          <cf-span multiline>
            {{ $tc('workspace.mentionsTooltipMessage', project.mentions) }}
          </cf-span>
          <template #reference>
            <div
              v-if="project.mentions"
              :class="$style.iconRedBackground"
            >
              <cf-span
                color="white"
                style="
                  display: grid;
                  justify-content: center;
                  align-content: center;
                "
              >
                @
              </cf-span>
            </div>
          </template>
        </cf-tooltip>
        <cf-tooltip>
          <cf-span multiline>
            {{
              $tc(
                'workspace.notificationsTooltipMessage',
                project.notifications
              )
            }}
          </cf-span>
          <template #reference>
            <div
              v-if="project.notifications"
              :class="$style.iconRedBackground"
            >
              <cf-span
                color="white"
                style="
                  display: grid;
                  justify-content: center;
                  align-content: center;
                "
              >
                {{ maxNumberText(project.notifications) }}
              </cf-span>
            </div>
          </template>
        </cf-tooltip>
      </div>
    </div>
    <ProjectCardTitle :name="project.name" />
    <ProjectCardCompany
      :name="project.company.name"
      :image="project.company.logo"
    />
    <div :class="$style.projectCard__cover">
      <cf-avatar
        :src="project.image"
        type="standard"
        imgStyle="max-height: 10rem; max-width: 10rem"
      />
    </div>
    <div :class="$style.footer">
      <cf-tooltip v-if="!!project.pendencies">
        <cf-span multiline>
          {{ $tc('workspace.pendenciesTooltipMessage', project.pendencies) }}
        </cf-span>
        <template #reference>
          <div :class="$style.iconWithNumber">
            <cf-span
              size="lg"
              class="icon-pendencies"
              color="red"
            />
            <cf-span
              :class="$style.iconNumber"
              color="red"
            >
              {{ maxNumberText(project.pendencies) }}
            </cf-span>
          </div>
        </template>
      </cf-tooltip>
      <cf-tooltip v-if="!!project.pendencies">
        <cf-span multiline>
          {{
            $tc(
              'workspace.latePendenciesTooltipMessage',
              project.latePendencies
            )
          }}
        </cf-span>
        <template #reference>
          <div :class="$style.iconWithNumber">
            <cf-span
              size="lg"
              class="icon-clock"
              :color="!!project.latePendencies ? 'red' : 'grey'"
            />
            <cf-span
              :class="$style.iconNumber"
              :color="!!project.latePendencies ? 'red' : 'grey'"
            >
              {{ maxNumberText(project.latePendencies) }}
            </cf-span>
          </div>
        </template>
      </cf-tooltip>
    </div>
  </div>
</template>

<script>
import ProjectCardTitle from './ProjectCardTitle'
import ProjectCardCompany from './ProjectCardCompany'
export default {
  components: {
    ProjectCardTitle,
    ProjectCardCompany
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    hideStarIcon: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    maxNumberText (value) {
      return value <= 99 ? value : '99+'
    }
  }
}
</script>
<style lang="scss" module>
.projectCard {
  padding: 1rem 1rem;
  width: 13rem;
  height: 20rem;
  border-radius: 0.5rem;
  border: 0.2rem solid getColor('grey-lighter');
  display: grid;
  grid-template-rows: min-content min-content min-content 1fr;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    margin-top: -1rem;
  }
}

.header {
  position: relative;
}

.leftIconsContainer {
  position: absolute;
  left: -2rem;
  top: -2.2rem;
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
}

.rightIconsContainer {
  position: absolute;
  right: -2rem;
  top: -2.2rem;
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
  justify-content: end;
}

.iconRedBackground {
  background-color: getColor('red');
  border-radius: 5rem;
  padding: 0.25rem 0.5rem 0 0.5rem;
  min-width: 1.5rem;
}

.projectCard__cover {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconWithNumber {
  display: flex;
  column-gap: 0.2rem;
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  overflow: hidden;
  column-gap: 0.5rem;
}
</style>
