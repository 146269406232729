const filterFunctions = {
  status: standardFilter,
  visibility: standardFilter,
  commentVisibility: commentVisibility,
  issueVisibility: simpleFilter,
  priority: standardFilter,
  creationPhase: standardFilter,
  resolutionPhase: standardFilter,
  starred: issueStarredFilter,
  issueDisciplines: issueDisciplinesFilter,
  locals: localsFilter,
  labels: labelsFilter,
  editedAt: editedAtFilter,
  resolutionDate: resolutionDateFilter,
  deadline: deadlineFilter,
  deadlineDate: deadlineDateFilter,
  createdBy: createdByFilter,
  createdAt: createdAtFilter,
  publishedBy: publishedByFilter,
  publishedAt: publishedAtFilter,
  editedBy: editedByFilter,
  mentionedUsers: mentionsFilter,
  groupId: groupFilter,
  notifications: notificationsFilter,
  userTags: tagsFilter,
  category: standardFilter,
  ccodeDocumentsReferences: ccodeDocumentsReferencesFilter
}

const clearedCustomFilters = {
  createdBy: [],
  createdAt: null,
  publishedBy: [],
  visibility: [],
  groupId: [],
  publishedAt: null,
  editedBy: [],
  editedAt: null,
  creationPhase: [],
  resolutionPhase: [],
  resolutionDate: null,
  starred: [],
  notifications: [],
  userTags: [],
  tagsFilterType: 'or',
  userTagsDontContains: false,
  priority: [],
  status: [],
  issueDisciplines: {
    disciplines: [],
    selectionType: 'or',
    dontContains: false,
    status: [],
    deadline: null,
    doneAt: null
  },
  mentionedUsers: [],
  localsFilterType: 'or',
  localsDontContains: false,
  locals: [],
  localsLeaves: [],
  labelsFilterType: 'or',
  labelsDontContains: false,
  labels: [],
  labelsLeaves: [],
  deadline: [],
  deadlineDate: null,
  category: [],
  ccodeDocumentsReferences: [],
  ccodeDocumentsReferencesFilterType: 'or'
}

const clearedCommentsFilters = {
  commentVisibility: ['creator', 'group', 'project_management', 'public']
}

const clearedHistoryFilters = {
  issueVisibility: []
}

function standardFilter (object, filters, filter) {
  if (filters[filter].includes(object[filter]) || filters[filter].length === 0) { return true } else return false
}

function simpleFilter (object, filters, filter) {
  if (filters[filter].includes(object[filter])) return true
  else return false
}

function issueStarredFilter (issue, filters, filter) {
  if (
    filters[filter].includes(issue.userPersonalData.starred) ||
    filters[filter].length === 0
  ) { return true } else return false
}

function commentVisibility (comment, filters, filter) {
  if (filters.commentVisibility.includes(comment.visibility)) return true
  else return false
}

function multiSelectionFilter (
  issueFieldValues,
  filterFieldValues,
  selectionType,
  notContaining = false
) {
  let result = false
  for (const filterFieldValue of filterFieldValues) {
    const selectEmpty = filterFieldValue === 'empty'
    const issueFieldValuesEmpty = issueFieldValues.length === 0
    result = (selectEmpty && issueFieldValuesEmpty) || issueFieldValues.includes(filterFieldValue)

    if (notContaining) result = !result

    if (result === true && selectionType === 'or') break
    if (result === false && selectionType === 'and') break
  }
  return result
}

function issueDisciplinesFilter (issue, filters, filter) {
  if (filters.issueDisciplines.dontContains) {
    return issueDisciplinesDontContainsFilter(issue, filters, filter)
  } else {
    return issueDisciplinesContainsFilter(issue, filters, filter)
  }
}

function issueDisciplinesContainsFilter (issue, filters, filter) {
  if (filters.issueDisciplines.disciplines.includes('empty') && issue.disciplines.length === 0) return true
  if (
    filters.issueDisciplines.disciplines.length === 0 &&
    filters.issueDisciplines.status.length === 0 &&
    (!filters.issueDisciplines.deadline ||
      !filters.issueDisciplines.deadline.length) &&
    (!filters.issueDisciplines.doneAt ||
      !filters.issueDisciplines.doneAt.length)
  ) { return true }

  let disciplinesToEval = issue.disciplines

  if (filters.issueDisciplines.disciplines.length) {
    disciplinesToEval = issue.disciplines.filter((issueDiscipline) =>
      filters.issueDisciplines.disciplines.includes(
        issueDiscipline.disciplineId
      )
    )
    if (
      filters.issueDisciplines.selectionType === 'or' &&
      disciplinesToEval.length === 0
    ) { return false }
    if (
      filters.issueDisciplines.selectionType === 'and' &&
      disciplinesToEval.length !== filters.issueDisciplines.disciplines.length
    ) { return false }
  }

  function evalDiscipline (disciplineToEval) {
    if (
      filters.issueDisciplines.status.length &&
      !filters.issueDisciplines.status.includes(disciplineToEval.status)
    ) { return false }
    if (
      filters.issueDisciplines.deadline &&
      filters.issueDisciplines.deadline.length
    ) {
      const isInFilter = dateFilter(
        disciplineToEval.deadline,
        filters.issueDisciplines.deadline
      )
      if (!isInFilter) return false
    }
    if (
      filters.issueDisciplines.doneAt &&
      filters.issueDisciplines.doneAt.length
    ) {
      const isInFilter = dateFilter(
        disciplineToEval.doneAt,
        filters.issueDisciplines.doneAt
      )
      if (!isInFilter) return false
    }

    return true
  }

  return disciplinesToEval.some(evalDiscipline)
}

function issueDisciplinesDontContainsFilter (issue, filters, filter) {
  if (filters.issueDisciplines.disciplines.length === 0) return true
  if (filters.issueDisciplines.disciplines.includes('empty') && issue.disciplines.length !== 0) return true
  const issueDisciplinesIds = issue.disciplines.map(({ disciplineId }) => disciplineId)
  return multiSelectionFilter(
    issueDisciplinesIds,
    filters.issueDisciplines.disciplines,
    filters.issueDisciplines.selectionType,
    true
  )
}

function localsFilter (issue, filters, filter) {
  if (filters[filter].length === 0) return true
  const issueLocalsIds = issue[filter].map((local) => local.localId)
  return multiSelectionFilter(
    issueLocalsIds,
    filters.localsLeaves,
    filters.localsFilterType,
    filters.localsDontContains
  )
}

function labelsFilter (issue, filters, filter) {
  if (filters[filter].length === 0) return true
  const issueLabelsIds = issue[filter].map((label) => label.labelId)
  return multiSelectionFilter(
    issueLabelsIds,
    filters.labelsLeaves,
    filters.labelsFilterType,
    filters.labelsDontContains
  )
}

function ccodeDocumentsReferencesFilter (issue, filters, filter) {
  if (filters[filter].length === 0) return true
  const issueCcodeDocumentsReferences = issue[filter].map(
    (ccodeDocumentReference) => ccodeDocumentReference.title
  )
  return multiSelectionFilter(
    issueCcodeDocumentsReferences,
    filters.ccodeDocumentsReferences,
    filters.ccodeDocumentsReferencesFilterType
  )
}

function publishedByFilter (issue, filters, filter) {
  if (filters[filter].length === 0) return true
  if (
    filters.publishedBy.includes(issue.visibilityUpdatedByUserId) ||
    (!issue.visibilityUpdatedByUserId &&
      filters.publishedBy.includes(issue.createdByUserId))
  ) { return true } else return false
}

function createdByFilter (issue, filters, filter) {
  if (filters[filter].length === 0) return true
  if (filters.createdBy.includes(issue.createdByUserId)) return true
  else return false
}

function editedByFilter (issue, filters, filter) {
  if (filters[filter].length === 0) return true
  if (filters.editedBy.includes(issue.editedByUserId)) return true
  else return false
}

function mentionsFilter (issue, filters, filter) {
  if (filters[filter].length === 0) return true
  return multiSelectionFilter(issue[filter], filters[filter], 'or')
}

function groupFilter (issue, filters, filter) {
  if (filters.visibility.includes('group')) { return standardFilter(issue, filters, filter) } else return true
}

function notificationsFilter (issue, filters, filter) {
  const hasNotifications = issue.notificationsSummary.count > 0
  const hasMentions = issue.notificationsSummary.mentions > 0
  const shouldShow =
    filters[filter].length === 0 ||
    (filters[filter].includes('mentions') && hasMentions) ||
    (filters[filter].includes('withNotifications') && hasNotifications) ||
    (filters[filter].includes('noNotifications') && !hasNotifications)
  return shouldShow
}

function publishedAtFilter (issue, filters, filter) {
  if (!filters[filter] || filters[filter].length === 0) return true
  return dateFilter(issue.visibilityUpdatedAt, filters[filter])
}

function createdAtFilter (issue, filters, filter) {
  if (!filters[filter] || filters[filter].length === 0) return true
  return dateFilter(issue.createdAt, filters[filter])
}

function editedAtFilter (issue, filters, filter) {
  if (!filters[filter] || filters[filter].length === 0) return true
  return dateFilter(issue[filter], filters[filter])
}

function resolutionDateFilter (issue, filters, filter) {
  if (!filters[filter] || filters[filter].length === 0) return true
  if (issue.status !== 'active') { return dateFilter(issue.statusUpdatedAt, filters[filter]) } else return false
}

function deadlineFilter (issue, filters, filter) {
  if (!filters[filter] || filters[filter].length === 0) return true
  const hasDeadline = !!issue.deadline
  return filters[filter].includes(hasDeadline)
}

function deadlineDateFilter (issue, filters, filter) {
  if (
    !filters[filter] ||
    filters[filter].length === 0 ||
    !filters.deadline.includes(true)
  ) { return true }
  return dateFilter(issue.deadline, filters[filter])
}

function dateFilter (issueFieldValues, filterFieldValues) {
  if (filterFieldValues && filterFieldValues.length) {
    const startDate = new Date(filterFieldValues[0])
    const endDate = new Date(filterFieldValues[1])
    endDate.setHours(23, 59, 59, 999)
    const currentDate = new Date(issueFieldValues)
    return (
      currentDate.getTime() <= endDate.getTime() &&
      currentDate.getTime() >= startDate.getTime()
    )
  } else return true
}

function tagsFilter (issue, filters, filter) {
  if (filters[filter].length === 0) return true
  const issueTagsIds = issue.userPersonalData.tags
    .map((issueTag) => issueTag.tag && issueTag.tag.id)
    .filter(Boolean)
  return multiSelectionFilter(
    issueTagsIds,
    filters[filter],
    filters.tagsFilterType,
    filters.userTagsDontContains
  )
}

function groupBy (array, key, makeCurrentKey) {
  return array.reduce((accumulated, item) => {
    const currentKey = makeCurrentKey(item, key)
    return {
      ...accumulated,
      [currentKey]: [...(accumulated[currentKey] || []), item]
    }
  }, {})
}

export {
  filterFunctions,
  clearedCustomFilters,
  clearedCommentsFilters,
  clearedHistoryFilters,
  standardFilter,
  multiSelectionFilter,
  issueDisciplinesFilter,
  localsFilter,
  labelsFilter,
  publishedByFilter,
  editedByFilter,
  mentionsFilter,
  dateFilter,
  groupBy
}
