<template>
  <FixedHeaderFooterLayout v-loading="loading">
    <template #header>
      <div
        v-if="avatar || title || iconManual || editable"
        :class="$style.header"
      >
        <div
          :class="$style.titleDiv"
          style="align-items: center"
        >
          <cf-avatar
            v-if="avatar"
            :src="avatar.src"
            :type="avatar.type"
            :circle="avatar.circle"
            imgStyle="max-height: 3rem; margin-right: 1rem"
          />
          <cf-span
            size="xl"
            semibold
          >
            {{ title }}
          </cf-span>
          <a
            v-if="iconManual"
            :class="['icon-manual', $style.manual, 'cf-text__xl']"
            target="_blank"
            :href="manualLink"
          />
        </div>
        <div :class="$style.headerAction">
          <div
            v-if="labels.primary && !editing && editable"
            style="padding-right: 1.5rem"
            :class="$style.buttonsContainer"
          >
            <slot name="headerExtraElements" />
            <cf-button
              v-if="labels.secondary"
              secondary
              :mobileIcon="secondaryIcon"
              :mobileIconSize="mobileIconSize"
              :class="$style.button"
              @click="secondaryClick"
            >
              {{ labels.secondary }}
            </cf-button>
            <cf-button
              :mobileIcon="primaryIcon"
              :mobileIconSize="mobileIconSize"
              :class="$style.button"
              @click="primaryClick"
            >
              {{ labels.primary }}
            </cf-button>
          </div>
          <cf-searchbar
            v-if="search"
            :expansive="expansiveSearchbar"
            @input="searchInput($event)"
          />
          <cf-span
            v-if="closeIcon"
            style="height: min-content"
            :class="['icon-no', $style.close]"
            @click.native="closeClick()"
          />
        </div>
      </div>
    </template>
    <template #content>
      <slot name="default" />
    </template>
    <template #footer>
      <div
        v-if="editing"
        :class="$style.footer"
      >
        <div :class="$style.buttonsContainer">
          <cf-button
            secondary
            :mobileIcon="editSecondaryIcon"
            :mobileIconSize="mobileIconSize"
            :disabled="editSecondaryLabelDisabled"
            :class="$style.button"
            @click="editSecondaryClick"
          >
            {{ labels.editSecondary }}
          </cf-button>
          <cf-button
            :mobileIcon="editPrimaryIcon"
            :mobileIconSize="mobileIconSize"
            :class="$style.button"
            :disabled="editPrimaryLabelDisabled"
            @click="editPrimaryClick"
          >
            {{ labels.editPrimary }}
          </cf-button>
        </div>
      </div>
    </template>
  </FixedHeaderFooterLayout>
</template>
<script>
import FixedHeaderFooterLayout from './FixedHeaderFooterLayout'
export default {
  components: {
    FixedHeaderFooterLayout
  },
  props: {
    avatar: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    closeIcon: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    primaryLabel: {
      type: String,
      default: null
    },
    primaryIcon: {
      type: String,
      default: null
    },
    secondaryLabel: {
      type: String,
      default: null
    },
    secondaryIcon: {
      type: String,
      default: null
    },
    search: {
      type: Boolean,
      default: false
    },
    editPrimaryLabel: {
      type: String,
      default: null
    },
    editPrimaryLabelDisabled: {
      type: Boolean,
      default: false
    },
    editPrimaryIcon: {
      type: String,
      default: null
    },
    editSecondaryLabel: {
      type: String,
      default: null
    },
    editSecondaryLabelDisabled: {
      type: Boolean,
      default: false
    },
    editSecondaryIcon: {
      type: String,
      default: null
    },
    editing: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    validated: {
      type: Boolean,
      default: true
    },
    expansiveSearchbar: {
      type: Boolean,
      default: false
    },
    iconManual: {
      type: Boolean,
      default: false
    },
    manualLink: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      inputText: '',
      mobileIconSize: 'md'
    }
  },
  computed: {
    labels () {
      return {
        primary: this.primaryLabel || this.$t('add'),
        secondary: this.secondaryLabel,
        editPrimary: this.editPrimaryLabel || this.$t('save'),
        editSecondary: this.editSecondaryLabel || this.$t('cancel')
      }
    }
  },
  methods: {
    primaryClick () {
      this.$emit('primaryClick')
    },
    secondaryClick () {
      this.$emit('secondaryClick')
    },
    searchInput (text) {
      this.$emit('searchInput', text)
    },
    editPrimaryClick () {
      this.$emit('editPrimaryClick')
    },
    editSecondaryClick () {
      this.$emit('editSecondaryClick')
    },
    closeClick () {
      this.$emit('closeClick')
    }
  }
}
</script>
<style lang="scss" module>
.header {
  display: grid;
  justify-content: space-between;
  grid-template: auto / auto auto;
  align-items: center;
  padding: 1.5rem;
}
.close {
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  &:hover {
    color: getColor('grey');
  }
}
.buttonsContainer {
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  align-content: center;
  align-items: center;
  column-gap: 1rem;
  padding: 1rem;
}
.footer {
  display: grid;
  grid-template: auto 1fr / 1fr;
  overflow: hidden;
}
.titleDiv {
  display: grid;
  grid-template: auto / auto auto auto;
}
.headerAction {
  display: grid;
  grid-template: auto / auto auto auto;
  align-items: center;
}
.manual {
  padding-left: 1rem;
  color: getColor('grey');
  text-decoration: none;
  &:hover {
    color: getColor('black');
    cursor: pointer;
  }
}
</style>
