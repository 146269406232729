import Vue from 'vue'
import {
  Badge,
  Collapse,
  CollapseItem,
  DatePicker,
  Drawer,
  Button,
  InputNumber,
  Option,
  Pagination,
  Cascader,
  Select,
  Input,
  Popover,
  Slider,
  ColorPicker,
  Tooltip,
  Progress,
  Tree,
  Message,
  Loading
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import lang from 'element-ui/lib/locale/lang/pt-br'
import locale from 'element-ui/lib/locale'

locale.use(lang)
Vue.prototype.$loading = Loading.service
Vue.prototype.$message = Message

Vue.use(Loading.directive)

Vue.use(Badge)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(DatePicker)
Vue.use(Drawer)
Vue.use(Button)
Vue.use(InputNumber)
Vue.use(Option)
Vue.use(Pagination)
Vue.use(Cascader)
Vue.use(Select)
Vue.use(Input)
Vue.use(Popover)
Vue.use(Slider)
Vue.use(ColorPicker)
Vue.use(Tooltip)
Vue.use(Progress)
Vue.use(Tree)
