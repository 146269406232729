import issuesRoutes from '../modules/issues/routes'
import documentsRoutes from '../modules/documents/routes'
import setupRoutes from '../modules/setup/routes'
import modelViewerRoutes from '../modules/modelViewer/routes'
const ProjectView = () => import('../views/ProjectView')
export default [
  {
    path: 'project/:projectId',
    name: 'project',
    component: ProjectView,
    redirect: { name: 'projectIssues' },
    meta: { projectIcons: true },
    children: [
      ...setupRoutes,
      ...issuesRoutes,
      ...modelViewerRoutes,
      ...documentsRoutes
    ]
  }
]
