<template>
  <div :class="$style.central">
    <div
      v-if="expansive"
      :class="$style.expansiveLayout"
    >
      <input
        v-model="search"
        :style="'width: ' + width"
        :placeholder="placeholder"
        type="text"
        :class="$style.expansive"
        name="expansive"
      >
    </div>
    <div
      v-else
      :class="[$style.flex, $style.inputGroup]"
    >
      <input
        v-model="search"
        :style="'width: ' + width"
        :class="$style.input"
        type="text"
        :placeholder="placeholder"
      >
      <cf-span
        size="md"
        :class="[$style.icon, $style.flex, 'icon-search']"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customClass: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    expansive: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      search: ''
    }
  },
  watch: {
    search: function () {
      this.$emit('input', this.search)
    },
    value: function () {
      this.ruleForm = this.value
    }
  }
}
</script>
<style lang="scss" module>
.central {
  align-items: center;
  display: flex;
  padding-top: 0.2rem;
}
.flex {
  display: flex;
}
.inputGroup {
  width: 100%;
  margin-bottom: 5px;
}

.input {
  padding-left: 1rem;
  border-right: none;
  border: 1px solid getColor('grey-lighter');
  -webkit-text-fill-color: getColor('grey');
  border-right: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 1.4rem;
}

.icon {
  padding: 0.9rem;
  border: 1px solid getColor('grey-lighter');
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  align-items: center;
}

.expansive {
  color: getColor('black');
  background: url('./../assets/search.png') no-repeat center right;
  border: none;
  width: 3rem;
  height: 3rem;
  -webkit-transition: all 0.3s linear;
  background-position: right 10px top 5px;
  outline: none;
  border: 0.1rem solid transparent;
  cursor: pointer !important;
  padding-right: 3rem;
  border-radius: 0.5rem;
}
.expansive:focus {
  width: 100%;
  outline: none;
  border: 0.1rem solid getColor('grey');
  cursor: unset !important;
}
.expansiveLayout {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
