export default {
  setInviteList: (state, inviteList) => {
    state.inviteList = inviteList
  },
  setprojectsList: (state, projectsList) => {
    state.projectsList = projectsList
  },
  clearProjectListAndInvite: (state) => {
    state.inviteList = []
    state.projectsList = []
  }
}
