<template>
  <cf-tooltip v-if="tooltipText">
    <cf-span
      multiline
      size="xs"
    >
      {{ tooltipText }}
    </cf-span>
    <template #reference>
      <el-option
        :label="label"
        :value="value"
        :disabled="disabled"
      >
        <slot />
      </el-option>
    </template>
  </cf-tooltip>
  <el-option
    v-else
    :label="label"
    :value="value"
    :disabled="disabled"
  >
    <slot />
  </el-option>
</template>
<script>
export default {
  props: {
    label: undefined,
    value: undefined,
    disabled: Boolean,
    tooltipText: undefined
  }
}
</script>
