<template>
  <div style="width: fit-content">
    <cf-tooltip
      trigger="manual"
      :placement="placement"
      :visible="tooltipVisible"
    >
      <div :class="$style.content">
        <cf-span
          semibold
          size="lg"
          color="black"
        >
          Selecionar projeto{{ multiSelect ? 's' : '' }}
        </cf-span>
        <el-input
          v-model="search"
          suffixIcon="el-icon-search"
          size="mini"
        />
        <div style="overflow-y: auto">
          <cf-profile
            v-for="project in filteredProjects"
            :key="project.id"
            type="project"
            :class="$style.projectListItem"
            :src="project.image"
            :circle="false"
            :name="project.name"
            :color="isSelected(project.id) ? 'primary' : 'black'"
            @click.native="onProjectClick(project.id)"
          />
        </div>
      </div>
      <template #reference>
        <div
          :class="$style.referenceDiv"
          @click="tooltipVisible = !tooltipVisible"
        >
          <cf-span v-if="label">
            {{ label }}
          </cf-span>
          <div
            style="
              display: grid;
              grid-template-columns: 1fr min-content;
              column-gap: 1rem;
            "
          >
            <cf-profile
              v-if="!!selectedProjects.length"
              :circle="false"
              type="project"
              :src="selectedProjects[0].image"
              :name="selectedProjects[0].name"
            />
            <cf-span
              v-if="selectedProjects.length > 1"
              color="grey"
            >
              (+{{ selectedProjects.length - 1 }})
            </cf-span>
          </div>
          <cf-span
            v-if="!selectedProjects || !selectedProjects.length"
            italic
            color="grey"
          >
            {{ $t('select') }}
          </cf-span>
        </div>
      </template>
    </cf-tooltip>
  </div>
</template>

<script>
import loggedUserMixin from '@/mixins/logged-user.js'
export default {
  mixins: [loggedUserMixin],
  props: {
    label: {
      type: String,
      default: null
    },
    projects: {
      type: Array,
      required: true
    },
    value: {
      type: null,
      default: null
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'bottom'
    }
  },
  data () {
    return {
      search: '',
      selectedProjects: [],
      tooltipVisible: false
    }
  },
  computed: {
    filteredProjects () {
      if (!this.projects) return []
      return this.projects.filter(
        (project) =>
          !this.search ||
          project.name.toLowerCase().includes(this.search.toLowerCase()) ||
          project.company.name.toLowerCase().includes(this.search.toLowerCase())
      )
    }
  },
  watch: {
    value (newValue) {
      const selectedProjectsIds = this.multiSelect ? this.value : [this.value]
      this.selectedProjects = selectedProjectsIds.map((projectId) => {
        return this.projects.find((project) => project.id === projectId)
      })
    }
  },
  methods: {
    onProjectClick (projectId) {
      if (this.multiSelect) {
        const selectedProjectsIds = JSON.parse(JSON.stringify(this.value))
        const projectIdCurrentPosition = selectedProjectsIds.findIndex(
          (currentProjectId) => currentProjectId === projectId
        )
        if (projectIdCurrentPosition === -1) {
          selectedProjectsIds.push(projectId)
        } else {
          selectedProjectsIds.splice(projectIdCurrentPosition, 1)
        }
        this.$emit('input', selectedProjectsIds)
      } else {
        this.tooltipVisible = false
        this.$emit('input', projectId)
      }
    },
    isSelected (projectId) {
      if (this.multiSelect) return this.value.includes(projectId)
      else return projectId === this.value
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.spanBtn {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  span:hover {
    text-decoration: underline currentColor;
    cursor: pointer;
  }
  span + span {
    margin-left: 0.5rem;
  }
}
.content {
  display: grid;
  grid-template-rows: min-content min-content 1fr;
  max-height: 30rem;
  overflow: hidden;
}
.projectListItem {
  margin: 1.5rem 0;
  cursor: pointer;
}

.referenceDiv {
  overflow: hidden;
  cursor: pointer;
}
</style>
