import * as setupService from '../services/setup-service'

export default {
  async getProjectCompanies (context, { projectId, allCompanies }) {
    context.commit('setProjectCompanies', [])
    const response = await setupService.getProjectCompanies(projectId)
    const invites = response.invites.map((invite) => {
      return {
        isInvite: true,
        ...invite
      }
    })
    const companies = [...invites, ...response.companies]
    context.commit('setProjectCompanies', companies)
  }
}
