<template>
  <div
    v-if="currentBanner"
    :class="$style.container"
    :type="type"
  >
    <a
      :href="currentBanner.destination"
      target="_blank"
      @click="bannerClicked"
    >
      <img
        style="max-width: 100%; max-height: 100%"
        :src="currentBanner[type]"
      >
    </a>
  </div>
</template>

<script>
import bannersService from '@/services/banners-service'
export default {
  props: {
    type: {
      // horizontal or square
      type: String,
      required: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      banners: [],
      currentBanner: null,
      currentBannerIndex: null,
      nextBannerInterval: null
    }
  },

  async created () {
    const banners = await bannersService.getBanners()
    this.banners = banners.filter((banner) => !!banner[this.type])

    if (!this.hidden && this.banners.length) {
      this.currentBannerIndex = Math.floor(Math.random() * this.banners.length)
      if (this.banners.length > 1) { this.nextBannerInterval = setInterval(this.nextBanner, 10000) }
    }
    this.printBanner()
  },

  beforeDestroy () {
    if (this.nextBannerInterval) clearInterval(this.nextBannerInterval)
  },

  methods: {
    async bannerClicked () {
      this.createBannerLog('click')
    },

    async printBanner () {
      this.currentBanner = this.banners[this.currentBannerIndex]
      // this.createBannerLog('print')
    },

    async createBannerLog (type) {
      const { projectId } = this.$route.params
      const { clientId, issueId } = this.$route.query
      const logData = {
        type,
        title: this.currentBanner ? this.currentBanner.title : 'blank',
        details: {
          source:
            clientId === '2qvshuend9668etklokeccg7lj' ? 'ccode' : 'construflow',
          route: this.$route.name,
          projectId: projectId ? +projectId : null,
          issueId: issueId ? +issueId : null,
          bannersVersion: 2
        }
      }
      await bannersService.createBannerLog({ logData })
    },

    nextBanner () {
      if (document.visibilityState === 'hidden') return
      if (this.currentBannerIndex === this.banners.length - 1) { this.currentBannerIndex = 0 } else this.currentBannerIndex++
      this.printBanner()
    }
  }
}
</script>

<style lang="scss" module>
.container {
  overflow: hidden;
  display: grid;
  align-content: center;
  justify-content: center;
}
</style>
