import apollo from '@/plugins/apollo'

import getBannersQuery from './graphql/queries/getBannersQuery.gql'
import createBannerLogMutation from './graphql/mutations/createBannerLogMutation.gql'

const getBanners = async () => {
  const response = await apollo.query({
    query: getBannersQuery,
    fetchPolicy: 'cache-first'
  })
  return response.data.banners
}

const createBannerLog = async (variables) => {
  await apollo.mutate({
    mutation: createBannerLogMutation,
    variables
  })
}

export default { getBanners, createBannerLog }
