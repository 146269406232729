import { filterFunctions, groupBy } from '../utils/filter-utils'
import imgFunctions from '@/utils/ImgFunctions'
import accents from 'remove-accents'

export default {
  filteredCommentsAndHistory: ({
    selectedIssueComments,
    selectedIssueHistory,
    commentsFilters,
    historyFilters
  }) => {
    selectedIssueComments.forEach((comment) => {
      comment.dataTime = comment.visibilityUpdatedAt
      comment.visible = true
      comment.images.forEach((img) => {
        img.type = imgFunctions.getFileType(img.original)
      })

      for (const filter in commentsFilters) {
        const visible = filterFunctions[filter]
          ? filterFunctions[filter](comment, commentsFilters, filter)
          : true
        if (!visible) {
          comment.visible = false
          return
        }
      }
    })

    selectedIssueHistory.forEach((history) => {
      history.visible = true
      for (const filter in historyFilters) {
        const visible = filterFunctions[filter]
          ? filterFunctions[filter](history, historyFilters, filter)
          : true
        if (!visible) {
          history.visible = false
          return
        }
      }
    })

    let commentsHistory = [...selectedIssueComments, ...selectedIssueHistory]

    commentsHistory.sort((a, b) => {
      return new Date(a.dataTime) - new Date(b.dataTime)
    })

    if (commentsHistory.length > 0) {
      const firstItemWithNotification = commentsHistory.find(
        (item) => item.hasNotification
      )
      if (firstItemWithNotification) { firstItemWithNotification.scrollToThis = true } else commentsHistory[commentsHistory.length - 1].scrollToThis = true
    }

    commentsHistory = groupBy(
      commentsHistory,
      'dataTime',
      (comment, dataTime) => {
        const dateAux = new Date(comment[dataTime])
        return (
          dateAux.getMonth() +
          1 +
          '/' +
          dateAux.getDate() +
          '/' +
          dateAux.getFullYear()
        )
      }
    )

    return commentsHistory
  },

  searchedIssues ({
    filteredIssues,
    searchIssues,
    searchIssuesWholeExpression,
    smartFilterIssuesIds
  }) {
    if (smartFilterIssuesIds) {
      return filteredIssues.filter((issue) =>
        smartFilterIssuesIds.includes(issue.id)
      )
    }

    if (!searchIssues) return filteredIssues

    const searchExpression = accents.remove(searchIssues.toLowerCase())
    const isWholeExpression =
      searchExpression.startsWith('"') && searchExpression.endsWith('"')
    const searchWords = isWholeExpression
      ? [searchExpression.replaceAll('"', '')]
      : searchExpression.split(' ')

    return filteredIssues.filter((issue) => {
      return searchWords.every((word) => {
        return (
          String(issue.code).toLowerCase().includes(word) ||
          accents.remove(String(issue.title).toLowerCase()).includes(word) ||
          accents
            .remove(String(issue.description).toLowerCase())
            .includes(word) ||
          issue.images.some((image) =>
            accents.remove(String(image.title).toLowerCase()).includes(word)
          ) ||
          issue.ccodeDocumentsReferences.some((document) =>
            `${accents.remove(String(document.title)).toLowerCase()}-${String(
              document.rev
            ).toLowerCase()}`.includes(word)
          )
        )
      })
    })
  }
}
