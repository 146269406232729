import { mapActions, mapMutations, mapState } from 'vuex'
import Auth from '@/plugins/auth'

export default {
  computed: {
    ...mapState(['loggedUser'])
  },

  methods: {
    ...mapActions(['getLoggedUserAction']),
    ...mapMutations(['setLoggedUser']),
    async getLoggedUser () {
      const responseUser = await this.getLoggedUserAction()
      return responseUser
    },

    async signOut (redirect) {
      try {
        const { clientId, clientUser } = this.$route.query
        if (clientId) {
          localStorage.removeItem(
            `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`
          )
          if (clientUser) {
            const clientUsersCognitoId = JSON.parse(
              localStorage.getItem(`cognitoUsers.${clientId}`)
            )
            if (clientUsersCognitoId) {
              const clientUserCognitoId = clientUsersCognitoId[clientUser]
              localStorage.removeItem(
                `CognitoIdentityServiceProvider.${clientId}.${clientUserCognitoId}.userData`
              )
              localStorage.removeItem(
                `CognitoIdentityServiceProvider.${clientId}.${clientUserCognitoId}.accessToken`
              )
              localStorage.removeItem(
                `CognitoIdentityServiceProvider.${clientId}.${clientUserCognitoId}.refreshToken`
              )
              localStorage.removeItem(
                `CognitoIdentityServiceProvider.${clientId}.${clientUserCognitoId}.clockDrift`
              )
              localStorage.removeItem(
                `CognitoIdentityServiceProvider.${clientId}.${clientUserCognitoId}.idToken`
              )

              delete clientUsersCognitoId[clientUser]
              localStorage.setItem(
                `cognitoUsers.${clientId}`,
                JSON.stringify(clientUsersCognitoId)
              )
            }
          }
        } else {
          await Auth.signOut()
        }

        this.setLoggedUser(null)
        const query = Object.assign({}, { clientId, clientUser, redirect })
        this.$router.push({ name: 'Authenticate', query })
      } catch (err) {}
    }
  }
}
