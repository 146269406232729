<template>
  <div>
    <cf-span
      size="sm"
      color="grey"
    >
      {{ label }}
    </cf-span>
    <cf-span
      style="margin-top: 0.8rem"
      size="md"
    >
      {{ value }}
    </cf-span>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    }
  }
}
</script>
