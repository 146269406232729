function openChat () {
  try {
    sessionStorage.setItem('chatIsActive', true)
    zE('webWidget', 'show')
    zE('webWidget', 'open')
  } catch (e) {
    console.error('Zendesk not available')
  }
}

function closeChat () {
  try {
    sessionStorage.setItem('chatIsActive', false)
    zE('webWidget', 'close')
    zE('webWidget', 'hide')
  } catch (e) {
    console.error('Zendesk not available')
  }
}

function setChatUser (user) {
  try {
    if (user) {
      const identifiedUser = {
        name: [user.name, user.lastName].join(' '),
        email: user.email,
        organization: user.company ? user.company.name : 'no_company'
      }
      zE('webWidget', 'identify', identifiedUser)
    } else {
      zE('webWidget', 'logout')
    }
  } catch (e) {
    console.error('Zendesk not available')
  }
}

try {
  zE('webWidget:on', 'close', function () {
    closeChat()
  })

  zE('webWidget:on', 'open', function () {
    openChat()
  })

  zE('webWidget:on', 'chat:unreadMessages', function (number) {
    openChat()
  })

  const chatIsActive = sessionStorage.getItem('chatIsActive') === 'true'
  if (chatIsActive) {
    openChat()
  } else {
    closeChat()
  }
} catch (e) {
  console.error('Zendesk not available')
}

export { openChat, closeChat, setChatUser }
