export default {
  setModelsList: (state, models) => {
    state.modelsList = models
  },
  insertNewModel: (state, model) => {
    state.modelsList = state.modelsList.push(model)
  },
  updateModel: (state, model) => {
    const modelIndex = state.modelsList.findIndex(
      (models) => models.id === model.id
    )
    state.modelsList[modelIndex].title = model.title
    state.modelsList[modelIndex].visibility = model.visibility
  }
}
