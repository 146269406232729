import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['loggedUser'])
  },
  methods: {
    getErrors (error) {
      const errorObj = error
      if (errorObj.networkError) { return [errorObj.networkError.result || { message: errorObj.message }] }
      if (errorObj.graphQLErrors && errorObj.graphQLErrors.length > 0) {
        return errorObj.graphQLErrors.map((e) => {
          return { code: e.extensions.code, message: e.message }
        })
      }
      return [errorObj]
    },
    getErroObjectsMessages (errors) {
      const errorsObj = this.getErrors(errors)
      if (errorsObj.some((e) => e.code === 'NOT_ACCEPTED_POLICIES')) {
        this.$router.push({
          name: 'acceptPolicies',
          query: { redirect: this.$route.fullPath }
        })
      }

      return errorsObj.map((e) => {
        let messageCode
        if (e.code) {
          if (e.code === 'INTERNAL_SERVER_ERROR') messageCode = 'UNEXPECTED'
          else messageCode = e.code
        } else if (e.message === 'Network error: Failed to fetch') {
          messageCode = 'FAILED_TO_FETCH'
        } else if (
          e.message === 'User cannot be confirmed. Current status is CONFIRMED'
        ) {
          messageCode = 'userAlreadyConfirmed'
        } else if (e.errors && e.errors[0]) {
          if (
            e.errors[0].message ===
            'Context creation failed: Not allowed in demo mode'
          ) { messageCode = 'FORBIDDEN_DEMO_MODE' } else messageCode = e.errors[0].message
        }

        let message = [messageCode, e.message].filter(Boolean).join(': ')

        const translation = this.$te(`errors.${messageCode}`)
        if (translation) {
          message = this.$t(`errors.${messageCode}`)
        }
        return message
      })
    },
    async errorCapturedMethod (errors, info) {
      this.getErroObjectsMessages(errors).forEach((message) => {
        console.error(message)
        this.$message({
          type: 'error',
          message
        })
      })
    }
  }
}
