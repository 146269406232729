
const pixelmatch = require('pixelmatch')

const resizeImage = function (src, maxWidth, maxHeight) {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img')
    img.crossOrigin = 'Anonymous'
    img.src = src
    img.onload = function () {
      const MAX_WIDTH = maxWidth
      const MAX_HEIGHT = maxHeight
      let width = img.naturalWidth
      let height = img.naturalHeight
      if (width > MAX_WIDTH || height > MAX_HEIGHT) {
        if (width / height > MAX_WIDTH / MAX_HEIGHT) {
          height *= MAX_WIDTH / width
          width = MAX_WIDTH
        } else {
          width *= MAX_HEIGHT / height
          height = MAX_HEIGHT
        }
      }
      const canvas = document.createElement('canvas')
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, width, height)
      const dataUrl = canvas.toDataURL()
      resolve(dataUrl)
    }
    img.onerror = function (err) {
      reject(err)
    }
  })
}

const fileToDataUrl = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      resolve(e.target.result)
    }
    reader.onerror = function (err) {
      reject(err)
    }
    reader.readAsDataURL(file)
  })
}

const dataUrlToFile = (dataRrl, filename) => {
  // Passa hash de imagem para arquivo de imagem
  const arr = dataRrl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

const urlToDataUrl = (url) => {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img')
    img.crossOrigin = 'Anonymous'
    img.src = url
    img.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = img.naturalWidth
      canvas.height = img.naturalHeight
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      const dataUrl = canvas.toDataURL()
      resolve(dataUrl)
    }
    img.onerror = function (err) {
      reject(err)
    }
  })
}

const copyImageToClipboard = (src) => {
  const img = new Image()
  const c = document.createElement('canvas')
  const ctx = c.getContext('2d')
  img.crossOrigin = 'Anonymous'
  img.src = src
  img.onload = async function () {
    c.width = this.naturalWidth
    c.height = this.naturalHeight
    ctx.drawImage(this, 0, 0)
    c.toBlob(
      (blob) => {
        // here the image is a blob
        const item = new window.ClipboardItem({ 'image/png': blob })
        navigator.clipboard.write([item])
      },
      'image/png',
      1
    )
  }
}

const dataUrlToImageData = (src, width, height, x = 0, y = 0) => {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img')
    img.crossOrigin = 'Anonymous'
    img.src = src
    img.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = width || img.naturalWidth
      canvas.height = height || img.naturalHeight
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, x, y)
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      resolve(imageData)
    }
    img.onerror = function (err) {
      reject(err)
    }
  })
}

const imageDataToDataUrl = (imageData) => {
  const canvas = document.createElement('canvas')
  canvas.width = imageData.width
  canvas.height = imageData.height
  const ctxDiff = canvas.getContext('2d')

  ctxDiff.putImageData(imageData, 0, 0)
  return canvas.toDataURL()
}

const compareImages = async (image1, image2) => {
  const imgDataUrl1 = await getPositionedImageUrl(image1)
  const imgDataUrl2 = await getPositionedImageUrl(image2)

  const minX = Math.min(
    imgDataUrl1.minX + image1.settings.x,
    imgDataUrl2.minX + image2.settings.x
  )
  const minY = Math.min(
    imgDataUrl1.minY + image1.settings.y,
    imgDataUrl2.minY + image2.settings.y
  )
  const maxX = Math.max(
    imgDataUrl1.maxX + image1.settings.x,
    imgDataUrl2.maxX + image2.settings.x
  )
  const maxY = Math.max(
    imgDataUrl1.maxY + image1.settings.y,
    imgDataUrl2.maxY + image2.settings.y
  )
  const width = Math.floor(maxX - minX)
  const height = Math.floor(maxY - minY)

  imgDataUrl2.absoluteX = imgDataUrl2.minX + image2.settings.x
  imgDataUrl2.absoluteY = imgDataUrl2.minY + image2.settings.y

  imgDataUrl2.finalX = Math.max(imgDataUrl2.absoluteX, 0)
  imgDataUrl2.finalY = Math.max(imgDataUrl2.absoluteY, 0)

  imgDataUrl1.finalX = Math.max(-imgDataUrl2.absoluteX, 0)
  imgDataUrl1.finalY = Math.max(-imgDataUrl2.absoluteY, 0)

  const imgData1Resized = await dataUrlToImageData(
    imgDataUrl1.dataUrl,
    width,
    height,
    imgDataUrl1.finalX,
    imgDataUrl1.finalY
  )
  const imgData2Resized = await dataUrlToImageData(
    imgDataUrl2.dataUrl,
    width,
    height,
    imgDataUrl2.finalX,
    imgDataUrl2.finalY
  )

  const outPut = new ImageData(width, height)

  pixelmatch(
    imgData1Resized.data,
    imgData2Resized.data,
    outPut.data,
    width,
    height
  )

  return {
    dataUrl1: imageDataToDataUrl(imgData1Resized),
    dataUrl2: imageDataToDataUrl(imgData2Resized),
    dataUrlCompare: imageDataToDataUrl(outPut),
    settings: {
      scale: {
        x: 1,
        y: 1
      },
      rotation: 0,
      x: minX,
      y: minY
    }
  }
}

const getPositionedImageUrl = (image) => {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img')
    img.crossOrigin = 'Anonymous'
    img.src = image.dataUrl
    img.onload = function () {
      const img = document.createElement('img')
      img.crossOrigin = 'Anonymous'
      img.src = image.dataUrl
      img.onload = function () {
        const scale = image.settings.scale.x
        const imgWidth = img.naturalWidth * scale
        const imgHeight = img.naturalHeight * scale
        const degrees = image.settings.rotation
        const radians = (degrees * Math.PI) / 180

        const imgCorners = [
          { x: 0, y: 0 },
          { x: imgWidth, y: 0 },
          { x: imgWidth, y: imgHeight },
          { x: 0, y: imgHeight }
        ]
        imgCorners.forEach((corner) => {
          corner.radius = Math.sqrt(
            Math.pow(corner.x, 2) + Math.pow(corner.y, 2),
            2
          )
          corner.radians = Math.atan(corner.y / corner.x)
          corner.degrees = (Math.atan(corner.y / corner.x) * 180) / Math.PI
        })
        const boundingBoxCoordinates = imgCorners.map((imgCorner) => {
          if (imgCorner.radius === 0) return { x: 0, y: 0 }

          const totalRadians = radians + imgCorner.radians
          return {
            x: imgCorner.radius * Math.cos(totalRadians),
            y: imgCorner.radius * Math.sin(totalRadians)
          }
        })
        const xCoordinates = boundingBoxCoordinates.map(
          (coordinate) => coordinate.x
        )
        const yCoordinates = boundingBoxCoordinates.map(
          (coordinate) => coordinate.y
        )
        const minX = Math.min(...xCoordinates)
        const maxX = Math.max(...xCoordinates)
        const minY = Math.min(...yCoordinates)
        const maxY = Math.max(...yCoordinates)
        const width = maxX - minX
        const height = maxY - minY

        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height

        const ctx = canvas.getContext('2d')
        ctx.save()
        ctx.translate(-minX, -minY)
        ctx.rotate(radians)
        ctx.drawImage(img, 0, 0, imgWidth, imgHeight)
        ctx.restore()

        resolve({
          dataUrl: canvas.toDataURL(),
          minX,
          minY,
          maxX,
          maxY,
          width,
          height
        })
      }
    }
    img.onerror = function (err) {
      reject(err)
    }
  })
}

const filters = {
  whiteToTransparent (imageData) {
    const data = imageData.data
    for (let i = 0; i < data.length; i += 4) {
      if (data[i] === 255 && data[i + 1] === 255 && data[i + 2] === 255) {
        data[i + 3] = 0
      }
    }

    return imageData
  },
  softBlack (imageData) {
    const data = imageData.data
    for (let i = 0; i < data.length; i += 4) {
      if (data[i] < 100 && data[i + 1] < 100 && data[i + 2] < 100) {
        data[i] = 100
        data[i + 1] = 100
        data[i + 2] = 100
      }
    }

    return imageData
  }
}

const getFileType = (filename) => {
  const dictionary = {
    jpg: 'image',
    jpeg: 'image',
    png: 'image',
    undefined: 'image',
    mp4: 'media',
    ogg: 'media',
    oga: 'media',
    ogv: 'media',
    mp3: 'media',
    wav: 'media'
  }
  const urlFileName = filename.split('/').pop()
  const hasExtension = urlFileName.split('.').length > 1 // necessário para manter a compatibilidade com arquivos migrados da v1
  const extension = hasExtension && urlFileName.split('.').pop().toLowerCase()
  return dictionary[extension] || extension || 'image'
}

export default {
  resizeImage,
  dataUrlToFile,
  fileToDataUrl,
  urlToDataUrl,
  copyImageToClipboard,
  dataUrlToImageData,
  imageDataToDataUrl,
  compareImages,
  filters,
  getFileType
}
