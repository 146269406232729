import mutations from './mutations'
const state = {
  inviteList: [],
  projectsList: []
}
export default {
  namespaced: true,
  state,
  mutations
}
