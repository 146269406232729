import {
  clearedCommentsFilters,
  clearedHistoryFilters
} from '../utils/filter-utils'
import { clearedSortSettings } from '../utils/sort-utils'

const getDefaultState = () => {
  const localStorageHistoryFilter = localStorage.getItem('historyFilter')
  const historyFilters = localStorageHistoryFilter
    ? JSON.parse(localStorageHistoryFilter)
    : JSON.parse(JSON.stringify(clearedHistoryFilters))

  return {
    issues: [],
    mentionableIssues: [],
    filteredIssues: [],
    standardFilter: null,
    customFilters: null,
    searchIssues: null,
    selectedIssueComments: [],
    commentsFilters: JSON.parse(JSON.stringify(clearedCommentsFilters)),
    historyFilters,
    selectedIssueHistory: [],
    projectEnums: [],
    selectedIssues: [],
    userGroups: [],
    userTags: [],
    userDisciplines: [],
    sortSettings: JSON.parse(JSON.stringify(clearedSortSettings)),
    ccodeDocumentsReferences: [],
    mentionedUsersIds: [],
    creatorUsersIds: [],
    editorUsersIds: [],
    publisherUsersIds: [],
    smartFilterIssuesIds: null
  }
}

export { getDefaultState }
