<template>
  <div style="display: grid">
    <div
      v-if="label"
      :class="$style.labelDiv"
    >
      <cf-span
        size="sm"
        :class="$style.labelClass"
        :color="inputError ? 'red' : 'grey'"
      >
        {{ label }}
      </cf-span>
      <cf-span
        v-show="required"
        size="lg"
        :color="inputError ? 'red' : 'grey'"
      >
        *
      </cf-span>
    </div>
    <el-date-picker
      v-model="inputDate"
      style="width: 100%"
      :type="type"
      :size="size"
      :rangeSeparator="rangeSeparator"
      :unlinkPanels="unlinkPanels"
      :placeholder="placeholder"
      :startPlaceholder="startPlaceholder"
      :endPlaceholder="endPlaceholder"
      :format="format"
      :clearable="clearable"
      :class="noBorder ? 'noBorder' : null"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: undefined,
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'date'
    },
    reference: {
      type: String,
      default: null
    },
    rangeSeparator: {
      type: String,
      default: null
    },
    unlinkPanels: Boolean,
    placeholder: {
      type: String,
      default: null
    },
    startPlaceholder: {
      type: String,
      default: null
    },
    endPlaceholder: {
      type: String,
      default: null
    },
    format: {
      type: String,
      default: null
    },
    inputError: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputDate: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style lang="scss" module>
.labelDiv {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  column-gap: 0.2rem;
  grid-template-rows: 1.8rem;
}
.labelClass {
  transition: 0.3s ease-in-out;
  &:hover {
    color: getColor('black');
  }
}
</style>

<style lang="scss" scoped>
::v-deep .noBorder .el-input__inner {
  border: none;
  padding: 0;
}

// ::v-deep .noBorder .el-input__suffix {
//     visibility: hidden
// }
::v-deep .noBorder .el-input__prefix {
  visibility: hidden;
}

::v-deep .el-input.is-disabled .el-input__inner {
  background-color: transparent;
}
</style>
