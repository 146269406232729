<template>
  <div :class="$style.container">
    <div :class="$style.layout">
      <div
        v-if="!isMobile"
        :class="$style.desktopMenu"
      >
        <slot name="header" />
        <div :class="$style.menuList">
          <slot />
        </div>
      </div>
      <div
        v-if="isMobile"
        :reverse="!backTo"
        :class="$style.mobileMenu"
      >
        <div :class="$style.mobileBack">
          <cf-span
            v-if="backTo"
            size="xl"
            class="icon-arrow-left-2"
            clickable
            @click.native="$router.push({ name: backTo })"
          />
        </div>
        <div :class="$style.mobileHeader">
          <slot name="header" />
        </div>
        <div :class="$style.mobileHamburguer">
          <cf-span
            size="xl"
            class="icon-menu"
            clickable
            @click.native="showDrawer"
          />
        </div>

        <el-drawer
          :showClose="false"
          :visible.sync="drawerVisible"
          :direction="backTo ? 'rtl' : 'ltr'"
          size="80%"
        >
          <template #title>
            <slot name="header" />
          </template>
          <div :class="$style.menuList">
            <slot />
          </div>
        </el-drawer>
        <div :class="$style.divider" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    backTo: {
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      drawerVisible: false
    }
  },
  methods: {
    showDrawer () {
      this.drawerVisible = true
    }
  }
}
</script>

<style lang="scss" module>
.layout {
  display: grid;
  overflow: hidden;
}
.desktopMenu {
  display: grid;
  overflow: hidden;
  grid-template: auto 1fr / minmax(0, 1fr);
  margin: 2.5rem 0;
}
.menuList {
  display: grid;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1.5rem;
  align-content: start;
}
.mobileMenu {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  align-items: center;
  margin: 0.5rem 2.5rem;
  grid-template-areas:
    'back header hamburguer'
    'divider divider divider';
  &[reverse] {
    grid-template-areas:
      'hamburguer header back'
      'divider divider divider';
  }
}
.mobileBack {
  grid-area: back;
}
.mobileHeader {
  grid-area: header;
}
.mobileHamburguer {
  grid-area: hamburguer;
}

.divider {
  border-bottom: 1px solid getColor('grey-lighter');
  width: 100%;
  padding: 0.5rem 0;
  height: 0;
  justify-self: center;
  grid-area: divider;
}

.container {
  display: grid;
}
</style>
