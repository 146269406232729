import { Auth, Hub } from 'aws-amplify'
import Amplify from '@aws-amplify/core'
import { PublicClientApplication, EventType } from '@azure/msal-browser'

const redirectLogin = `${window.location.protocol}//${window.location.host}/process-federated-sign-in`
const redirectLogout = `${window.location.protocol}//${window.location.host}/login`

const amplifyConfig = {
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_iYqKUPHA5',
  aws_user_pools_web_client_id: getClientIdFromQueryParams()
}

Amplify.configure({
  Auth: {
    endpoint: 'https://cognito.construflow.com.br',
    mandatorySignId: true,
    region: amplifyConfig.aws_cognito_region,
    userPoolId: amplifyConfig.aws_user_pools_id,
    userPoolWebClientId: amplifyConfig.aws_user_pools_web_client_id
    // authenticationFlowType: 'USER_PASSWORD_AUTH'
  },
  oauth: {
    domain: 'auth.construflow.com.br',
    scope: ['email', 'openid', 'profile'],
    redirectSignIn: redirectLogin,
    redirectSignOut: redirectLogout,
    responseType: 'code'
  }
})

function getClientIdFromQueryParams () {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  const clientId = params.clientId
  if (!clientId) return '13ljc39ak8erum7p17ua67dk51'

  const clientUser = params.clientUser

  if (clientUser) {
    localStorage.removeItem(
      `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`
    )
    const clientUsersCognitoId = JSON.parse(
      localStorage.getItem(`cognitoUsers.${clientId}`)
    )
    const clientUserCognitoId =
      clientUsersCognitoId && clientUsersCognitoId[clientUser]
    if (clientUserCognitoId) {
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`,
        clientUserCognitoId
      )
    }
  }
  return clientId
}

Hub.listen('auth', ({ payload }) => {
  if (payload.event === 'signIn') {
    onSignInSuccess()
  }
})

const msalConfig = {
  auth: {
    clientId: 'db601202-6baf-4a98-bc47-667073a29b3a',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: redirectLogin,
    postLogoutRedirectUri: redirectLogout,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
}

const msalInstance = new PublicClientApplication(msalConfig)

msalInstance
  .handleRedirectPromise()
  .then(handleMicrosoftRedirectResponse)
  .catch((error) => {
    console.error(error)
  })

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  }
})

async function handleMicrosoftRedirectResponse (response) {
  if (response !== null) {
    signUpOrSignInWithMicrosoftToken(response)
  }
}

async function initialize () {
  await msalInstance.initialize()
}
async function currentSession () {
  await validateFederatedLoginSession()
  return Auth.currentSession()
}

async function signOut () {
  await Auth.signOut()
  await signOutFromFederatedLogin()
  localStorage.removeItem('federatedLogin')
}

async function signUp ({ username, password, attributes }) {
  return Auth.signUp({
    username,
    password,
    attributes
  })
}

async function forgotPasswordSubmit (username, code, newPassword) {
  return Auth.forgotPasswordSubmit(username, code, newPassword)
}

async function signIn (username, password) {
  localStorage.removeItem('federatedLogin')
  return Auth.signIn(username, password)
}

async function resendSignUp (username) {
  return Auth.resendSignUp(username)
}

async function confirmSignUp (username, code) {
  return Auth.confirmSignUp(username, code)
}

async function forgotPassword (username) {
  return Auth.forgotPassword(username)
}

async function signUpOrSignInWithMicrosoftToken (data) {
  saveMicrosoftFederatedLoginOnLocalStorage(data)

  const microsoftIdToken = data.idToken
  const attributes = {}
  const username = data.account.username.toLowerCase()
  attributes.email = username
  attributes['custom:microsoftIdToken'] = microsoftIdToken
  attributes.name = data.account.name
  try {
    await signInWithMicrosoftToken(username, microsoftIdToken)
  } catch (error) {
    if (
      error.code === 'UserLambdaValidationException' &&
      error.message.includes('User does not exist')
    ) {
      await Auth.signUp({
        username: username,
        password: `M1cr05@ft${generateRandomString(20)}`,
        attributes: attributes
      })
      await signInWithMicrosoftToken(username, microsoftIdToken)
    } else {
      throw error
    }
  }
}

async function signInWithMicrosoftToken (username, microsoftIdToken) {
  const cognitoUser = await Auth.signIn(username)
  await Auth.sendCustomChallengeAnswer(cognitoUser, microsoftIdToken)
}

function generateRandomString (length) {
  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-@!#._~'
  let randomString = ''
  for (let i = 0; i < length; i++) {
    const randomPoz = Math.floor(Math.random() * charset.length)
    randomString += charset.substring(randomPoz, randomPoz + 1)
  }
  return randomString
}

async function validateFederatedLoginSession () {
  const isFederatedLogin = !!localStorage.getItem('federatedLogin')
  if (isFederatedLogin) {
    const federatedLogin = JSON.parse(localStorage.getItem('federatedLogin'))
    if (federatedLogin.idp === 'microsoft') {
      const isExpired =
        new Date(federatedLogin.expiresAt).getTime() < new Date().getTime()
      if (isExpired) {
        const account = msalInstance.getAccountByLocalId(
          federatedLogin.localAccountId
        )
        if (account) {
          const data = await msalInstance.acquireTokenSilent({ account })
          saveMicrosoftFederatedLoginOnLocalStorage(data)
        }
      }
    }
  }
}

function saveMicrosoftFederatedLoginOnLocalStorage (data) {
  localStorage.setItem(
    'federatedLogin',
    JSON.stringify({
      idp: 'microsoft',
      localAccountId: data.account.localAccountId,
      expiresAt: data.expiresOn,
      loginHint: data.idTokenClaims.login_hint
    })
  )
}

async function signOutFromFederatedLogin () {
  const isFederatedLogin = !!localStorage.getItem('federatedLogin')
  if (isFederatedLogin) {
    const federatedLogin = JSON.parse(localStorage.getItem('federatedLogin'))
    if (federatedLogin.idp === 'microsoft') {
      const account = msalInstance.getAccountByLocalId(
        federatedLogin.localAccountId
      )
      if (account) {
        await msalInstance
          .logoutRedirect({
            logoutHint: federatedLogin.loginHint,
            account
          })
          .catch(console.info)
      }
    }
  }
}

async function signInWithGoogle () {
  localStorage.setItem(
    'federatedLogin',
    JSON.stringify({
      idp: 'google'
    })
  )
  await Auth.federatedSignIn({ provider: 'Google' })
}

async function signInWithMicrosoft () {
  await msalInstance.loginRedirect({
    scopes: ['openid', 'profile', 'email', 'user.read'],
    redirectStartPage: redirectLogin,
    redirectUri: redirectLogin
  })
}

function onSignInSuccess () {
  sessionStorage.setItem('newSignIn', true)
  saveClientUserCognitoIdOnLocalStorage()
  window.location.href = sessionStorage.getItem('redirectAfterLogin') || '/'
}

function saveClientUserCognitoIdOnLocalStorage () {
  const url = window.location.href
  const params = new URLSearchParams(new URL(url).search)
  const clientId = params.get('clientId')
  const clientUser = params.get('clientUser')
  if (clientId && clientUser) {
    const clientUsersCognitoId =
      JSON.parse(localStorage.getItem(`cognitoUsers.${clientId}`)) || {}

    clientUsersCognitoId[clientUser] = localStorage.getItem(
      `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`
    )
    localStorage.setItem(
      `cognitoUsers.${clientId}`,
      JSON.stringify(clientUsersCognitoId)
    )
  }
}

export default {
  getClientIdFromQueryParams,
  handleMicrosoftRedirectResponse,
  initialize,
  currentSession,
  signOut,
  signUp,
  forgotPasswordSubmit,
  signIn,
  resendSignUp,
  confirmSignUp,
  forgotPassword,
  signUpOrSignInWithMicrosoftToken,
  signInWithMicrosoftToken,
  generateRandomString,
  validateFederatedLoginSession,
  saveMicrosoftFederatedLoginOnLocalStorage,
  signOutFromFederatedLogin,
  signInWithGoogle,
  signInWithMicrosoft,
  msalInstance
}
