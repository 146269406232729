<template>
  <div
    :class="[$style.iconContainer, $style[`color_${color}`]]"
    :active="active"
    :hoverable="hoverable"
    :clickable="clickable"
    :disabled="disabled"
    :textSize="textSize"
    @click="click"
  >
    <cf-span
      :class="[`icon-${icon}`, $style.icon]"
      multiline
      :size="textSize"
      icon
    />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'construflow'
    },
    padding: {
      type: String,
      default: '1rem'
    },
    textSize: {
      type: String,
      default: 'md'
    },
    hoverable: Boolean,
    clickable: Boolean,
    disabled: Boolean,
    active: Boolean
  },

  methods: {
    click (e) {
      if (!this.disabled) this.$emit('click', e)
    }
  }
}
</script>
<style lang="scss" module>
.iconContainer {
  border-radius: 50%;
  display: grid;
  align-content: center;
  justify-content: center;
  overflow: hidden;
  &[clickable] {
    cursor: pointer;
  }
  &[disabled] {
    cursor: not-allowed !important;
  }

  &[textSize='2xs'] {
    width: 3rem;
    height: 3rem;
  }
  &[textSize='xs'] {
    width: 3.2rem;
    height: 3.2rem;
  }
  &[textSize='sm'] {
    width: 3.4rem;
    height: 3.4rem;
  }
  &[textSize='md'] {
    width: 3.6rem;
    height: 3.6rem;
  }
  &[textSize='lg'] {
    width: 3.8rem;
    height: 3.8rem;
  }
  &[textSize='xl'] {
    width: 4.1rem;
    height: 4.1rem;
  }
  &[textSize='2xl'] {
    width: 5rem;
    height: 5rem;
  }
}

.icon {
  width: fit-content;
  height: fit-content;
  line-height: normal;
}

.color_construflow {
  background-color: getColor('construflow');
  .icon {
    color: getColor('white');
  }
  &[hoverable],
  &[clickable] {
    &:hover {
      background-color: getColor('construflow-darker');
    }
  }

  &[active] {
    background-color: getColor('construflow-darker');
  }
}
.color_green {
  background-color: getColor('green');
  .icon {
    color: getColor('white');
  }
  &[hoverable],
  &[clickable] {
    &:hover {
      background-color: getColor('green-darker');
    }
  }

  &[active] {
    background-color: getColor('green-darker');
  }
}
.color_grey {
  background-color: getColor('grey');
  .icon {
    color: getColor('white');
  }
  &[hoverable],
  &[clickable] {
    &:hover {
      background-color: getColor('grey-lighter');
      .icon {
        color: getColor('grey');
      }
    }
  }

  &[active] {
    background-color: getColor('grey');
  }
}
.color_grey-lighter {
  background-color: getColor('grey-lighter');
  .icon {
    color: getColor('white');
  }
  &[hoverable],
  &[clickable] {
    &:hover {
      background-color: getColor('grey');
    }
  }

  &[active] {
    background-color: getColor('grey');
  }
}
.color_primary {
  background-color: getColor('primary');
  .icon {
    color: getColor('white');
  }
  &[hoverable],
  &[clickable] {
    &:hover {
      background-color: getColor('primary-darker');
    }
  }

  &[active] {
    background-color: getColor('primary-darker');
  }
}
.color_red {
  background-color: getColor('red');
  .icon {
    color: getColor('white');
  }
  &[hoverable],
  &[clickable] {
    &:hover {
      background-color: getColor('red-darker');
    }
  }

  &[active] {
    background-color: getColor('red-darker');
  }
}
.color_white {
  background-color: getColor('white');
  .icon {
    color: getColor('black');
  }
  &[hoverable],
  &[clickable] {
    &:hover {
      background-color: getColor('grey');
      .icon {
        color: getColor('white');
      }
    }
  }

  &[active] {
    background-color: getColor('grey');
    .icon {
      color: getColor('white');
    }
  }
}
</style>
