function calculateDeadlineColor (deadline) {
  const daysFromNow = Math.ceil((new Date(deadline) - new Date()) / 86400000)
  if (daysFromNow > 30) return 'grey'
  if (daysFromNow > 0) return 'yellow'
  else return 'red'
}

function calculateUserIssuePendencieAndDeadline (
  issue,
  userProjectPermissions,
  systemIssueDisciplineStatus
) {
  if (!systemIssueDisciplineStatus || !systemIssueDisciplineStatus.length) { return }

  const userRole = userProjectPermissions.manageDisciplines
    ? 'manager'
    : 'collaborator'

  const deadlineAlertStatuses = systemIssueDisciplineStatus
    .filter((i) => i.deadlineAlert)
    .map((i) => i.name)

  const issueDisciplines = JSON.parse(JSON.stringify(issue.disciplines))
  issueDisciplines.sort((a, b) => new Date(a.deadline) - new Date(b.deadline))

  const userIssueDisciplines = issue.disciplines.filter((issueDiscipline) => {
    return userProjectPermissions.userDisciplines.some(
      (userDiscipline) =>
        userDiscipline.disciplineId === issueDiscipline.disciplineId
    )
  })

  const userIssuePendingDisciplines = userIssueDisciplines.filter(
    (issueDiscipline) => {
      return systemIssueDisciplineStatus
        .find((statusItem) => statusItem.name === issueDiscipline.status)
        .responsibility.includes(userRole)
    }
  )

  //
  // calcula qual deadline deve exibir no card
  //
  let cardDeadline = null

  const userIssuePendingDisciplineWithDeadline =
    userIssuePendingDisciplines.filter(
      (issueDiscipline) => issueDiscipline.deadline
    )

  if (userIssuePendingDisciplineWithDeadline.length) {
    userIssuePendingDisciplineWithDeadline.sort(
      (a, b) => new Date(a.deadline) - new Date(b.deadline)
    )
    cardDeadline = {
      deadline: userIssuePendingDisciplineWithDeadline[0].deadline,
      deadlineColor: calculateDeadlineColor(
        userIssuePendingDisciplineWithDeadline[0].deadline
      )
    }
  }

  //
  // agrupa disciplinas por deadline para exibir na tooltip
  //

  const disciplinesDeadlines = []

  issueDisciplines.forEach((issueDiscipline) => {
    if (
      issueDiscipline.deadline &&
      deadlineAlertStatuses.includes(issueDiscipline.status)
    ) {
      let existingDeadline = disciplinesDeadlines.find(
        (disciplinesDeadline) => {
          const issuePendingDisciplineDeadline = new Date(
            issueDiscipline.deadline
          )
          issuePendingDisciplineDeadline.setHours(23, 59, 59, 999)
          const disciplinesDeadlineDate = new Date(disciplinesDeadline.deadline)
          disciplinesDeadlineDate.setHours(23, 59, 59, 999)
          return (
            disciplinesDeadlineDate.getTime() ===
            issuePendingDisciplineDeadline.getTime()
          )
        }
      )
      if (!existingDeadline) {
        existingDeadline = {
          deadline: issueDiscipline.deadline,
          deadlineColor: calculateDeadlineColor(issueDiscipline.deadline),
          issueDisciplines: []
        }
        disciplinesDeadlines.push(existingDeadline)
      }

      existingDeadline.issueDisciplines.push(issueDiscipline)
    }
  })

  disciplinesDeadlines.sort(
    (a, b) => new Date(a.deadline) - new Date(b.deadline)
  )

  //
  // adiciona dados à issue
  //

  Object.assign(issue, {
    pending: !!userIssuePendingDisciplines.length,
    computedDeadlines: { cardDeadline, disciplinesDeadlines }
  })
}

export { calculateUserIssuePendencieAndDeadline, calculateDeadlineColor }
