export default {
  id: 'es',
  messages: {
    ...require('@/translations/es.json'),
    ...require('@/modules/workspace/modules/company/translations/es.json'),
    ...require('@/modules/auth/translations/es.json'),
    ...require('@/modules/onboarding/translations/es.json'),
    ...require('@/modules/workspace/translations/es.json'),
    ...require('@/modules/workspace/modules/userSettings/translations/es.json'),
    ...require('@/modules/workspace/modules/company/translations/es.json'),
    ...require('@/modules/workspace/modules/project/translations/es.json'),
    ...require('@/modules/workspace/modules/project/modules/setup/translations/es.json'),
    ...require('@/modules/workspace/modules/project/modules/issues/translations/es.json'),
    ...require('@/modules/workspace/modules/project/modules/documents/translations/es.json'),
    ...require('@/modules/admin/translations/es.json'),
    ...require('@/modules/auth/translations/es.json'),
    ...require('@/modules/workspace/modules/project/modules/setup/translations/es.json'),
    ...require('@/modules/workspace/modules/project/modules/modelViewer/translations/es.json'),
    ...require('@/modules/ccode/translations/es.json'),
    ...require('@/modules/AI/translations/es.json'),
    ...require('@/components/image-document-viewer/translations/es.json'),
    ...require('@/modules/workspace/modules/smart-docs/translations/es.json'),
    ...require('@/modules/user-files/translations/es.json')
  },
  dateTimeFormats: {
    numeric: {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric'
    },
    short: {
      year: 'numeric',
      month: 'short',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: '2-digit'
    },
    yearShort: {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    },
    hourAndMinute: {
      hour: 'numeric',
      minute: 'numeric'
    },
    monthAndYear: {
      year: 'numeric',
      month: 'short'
    }
  }
}
