const modelViewerView = () => import('../views/ModelViewerView.vue')
const BimwebView = () => import('../../issues/views/BimwebView')
const RouterPassThrough = () => import('@/components/RouterPassThrough')

export default [
  {
    path: 'modelViewer',
    redirect: { name: 'projectModelsList' },
    name: 'projectModelViewer',
    component: RouterPassThrough,
    children: [
      {
        path: 'models',
        name: 'projectModelsList',
        component: modelViewerView
      },
      {
        path: 'issues',
        name: 'projectModelIssues',
        component: BimwebView
      }
    ]
  }
]
