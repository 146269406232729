<template>
  <div :class="$style.container">
    <div
      :class="$style.avatarDiv"
      :style="circleImage ? 'padding: 0%' : 'padding: 5%'"
    >
      <div
        v-show="editable"
        :class="$style.editButton"
        :style="circleImage ? 'bottom: 10%' : 'bottom: 0%'"
        @click="cropperDialog = true"
      >
        <span :class="['icon-camera', $style.icon]" />
      </div>
      <img
        id="img"
        :key="forceUpdate"
        :style="
          (circleImage ? 'border-radius: 50%;' : 'border-radius: 5%;') +
            imgStyle
        "
        :src="imageSource()"
        :class="border ? $style.imageBorder : null"
      >
    </div>
    <cf-dialog
      v-if="editable"
      v-model="cropperDialog"
      :mobile="isMobile"
      :showClose="false"
    >
      <cf-img-crop
        ref="crop"
        :cropRatio="cropRatio"
        @close="cropperDialog = false"
        @cropped="cropClose($event)"
      />
    </cf-dialog>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: undefined,
      required: true
    },
    border: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    imgStyle: {
      type: String,
      default: ''
    },
    cropRatio: {
      type: Number,
      default: 1
    }
  },

  data () {
    return {
      cropperDialog: false,
      forceUpdate: 0
    }
  },

  computed: {
    circleImage () {
      if (this.circle || this.type === 'user') return true
      return false
    }
  },

  watch: {
    editable: function (newValue) {
      if (!newValue) {
        this.resetCrop()
      }
    }
  },

  methods: {
    imageSource () {
      if (this.src) return this.src
      else {
        if (this.type === 'user') return require('@/assets/no-image-user.svg')
        if (this.type === 'company') { return require('@/assets/no-image-company.svg') }
        return require('@/assets/no-image.png')
      }
    },

    cropClose (event) {
      this.$emit('cropCloseEvent', event)
      this.cropperDialog = false
    },

    resetCrop () {
      if (this.$refs.crop) this.$refs.crop.reset()
    }
  }
}
</script>
<style lang="scss" module>
#img {
  object-fit: cover;
}

.container {
  display: flex;
  justify-content: center;
}

.avatarDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
  position: relative;
}

.editButton {
  display: flex;
  background-color: getColor('grey-lighter');
  color: getColor('white');
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  position: absolute;
  bottom: 10%;
  right: 0px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem;
  &:hover {
    -webkit-transition: all 0.3s linear;
    background-color: getColor('primary');
  }
}
.icon {
  font-size: 1.5rem;
  font-weight: 600;
}

.imageBorder {
  border: getColor('grey-lighter') solid 3px;
}
</style>
<style scoped>
::v-deep .el-dialog {
  width: 38rem;
  height: 40rem;
  overflow: hidden !important;
}
::v-deep .el-dialog__body {
  height: 100%;
}
</style>
