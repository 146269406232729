const OnboardingView = () => import('../views/OnboardingView')
const ConfirmUserView = () => import('../views/ConfirmUserView')
const OnboardingCompanyView = () => import('../views/OnboardingCompanyView')

export default [
  {
    path: '/onboarding',
    name: 'onboarding',
    redirect: { name: 'OnboardingUserProfile' },
    component: OnboardingView,
    meta: { needsUserData: true, showSupportIcon: true },
    children: [
      {
        path: 'userprofile',
        name: 'OnboardingUserProfile',
        component: ConfirmUserView
      },
      {
        path: 'company',
        name: 'OnboardingCompany',
        meta: { needsProfile: true },
        component: OnboardingCompanyView
      }
    ]
  }
]
