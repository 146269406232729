import axios from 'axios'
import Auth from '@/plugins/auth'

const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

client.interceptors.request.use(async (config) => {
  let session
  try {
    session = await Auth.currentSession()
    config.headers.Authorization = `Bearer ${session.accessToken.jwtToken}`
  } catch (err) {}

  return config
})

export default client
