<template>
  <SettingsLayout
    :editing="editing && !hideButtons"
    :title="title"
    @editSecondaryClick="modalCancelButton"
    @editPrimaryClick="saveButtonClick"
  >
    <div
      v-loading="loading"
      class="cf-image-editor-layout"
      @keydown.esc="escPressed"
      @keydown.stop.prevent
    >
      <div
        v-if="editing"
        class="cFlow-markerController"
      >
        <MarkupsMenu
          :selectedTool="selectedTool"
          :enablePinTool="enablePinTool"
          :strokeColor="strokeColor"
          :fillColor="fillColor"
          @selectTool="setSelectedTool"
          @removeShape="removeShape"
          @strokeColor="strokeColor = $event"
          @fillColor="fillColor = $event"
        />
      </div>
      <div
        ref="marker"
        :style="`cursor: ${cursor}`"
        class="cFlow-marker"
      >
        <v-stage
          v-show="calculatedSize"
          ref="stage"
          tabindex="1"
          class="cFlow-stage"
          :config="stageSettings"
          :style="`cursor: ${cursor}`"
          @keydown.native.delete="removeShape"
          @mousedown="handleStageMouseDown"
          @touchstart="handleStageMouseDown"
          @mousemove="handleStageMouseMove"
          @mouseup="handleStageMouseUp"
          @wheel="handleMouseScroll"
          @dblclick="handleStageDblClick"
        >
          <v-layer ref="mainLayer" />
          <v-layer ref="secondaryLayer">
            <v-group
              v-for="dividerGroup in dividers"
              :key="dividerGroup.id"
              ref="dividerGroup"
              :config="dividerGroup"
              @dragmove="handleDividerDragMove"
            >
              <v-line
                :config="dividerGroup.divider"
                @mouseenter="handleShapeMouseEnter"
                @mouseleave="handleShapeMouseLeave"
              />
              <v-label
                ref="dividerLabel"
                :config="dividerGroup.label"
              >
                <v-tag :config="dividerGroup.tag" />
                <v-text :config="dividerGroup.text" />
              </v-label>
            </v-group>
            <v-image
              v-for="(pin, index) in pinsStageElements"
              ref="pin"
              :key="index"
              :config="pin"
              :style="`cursor: ${cursor}`"
              @mouseenter="handleShapeMouseEnter"
              @mouseleave="handleShapeMouseLeave"
              @dragstart="handleDragStart"
              @dragend="handleDragEnd"
              @mousedown="handleStageMouseDown"
            />
          </v-layer>

          <v-layer ref="layer">
            <v-rect
              v-for="item in shapes.rectangles"
              ref="rect"
              :key="item._id"
              :config="item"
              :style="`cursor: ${cursor}`"
              @transformend="handleTransformEnd"
              @mouseenter="handleShapeMouseEnter"
              @mouseleave="handleShapeMouseLeave"
              @dragstart="handleDragStart"
              @dragend="handleDragEnd"
            />
            <v-circle
              v-for="item in shapes.circles"
              ref="circle"
              :key="item._id"
              :config="item"
              :style="`cursor: ${cursor}`"
              @transformend="handleTransformEnd"
              @mouseenter="handleShapeMouseEnter"
              @mouseleave="handleShapeMouseLeave"
              @dragstart="handleDragStart"
              @dragend="handleDragEnd"
            />
            <v-line
              v-for="item in shapes.lines"
              ref="line"
              :key="item._id"
              :config="item"
              :style="`cursor: ${cursor}`"
              @transformend="handleTransformEnd"
              @mouseenter="handleShapeMouseEnter"
              @mouseleave="handleShapeMouseLeave"
              @dragstart="handleDragStart"
              @dragend="handleDragEnd"
            />
            <v-arrow
              v-for="item in shapes.arrows"
              ref="arrow"
              :key="item._id"
              :config="item"
              :style="`cursor: ${cursor}`"
              @transformend="handleTransformEnd"
              @mouseenter="handleShapeMouseEnter"
              @mouseleave="handleShapeMouseLeave"
              @dragstart="handleDragStart"
              @dragend="handleDragEnd"
            />
            <v-text
              v-for="item in shapes.texts"
              ref="text"
              :key="item._id"
              :config="item"
              :style="`cursor: ${cursor}`"
              @transformend="handleTransformEnd"
              @mouseenter="handleShapeMouseEnter"
              @mouseleave="handleShapeMouseLeave"
              @dragstart="handleDragStart"
              @dragend="handleDragEnd"
              @dblclick="editingTextMethod"
            />
            <v-transformer
              ref="transformer"
              :config="{ ignoreStroke: true }"
            />
          </v-layer>
        </v-stage>
      </div>
      <div :class="$style.rightMenuContainer">
        <div
          v-if="documentMode"
          :class="$style.rightMenuFloating"
        >
          <cf-tooltip placement="left">
            <template #reference>
              <cf-icon
                icon="pin"
                color="primary"
                :active="showPins"
                clickable
                @click="setPinsVisibility(!showPins)"
              />
            </template>
            <cf-span>{{ $t('ccode.showHidePins') }}</cf-span>
          </cf-tooltip>
          <cf-tooltip placement="left">
            <template #reference>
              <cf-icon
                icon="markups"
                color="primary"
                :active="showMarkups"
                :disabled="!value.state"
                clickable
                @click="setShapesVisibility(!showMarkups)"
              />
            </template>
            <cf-span>{{ $t('ccode.showHideMarkups') }}</cf-span>
          </cf-tooltip>
          <el-slider
            v-model="sliderValue"
            vertical
            height="20rem"
            :min="10"
            :show-tooltip="false"
            style="margin: 1rem 0"
          />
          <cf-icon
            icon="zoom-extends"
            color="primary"
            clickable
            @click="zoomExtends()"
          />
        </div>
      </div>
    </div>
  </SettingsLayout>
</template>

<script>
import Konva from 'konva'
import Point from './point'
import ImgFunctions from '../../utils/ImgFunctions'
import { v4 as uuidv4 } from 'uuid'
const omitDeep = require('omit-deep-lodash')

export default {
  components: {
    SettingsLayout: () => import('@/layouts/SettingsLayout'),
    MarkupsMenu: () => import('./markups-menu.vue')
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    editing: {
      type: Boolean,
      default: false
    },
    documentMode: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    pins: {
      type: Array,
      default: () => []
    },
    hideButtons: {
      type: Boolean,
      default: false
    },
    enablePinTool: {
      type: Boolean,
      default: false
    },
    startingTool: {
      type: String,
      default: 'select'
    },
    overlayMode: {
      type: String,
      default: 'overlay'
    },
    userChangedViewpoint: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      shapes: this.clearedState(),
      selectedTool: 'select',
      selectedToolStateBeforePan: {},
      isDrawing: false,
      activeButton: {},
      startPosition: {},
      textareaVisible: false,
      stageConfig: {
        width: 200,
        height: 200
      },
      backgroundImages: [],
      pinConfig: {
        image: null
      },
      fillColor: '',
      strokeColor: '#FF0000',
      selectedShape: null,
      selectedShapeNode: null,
      calculatedSize: false,
      scaleFactor: 1.1,
      initialViewpointCalculate: false,
      viewpoint: {
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        scale: 1
      },
      isMovingPan: false,
      lastCreatedShape: null,
      pinsStageElements: [],
      pinsColorImages: {
        blue: require('@/assets/pin-blue.svg'),
        grey: require('@/assets/pin-grey.svg'),
        red: require('@/assets/pin-red.svg')
      },
      cursor: 'crosshair',
      lastCursor: null,
      dividers: [],
      compareImagesCache: {},
      draggableImageIndex: null,
      isolatedImageIndex: null,
      showPins: true,
      showMarkups: true,
      alignFace: null
      // layerSettings: {}
    }
  },
  computed: {
    stageSettings () {
      return {
        width: this.viewpoint.width,
        height: this.viewpoint.height,
        x: this.viewpoint.x,
        y: this.viewpoint.y,
        scale: {
          x: this.viewpoint.scale,
          y: this.viewpoint.scale
        }
      }
    },

    sliderValue: {
      get () {
        return Math.min(this.viewpoint.scale * 100, 100)
      },
      set (newValue, oldValue) {
        if (this.viewpoint.scale > 1 && newValue === 100) return // evita quebrar zoom quando acima de 100% utilizando o mouse
        if (this.viewpoint.scale < 0.1 && newValue === 10) return // evita quebrar zoom quando abaixo de 10% utilizando o mouse

        const pivotStartPosition = this.getCenterPosition()
        const pivotStartRelativePosition = this.getRelativeCenterPosition()

        this.viewpoint.scale = newValue / 100

        const pivotNewPosition = new Point(pivotStartRelativePosition)
          .mult(this.viewpoint.scale)
          .add(this.viewpoint)
        const deltaTranslate = new Point(pivotStartPosition).sub(
          pivotNewPosition
        )
        this.viewpoint.x += deltaTranslate.x
        this.viewpoint.y += deltaTranslate.y
      }
    }
  },
  watch: {
    fillColor: {
      handler: function (newVal) {
        this.changeShapeColor(1, newVal)
      }
    },
    strokeColor: {
      handler: function (newVal) {
        this.changeShapeColor(2, newVal)
      }
    },
    textareaVisible: {
      handler: function (value) {
        this.$emit('textareaVisible', value)
      }
    },
    value: {
      handler: function (newValue, oldValue) {
        this.processValue(newValue, oldValue)
      }
    },
    viewpoint: {
      deep: true,
      handler: function (newValue) {
        this.updatePinsPosition()
        this.updateDividersPositions()
      }
    },
    async overlayMode () {
      try {
        this.loading = true
        await this.loadBackgroundImages()
        this.setDividers()
      } finally {
        this.loading = false
      }
    },
    editing (newValue) {
      if (newValue) {
        this.setShapesVisibility(true)
        this.setPinsVisibility(true)
      } else {
        this.setSelectedTool('pan')
      }
    }
  },
  created () {
    if (this.startingTool) this.selectedTool = this.startingTool

    window.addEventListener('resize', this.resizeMarker)
  },
  async mounted () {
    await this.processValue(JSON.parse(JSON.stringify(this.value)))
    if (!this.editing) this.setSelectedTool('pan')
    else this.setSelectedTool(this.selectedTool)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.resizeMarker)
  },
  methods: {
    async processValue (newValue, oldValue) {
      try {
        this.loading = true
        const hasChanges =
          JSON.stringify(newValue) !== JSON.stringify(oldValue || null)
        if (!hasChanges) return
        if (!newValue.images) return

        const hasImagesChanges =
          JSON.stringify(newValue.images) !==
          JSON.stringify((oldValue && oldValue.images) || null)
        if (hasImagesChanges) await this.loadBackgroundImages()

        this.resizeMarker()
        this.calculatedSize = true

        const hasStateChanges =
          JSON.stringify(newValue.state) !==
          JSON.stringify((oldValue && oldValue.state) || null)
        if (hasStateChanges) {
          const stateClone = JSON.parse(
            JSON.stringify(newValue.state || this.clearedState())
          )
          Object.assign(this.shapes, this.clearedState(), stateClone)
          Object.keys(this.shapes).forEach((shapeListName) => {
            this.shapes[shapeListName].forEach((shape) => {
              shape.strokeScaleEnabled = false
              shape.listening = false
              shape.draggable = false
            })
          })
          this.setShapesVisibility(true)
        }

        const hasPinsChanges =
          JSON.stringify(newValue.pins) !==
          JSON.stringify((oldValue && oldValue.pins) || null)
        if (hasPinsChanges) {
          const pinsClone = JSON.parse(JSON.stringify(newValue.pins || []))
          if (pinsClone) await this.loadPins(pinsClone)
          this.updatePinsPosition()
        }

        if (this.$refs.stage) this.$refs.stage.getStage().batchDraw()

        const newVP = JSON.stringify(newValue.viewpoint)
        const oldVP = JSON.stringify((oldValue && oldValue.viewpoint) || null)
        const hasViewpointChanges = newVP !== oldVP
        if (
          hasViewpointChanges ||
          !this.initialViewpointCalculate ||
          !this.documentMode
        ) {
          this.setInitialViewpoint()
        }

        this.setDividers()

        if (this.$refs.stage) this.$refs.stage.getStage().batchDraw()
      } finally {
        this.loading = false
      }
    },

    createDividers () {
      const dividersPositions = [
        (this.viewpoint.width * (1 / 3) - this.viewpoint.x) /
          this.viewpoint.scale,
        (this.viewpoint.width * (2 / 3) - this.viewpoint.x) /
          this.viewpoint.scale
      ]

      this.dividers = dividersPositions.map((position, index) => {
        return {
          id: `divider_${index}`,
          x: position,
          y: this.calculteDividerPositionData().y,
          scale: this.calculteDividerPositionData().scale,
          draggable: true,
          width: 50,
          divider: {
            x: 0,
            y: 0,
            points: [0, 0, 0, this.viewpoint.height],
            stroke: 'grey',
            name: 'divider',
            strokeScaleEnabled: false,
            hitStrokeWidth: 20
          },
          label: {
            x: 0,
            y: 25
          },
          tag: {
            fill: 'grey',
            lineJoin: 'round',
            cornerRadius: [0, 15, 15, 0]
          },
          text: {
            text: this.value.images[index].title,
            padding: 8,
            fontSize: 16,
            fontFamily: 'Epilogue',
            fill: 'white',
            x: 0,
            y: 0,
            align: 'center',
            verticalAlign: 'middle'
          }
        }
      })

      this.applyDividerToImages()
    },

    clearDividers () {
      this.dividers = []
      this.loadBackgroundImages()
    },

    backgroundImagesNodes () {
      if (!this.$refs.mainLayer) return []
      return this.$refs.mainLayer
        .getNode()
        .getChildren((node) => node.name() === 'backgroundImage')
    },

    applyDividerToImages () {
      const backgroundImages = this.backgroundImagesNodes()
      if (!backgroundImages || !backgroundImages.forEach) return
      const backgroundX = JSON.parse(
        JSON.stringify(backgroundImages[0].attrs.x)
      )
      const backgroundWidth = JSON.parse(
        JSON.stringify(backgroundImages[0].attrs.image.width)
      )
      const backgroundHeight = JSON.parse(
        JSON.stringify(backgroundImages[0].attrs.image.height)
      )

      backgroundImages.forEach((imageNode, index) => {
        const leftDivider = this.dividers[index - 1]
        const rightDivider = this.dividers[index]
        const cropStart = leftDivider ? leftDivider.x - backgroundX : 0
        const cropEnd = rightDivider
          ? rightDivider.x - backgroundX
          : backgroundWidth
        const width = cropEnd - cropStart || 1
        imageNode.crop({ x: cropStart, y: 0, width, height: backgroundHeight })
        imageNode.width(width)
        const x = leftDivider ? leftDivider.x : backgroundX
        imageNode.x(x)
      })

      if (this.$refs.stage) this.$refs.stage.getStage().batchDraw()
    },

    async loadPins (pins) {
      const pinsMap = []
      for (const pin of pins) {
        const url = this.pinsColorImages[pin.color || 'red']
        const pinImage = await this.loadImage({ url })
        pinsMap.push({
          name: 'pin',
          image: pinImage,
          id: pin.id,
          x: pin.x,
          y: pin.y,
          originalX: pin.x,
          originalY: pin.y,
          originalWidth: pinImage.width,
          originalHeight: pinImage.height
        })
      }
      this.pinsStageElements = pinsMap
      this.setPinsVisibility(true)
    },

    setDividers () {
      if (this.overlayMode === 'compare') this.createDividers()
      else this.clearDividers()
    },

    updatePinsPosition () {
      const pins = this.$refs.pin
      if (!pins) return
      pins.forEach((pin) => {
        const node = pin.getNode()
        node.scale({
          x: 1 / this.viewpoint.scale,
          y: 1 / this.viewpoint.scale
        })
        node.x(
          node.attrs.originalX -
            node.attrs.originalWidth / 2 / this.viewpoint.scale
        )
        node.y(
          node.attrs.originalY -
            node.attrs.originalHeight / this.viewpoint.scale
        )
      })
    },

    calculteDividerPositionData () {
      return {
        y: -this.viewpoint.y / this.viewpoint.scale,
        scale: {
          x: 1 / this.viewpoint.scale,
          y: 1 / this.viewpoint.scale
        }
      }
    },

    updateDividersPositions () {
      const dividerGroups = this.$refs.dividerGroup
      if (!dividerGroups) return
      dividerGroups.forEach((group) => {
        const node = group.getNode()
        node.y(this.calculteDividerPositionData().y)
        node.scale(this.calculteDividerPositionData().scale)
      })
    },

    saveButtonClick () {
      if (!this.textareaVisible) return this.modalSaveButton()
      else this.warningTextarea()
    },
    modalCancelButton () {
      const textarea = document.getElementById('textArea')
      this.$refs.layer.getNode().draw()
      if (textarea) {
        textarea.parentNode.removeChild(textarea)
      }
      this.$emit('cancelMarked', false)
    },
    async modalSaveButton (e) {
      this.selectedShape = null
      this.selectedShapeNode = null
      await this.updateTransformer()

      const nodesClientRect = this.getNodesClientRect()

      const clientRectIsInViewpointRect =
        nodesClientRect.x >= 0 &&
        nodesClientRect.y >= 0 &&
        nodesClientRect.endX <= this.viewpoint.width &&
        nodesClientRect.endY <= this.viewpoint.height

      const obj = {
        markUp: omitDeep(this.shapes, [
          'draggable',
          'listening',
          'strokeScaleEnabled'
        ])
      }
      if (this.pinsStageElements[0]) {
        obj.pin = {
          x: this.pinsStageElements[0].originalX,
          y: this.pinsStageElements[0].originalY
        }
      }

      let stageCrop

      if (clientRectIsInViewpointRect) {
        const { x, y, width, height } = nodesClientRect
        stageCrop = { x, y, width, height }
      } else {
        stageCrop = {
          x: 0,
          y: 0,
          width: this.viewpoint.width,
          height: this.viewpoint.height
        }
      }
      obj.markedUpDataUrl = await this.$refs.stage
        .getStage()
        .toDataURL(stageCrop)

      if (!clientRectIsInViewpointRect || this.documentMode) {
        obj.viewpoint = this.viewpoint
      }

      this.$emit('saveMarked', obj)
      return obj
    },

    warningTextarea () {
      this.$message({
        type: 'warning',
        showClose: true,
        message: 'Termine de editar o texto antes de salvar!!'
      })
    },

    // image methods
    async loadImage ({ src, url }) {
      if (!src && url) {
        const response = await fetch(url)
        const blob = await response.blob()
        src = await ImgFunctions.fileToDataUrl(blob)
      }

      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => resolve(img)
        img.onerror = () => reject(new Error('error loading image'))
        img.src = src
      })
    },

    async loadBackgroundImages () {
      if (!this.value.images) return

      let imagesToLoad = JSON.parse(JSON.stringify(this.value.images))

      if (this.overlayMode === 'compare' && imagesToLoad.length === 2) {
        imagesToLoad = await this.compareImages(imagesToLoad)
      }

      const promises = imagesToLoad.map((image) => this.loadImage(image))
      const loadedImages = await Promise.all(promises)

      const mainLayer = this.$refs.mainLayer
      if (!mainLayer) return

      const currentImages = this.backgroundImagesNodes()
      currentImages.forEach((image) => image.destroy())

      const imagesConfigs = loadedImages.map((image, index) => {
        const config = {
          id: `backgroundImage_${index}`,
          index,
          name: 'backgroundImage',
          image,
          ...JSON.parse(JSON.stringify(imagesToLoad[index].settings || {})),
          draggable: index === this.draggableImageIndex,
          listening:
            this.draggableImageIndex === null
              ? true
              : index === this.draggableImageIndex
        }
        if (this.selectedTool === 'alignBackgroundImage') { config.visible = this.isolatedBackgroundImage === index }
        return config
      })

      imagesConfigs.forEach((config) => {
        const newImage = new Konva.Image(config)
        if (config.RGBFilter && this.overlayMode !== 'compare') {
          newImage.cache()
          newImage.filters([
            ImgFunctions.filters.whiteToTransparent,
            ImgFunctions.filters.softBlack,
            Konva.Filters.RGB
          ])
        }

        newImage.on('dragstart', this.handleDragStart)
        newImage.on('dragend', this.handleDragEnd)
        newImage.on('mouseleave', this.handleShapeMouseLeave)
        newImage.on('mouseenter', this.handleShapeMouseEnter)
        mainLayer.getNode().add(newImage)
      })
      if (this.$refs.stage) this.$refs.stage.getStage().batchDraw()
    },

    async compareImages (images) {
      const compareIsCached =
        JSON.stringify(images) ===
        JSON.stringify(this.compareImagesCache.images)
      if (!compareIsCached) {
        const [blob1, blob2] = await Promise.all([
          fetch(images[0].url).then((res) => res.blob()),
          fetch(images[1].url).then((res) => res.blob())
        ])

        const [imgDataUrl1, imgDataUrl2] = await Promise.all([
          ImgFunctions.fileToDataUrl(blob1),
          ImgFunctions.fileToDataUrl(blob2)
        ])

        const image1 = {
          dataUrl: imgDataUrl1,
          settings: images[0].settings
        }

        const image2 = {
          dataUrl: imgDataUrl2,
          settings: images[1].settings
        }

        const comparedImages = await ImgFunctions.compareImages(image1, image2)

        this.compareImagesCache = {
          images: JSON.parse(JSON.stringify(images)),
          comparedImages: [
            {
              src: comparedImages.dataUrl1,
              title: images[0].title,
              settings: comparedImages.settings
            },
            {
              src: comparedImages.dataUrlCompare,
              title: 'compare',
              settings: comparedImages.settings
            },
            {
              src: comparedImages.dataUrl2,
              title: images[1].title,
              settings: comparedImages.settings
            }
          ]
        }
      }

      return this.compareImagesCache.comparedImages
    },

    activateMoveBackgroundImage (moveTool, index) {
      this.setPinsVisibility(false)
      this.setShapesVisibility(false)
      this.draggableImageIndex = null
      if (moveTool.tool === 'move') {
        this.draggableImageIndex = index
        this.setSelectedTool('moveBackgroundImage')
        this.isolatedBackgroundImage = null
      } else if (moveTool.tool === 'align') {
        this.setSelectedTool('alignBackgroundImage')
        this.alignFace = moveTool.face
        this.isolatedBackgroundImage =
          moveTool.positions.length === 0 ? 0 : index
      }
      this.loadBackgroundImages()
    },

    deactivateMoveBackgroundImage () {
      this.draggableImageIndex = null
      this.setSelectedTool('pan')
      this.setPinsVisibility(true)
      this.setShapesVisibility(true)
      this.loadBackgroundImages()
    },

    getLinePosition (face) {
      const cursorPosition = this.getCursorPosition()
      const ctx = this.$refs.mainLayer.getNode().getContext()

      const cursorImgData = ctx.getImageData(
        cursorPosition.x,
        cursorPosition.y,
        1,
        1
      ).data

      const backgroundColors = [
        [0, 0, 0, 0],
        [0, 0, 0, 255],
        [255, 255, 255, 255]
      ]

      function isBackground (data) {
        return backgroundColors.findIndex((backgroundColor) => {
          return data.every(
            (dataItem, index) => dataItem === backgroundColor[index]
          )
        })
      }

      // se a cor clicada for do tipo background, deve-se eliminar ela da lista de backgroundColors
      const cursorImgDataBackgroundIndex = isBackground(cursorImgData)
      if (cursorImgDataBackgroundIndex !== -1) { backgroundColors.splice(cursorImgDataBackgroundIndex, 1) }

      const faceFunctions = {
        left: {
          x: (x, i) => x - i,
          y: (y, i) => y,
          coordinate: 'x'
        },
        top: {
          x: (x, i) => x,
          y: (y, i) => y - i,
          coordinate: 'y'
        },
        right: {
          x: (x, i) => x + i,
          y: (y, i) => y,
          coordinate: 'x'
        },
        bottom: {
          x: (x, i) => x,
          y: (y, i) => y + i,
          coordinate: 'y'
        }
      }

      for (let i = 0; i <= 1000; i++) {
        const offsetPosition = {
          x: faceFunctions[face].x(cursorPosition.x, i),
          y: faceFunctions[face].y(cursorPosition.y, i)
        }

        const offsetImgData = ctx.getImageData(
          offsetPosition.x,
          offsetPosition.y,
          1,
          1
        ).data

        if (isBackground(offsetImgData) !== -1) {
          const relativeOffsetPosition = cursorPosition
            .sub(this.viewpoint)
            .sub({ x: i, y: i })
            .div(this.viewpoint.scale)

          return relativeOffsetPosition[faceFunctions[face].coordinate]
        }
      }
    },

    // navigation methods
    setInitialViewpoint () {
      if (this.value.viewpoint) this.loadViewpoint(this.value.viewpoint)
      else this.zoomExtends()
      this.initialViewpointCalculate = true
    },
    loadViewpoint (savedViewpoint) {
      const calcudatedWidthScale =
        (this.viewpoint.width / savedViewpoint.width) * savedViewpoint.scale
      const calcudatedHeigthScale =
        (this.viewpoint.height / savedViewpoint.height) * savedViewpoint.scale
      this.viewpoint.scale = Math.min(
        calcudatedWidthScale,
        calcudatedHeigthScale
      )

      const absoluteViewpointTranslation = new Point(savedViewpoint).div(
        savedViewpoint.scale
      )

      const deltaTranslateToCenter = new Point(
        this.viewpoint.width / this.viewpoint.scale -
          savedViewpoint.width / savedViewpoint.scale,
        this.viewpoint.height / this.viewpoint.scale -
          savedViewpoint.height / savedViewpoint.scale
      ).div(2)

      const calculatedTranslation = new Point(absoluteViewpointTranslation)
        .add(deltaTranslateToCenter)
        .mult(this.viewpoint.scale)

      this.viewpoint.x = calculatedTranslation.x
      this.viewpoint.y = calculatedTranslation.y
    },
    zoomExtends () {
      const nodesClientRects = this.getNodesClientRect()
      if (!nodesClientRects) return
      const width = Math.max(
        nodesClientRects.width / this.viewpoint.scale,
        this.viewpoint.width
      )
      const height = Math.max(
        nodesClientRects.height / this.viewpoint.scale,
        this.viewpoint.height
      )

      const deltaWidth =
        this.viewpoint.width - nodesClientRects.width / this.viewpoint.scale
      const deltaHeight =
        this.viewpoint.height - nodesClientRects.height / this.viewpoint.scale

      let x = deltaWidth > 0 ? deltaWidth / 2 : 0
      let y = deltaHeight > 0 ? deltaHeight / 2 : 0

      x -= (nodesClientRects.x - this.viewpoint.x) / this.viewpoint.scale
      y -= (nodesClientRects.y - this.viewpoint.y) / this.viewpoint.scale

      const viewpoint = {
        width,
        height,
        x,
        y,
        scale: 1
      }
      this.loadViewpoint(viewpoint)
    },

    zoomMethod (delta) {
      this.$emit('userChangedViewpoint', true)
      const pivotStartPosition = this.getCursorPosition()
      const pivotStartRelativePosition = this.getRelativeCursorPosition()

      const factor = Math.pow(this.scaleFactor, delta)
      this.viewpoint.scale = this.viewpoint.scale * factor

      const pivotNewPosition = new Point(pivotStartRelativePosition)
        .mult(this.viewpoint.scale)
        .add(this.viewpoint)
      const deltaTranslate = new Point(pivotStartPosition).sub(pivotNewPosition)
      this.viewpoint.x += deltaTranslate.x
      this.viewpoint.y += deltaTranslate.y
    },
    getCursorPosition () {
      return new Point(this.$refs.stage.getStage().getPointerPosition())
    },
    getRelativeCursorPosition () {
      const cursorPosition = new Point(this.getCursorPosition())
      const relativeCursorPosition = cursorPosition
        .sub(this.viewpoint)
        .div(this.viewpoint.scale)
      return relativeCursorPosition
    },
    getCenterPosition () {
      return new Point(
        this.$refs.stage.getStage().width(),
        this.$refs.stage.getStage().height()
      ).div(2)
    },
    getRelativeCenterPosition () {
      const cursorPosition = new Point(this.getCenterPosition())
      const relativeCursorPosition = cursorPosition
        .sub(this.viewpoint)
        .div(this.viewpoint.scale)
      return relativeCursorPosition
    },

    getNodesClientRect () {
      const nodesClientRects = []

      const stageRef = this.$refs.stage
      if (!stageRef) return

      const layers = stageRef.getNode().getChildren()

      layers.forEach((layer) => {
        const layerNodes = layer.getChildren(
          (node) => node.getClassName() !== 'Transformer'
        )
        const layerNodesClientRects = layerNodes.map((node) =>
          node.getClientRect()
        )
        const { x, y, width, height } = layerNodesClientRects
        if (isNaN(x) && isNaN(y) && isNaN(width) && isNaN(height)) { nodesClientRects.push(...layerNodesClientRects) }
      })

      if (!nodesClientRects) return

      const minX = Math.min(...nodesClientRects.map((rect) => rect.x))
      const maxX = Math.max(
        ...nodesClientRects.map((rect) => rect.x + rect.width)
      )
      const minY = Math.min(...nodesClientRects.map((rect) => rect.y))
      const maxY = Math.max(
        ...nodesClientRects.map((rect) => rect.y + rect.height)
      )

      return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY,
        endX: maxX,
        endY: maxY
      }
    },

    setShapesDraggable (value) {
      if (!this.$refs.layer) return
      const allShapes = this.$refs.layer
        .getNode()
        .getChildren(
          (node) =>
            node.getClassName() !== 'Image' &&
            node.getClassName() !== 'Transformer'
        )
      Object.values(this.shapes).forEach((shapeList) => {
        shapeList.forEach((shape) => {
          shape.draggable = value
        })
      })
      allShapes.forEach((shape) => {
        shape.setAttr('draggable', value)
      })
      const pins = this.$refs.pin
      if (pins) {
        pins.forEach((pin) => {
          pin.getNode().setAttr('draggable', value)
        })
      }
    },

    setShapesListening (value) {
      if (!this.$refs.layer) return
      const allShapes = this.$refs.layer
        .getNode()
        .getChildren(
          (node) =>
            node.getClassName() !== 'Image' &&
            node.getClassName() !== 'Transformer'
        )
      Object.values(this.shapes).forEach((shapeList) => {
        shapeList.forEach((shape) => {
          shape.listening = value
        })
      })
      allShapes.forEach((shape) => {
        shape.setAttr('listening', value)
      })
    },

    setShapesVisibility (value) {
      this.showMarkups = value
      if (!this.$refs.layer) return
      const allShapes = this.$refs.layer
        .getNode()
        .getChildren(
          (node) =>
            node.getClassName() !== 'Image' &&
            node.getClassName() !== 'Transformer'
        )
      Object.values(this.shapes).forEach((shapeList) => {
        shapeList.forEach((shape) => {
          shape.visible = value
        })
      })
      allShapes.forEach((shape) => {
        shape.setAttr('visible', value)
      })
      if (this.$refs.stage) this.$refs.stage.getStage().batchDraw()
    },

    setPinsDraggable (value) {
      const pins = this.$refs.pin
      if (pins) {
        pins.forEach((pin) => {
          pin.getNode().setAttr('draggable', value)
        })
      }
    },

    setPinsListening (value) {
      const pins = this.$refs.pin
      if (pins) {
        pins.forEach((pin) => {
          pin.getNode().setAttr('listening', value)
        })
      }
    },

    setPinsVisibility (value) {
      this.showPins = value
      const pins = this.$refs.pin
      if (pins) {
        pins.forEach((pin) => {
          pin.getNode().setAttr('visible', value)
        })
      }
      if (this.$refs.stage) this.$refs.stage.getStage().batchDraw()
    },

    setBackgroundImagesDraggable (value, index) {
      this.backgroundImagesNodes().forEach((imageNode) =>
        imageNode.draggable(value)
      )
    },

    resizeMarker () {
      if (this.$refs.marker) {
        this.viewpoint.width = this.$refs.marker.clientWidth
        this.viewpoint.height = this.$refs.marker.clientHeight
      }
    },
    // keys event handlers
    escPressed () {
      if (!this.editing) return
      if (this.selectedShapeId) {
        this.setSelectedTool('select')
      } else {
        this.clearSelection()
      }
    },
    handleStageMouseDown (e) {
      this.startPosition = this.getRelativeCursorPosition()

      if (e.target.attrs.name === 'divider') return
      if (
        e.target.getParent() &&
        e.target.getParent().className === 'Transformer'
      ) {
        // Caso o usuário esteja com alguma ferramenta de desenho e clique nas ancoras de ajuste de outra forma
        return // não desenhar e permitir que o usuário altere as propriedas da forma clicada
      }
      if (e.evt.button === 1) {
        this.saveSelectedToolStateBeforePan()
        this.setBackgroundImagesDraggable(false)
        this.setSelectedTool('pan')
        this.startTool(e)
      }
      if (e.evt.button !== 0) return
      if (e.target.attrs.name === 'pin') { this.$emit('pinClicked', e.target.attrs.id) }
      this.startTool(e)
    },

    handleStageMouseMove (e) {
      const currentPosition = this.getRelativeCursorPosition()
      const delta = new Point(currentPosition).sub(this.startPosition)

      if (this.selectedTool === 'pan' && this.isMovingPan) {
        this.$emit('userChangedViewpoint', true)
        this.viewpoint.x += delta.x * this.viewpoint.scale
        this.viewpoint.y += delta.y * this.viewpoint.scale
      }

      if (
        (this.selectedTool === 'rect' || this.selectedTool === 'highlight') &&
        this.isDrawing
      ) {
        this.shapes.rectangles[this.shapes.rectangles.length - 1].width =
          delta.x
        this.shapes.rectangles[this.shapes.rectangles.length - 1].height =
          delta.y
        this.$refs.rect[this.shapes.rectangles.length - 1]
          .getNode()
          .width(delta.x)
        this.$refs.rect[this.shapes.rectangles.length - 1]
          .getNode()
          .height(delta.y)
      }
      if (this.selectedTool === 'circle' && this.isDrawing) {
        const a = delta.x
        const b = delta.y
        const c = Math.pow(Math.pow(a, 2) + Math.pow(b, 2), 1 / 2)
        this.shapes.circles[this.shapes.circles.length - 1].radius = c
        if (c > 0) {
          this.$refs.circle[this.shapes.circles.length - 1].getNode().radius(c)
        }
      }
      if (this.selectedTool === 'line' && this.isDrawing) {
        this.shapes.lines[this.shapes.lines.length - 1].points = [
          0,
          0,
          delta.x,
          delta.y
        ]
        this.$refs.line[this.shapes.lines.length - 1]
          .getNode()
          .points([0, 0, delta.x, delta.y])
      }
      if (this.selectedTool === 'arrow' && this.isDrawing) {
        this.shapes.arrows[this.shapes.arrows.length - 1].points = [
          0,
          0,
          delta.x,
          delta.y
        ]
        this.$refs.arrow[this.shapes.arrows.length - 1]
          .getNode()
          .points([0, 0, delta.x, delta.y])
      }
      this.$refs.layer.getNode().batchDraw()
    },
    handleStageMouseUp (e) {
      if (this.isDrawing && this.lastCreatedShape) {
        this.selectedShape = this.lastCreatedShape
        this.selectedShapeNode = this.findShapeNode(this.lastCreatedShape.id)
        this.updateTransformer()
      }

      this.isDrawing = false
      this.isMovingPan = false
      if (e.evt.button === 1) this.restoreSelectedToolStateBeforePan()

      if (this.selectedTool === 'text') {
        this.setSelectedTool('select')
      }
    },
    async handleDragStart (e) {
      this.lastCursor = this.cursor
      this.cursor = 'grabbing'
    },
    async handleDragEnd (e) {
      this.cursor = this.lastCursor
      if (this.selectedShape) {
        this.selectedShape.x = e.target.attrs.x
        this.selectedShape.y = e.target.attrs.y
      }
      if (e.target.name() === 'pin') {
        e.target.attrs.originalX =
          e.target.attrs.x +
          e.target.attrs.originalWidth / 2 / this.viewpoint.scale
        e.target.attrs.originalY =
          e.target.attrs.y +
          e.target.attrs.originalHeight / this.viewpoint.scale
        const pinStageElement = this.pinsStageElements.find(
          (pin) => pin.id === e.target.attrs.id
        )
        if (pinStageElement) {
          pinStageElement.originalX = e.target.attrs.originalX
          pinStageElement.originalY = e.target.attrs.originalY
        }
      }
      if (e.target.name() === 'backgroundImage') {
        this.$emit('backgroundImageMoved', {
          x: e.target.attrs.x,
          y: e.target.attrs.y
        })
      }
    },
    handleMouseScroll (e) {
      e.evt.preventDefault()
      const evt = e.evt
      const delta = evt.wheelDelta
        ? evt.wheelDelta / 40
        : evt.detail
          ? -evt.detail
          : 0
      if (delta) this.zoomMethod(delta)
    },
    handleStageDblClick (e) {
      if (e.evt.button === 1) this.zoomExtends()
    },
    handleShapeMouseEnter (e) {
      if (e.target.attrs.name === 'divider') this.cursor = 'col-resize'
      else if (e.target.attrs.draggable) this.cursor = 'grab'
      else if (!this.editing && e.target.attrs.name === 'pin') { this.cursor = 'pointer' }
    },
    handleShapeMouseLeave (e) {
      this.cursor = this.getSelectedToolCursor()
    },

    handleDividerDragMove (e) {
      const minGap = 100
      const targetDividerIndex = this.dividers.findIndex(
        (divider) => divider.id === e.target.attrs.id
      )
      if (targetDividerIndex === -1) return
      this.dividers[targetDividerIndex].x = e.target.x()
      this.dividers.forEach((divider, index) => {
        if (index > targetDividerIndex && divider.x - e.target.x() < minGap) {
          divider.x = e.target.x() + minGap
          this.$refs.dividerGroup[index].getNode().x(divider.x)
        }
        if (index < targetDividerIndex && e.target.x() - divider.x < minGap) {
          divider.x = e.target.x() - minGap
          this.$refs.dividerGroup[index].getNode().x(divider.x)
        }
      })
      this.updateDividersPositions()
      this.applyDividerToImages()
    },

    // tools functions
    setSelectedTool (tool) {
      this.clearSelection()
      this.selectedTool = tool
      this.cursor = this.getSelectedToolCursor()
      switch (tool) {
        case 'select':
          this.setShapesDraggable(true)
          this.setPinsDraggable(true)
          this.setShapesListening(true)
          break
        default:
          this.setShapesDraggable(false)
          this.setPinsDraggable(false)
          this.setShapesListening(false)
          break
      }
      if (this.$refs.stage) this.$refs.stage.getStage().batchDraw()
    },
    getSelectedToolCursor () {
      const toolsCursors = {
        pan: 'move',
        select: 'default',
        alignBackgroundImage: 'default'
      }
      return toolsCursors[this.selectedTool] || 'crosshair'
    },

    clearedState () {
      return {
        rectangles: [],
        circles: [],
        lines: [],
        arrows: [],
        texts: []
      }
    },

    saveSelectedToolStateBeforePan () {
      this.selectedToolStateBeforePan.selectedTool = this.selectedTool
      this.selectedToolStateBeforePan.cursor = this.cursor
      const images = this.backgroundImagesNodes()
      this.selectedToolStateBeforePan.imagesDraggable = images.map((image) =>
        image.draggable()
      )
      this.selectedToolStateBeforePan.imagesListening = images.map((image) =>
        image.listening()
      )
    },

    restoreSelectedToolStateBeforePan () {
      this.cursor = this.selectedToolStateBeforePan.cursor
      this.selectedTool = this.selectedToolStateBeforePan.selectedTool
      const images = this.backgroundImagesNodes()
      images.forEach((image, index) => {
        image.draggable(this.selectedToolStateBeforePan.imagesDraggable[index])
      })
      images.forEach((image, index) => {
        image.listening(this.selectedToolStateBeforePan.imagesListening[index])
      })
    },

    startTool (e) {
      const toolFunction = this.toolsFunctions()[this.selectedTool]
      toolFunction && toolFunction.start && toolFunction.start.bind(this)(e)
    },
    toolsFunctions () {
      return {
        pin: {
          async start (e) {
            this.$emit('pinCreated')
            await this.loadPins([
              {
                id: 'new',
                x: this.startPosition.x,
                y: this.startPosition.y,
                color: 'red'
              }
            ])
            this.updatePinsPosition()
          }
        },
        select: {
          start (e) {
            if (
              e.target.attrs.name !== 'backgroundImage' &&
              e.target.attrs.id
            ) {
              this.selectedShape = this.findShape(e.target.attrs.id)
              this.selectedShapeNode = this.findShapeNode(e.target.attrs.id)
              this.updateTransformer()
            } else {
              const textarea = document.querySelector('#textArea')
              if (textarea) {
                if (this.selectedShape.name === 'text') {
                  this.selectedShape.text = textarea.value
                }
                if (
                  this.selectedShapeNode &&
                  this.selectedShapeNode.attrs.name === 'text'
                ) {
                  this.selectedShapeNode.text(textarea.value)
                  this.removeTextarea(this.selectedShapeNode)
                }
              } else {
                this.clearSelection()
              }
            }
          }
        },
        pan: {
          start (e) {
            this.isMovingPan = true
          }
        },
        rect: {
          start (e) {
            if (e.target.attrs.draggable) return
            this.setShapesDraggable(false)
            this.setPinsDraggable(false)
            this.isDrawing = true
            const pos = this.startPosition
            const rect = new Konva.Rect({
              id: uuidv4(),
              rotation: 0,
              x: pos.x,
              y: pos.y,
              width: 0,
              height: 0,
              scaleX: 1,
              scaleY: 1,
              stroke: this.strokeColor,
              fill: this.fillColor,
              strokeWidth: 5,
              strokeScaleEnabled: false,
              draggable: false,
              name: 'rectangle'
            })
            this.shapes.rectangles.push(rect.attrs)
            this.lastCreatedShape =
              this.shapes.rectangles[this.shapes.rectangles.length - 1]
          }
        },
        circle: {
          start (e) {
            if (e.target.attrs.draggable) return
            this.setShapesDraggable(false)
            this.setPinsDraggable(false)
            this.isDrawing = true
            const pos = this.startPosition
            const circle = new Konva.Circle({
              id: uuidv4(),
              x: pos.x,
              y: pos.y,
              radius: 40,
              fill: this.fillColor,
              stroke: this.strokeColor,
              strokeWidth: 4,
              draggable: false,
              name: 'circle',
              strokeScaleEnabled: false
            })
            this.shapes.circles.push(circle.attrs)
            this.lastCreatedShape =
              this.shapes.circles[this.shapes.circles.length - 1]
          }
        },
        highlight: {
          start (e) {
            if (e.target.attrs.draggable) return
            this.setShapesDraggable(false)
            this.setPinsDraggable(false)
            this.isDrawing = true
            const pos = this.startPosition
            const rectHighlight = new Konva.Rect({
              id: uuidv4(),
              rotation: 0,
              x: pos.x,
              y: pos.y,
              width: 0,
              height: 0,
              fill: this.fillColor ? this.fillColor : this.strokeColor,
              opacity: 0.5,
              draggable: false,
              name: 'rectangle'
            })
            this.shapes.rectangles.push(rectHighlight.attrs)
            this.lastCreatedShape =
              this.shapes.rectangles[this.shapes.rectangles.length - 1]
          }
        },
        line: {
          start (e) {
            if (e.target.attrs.draggable) return
            this.setShapesDraggable(false)
            this.setPinsDraggable(false)
            this.isDrawing = true
            const pos = this.startPosition
            const line = new Konva.Line({
              id: uuidv4(),
              points: [0, 0, 0, 0],
              stroke: this.strokeColor,
              strokeWidth: 4,
              lineCap: 'round',
              lineJoin: 'round',
              draggable: false,
              name: 'line',
              x: pos.x,
              y: pos.y,
              strokeScaleEnabled: false
            })
            this.shapes.lines.push(line.attrs)
            this.lastCreatedShape =
              this.shapes.lines[this.shapes.lines.length - 1]
          }
        },
        arrow: {
          start (e) {
            if (e.target.attrs.draggable) return
            this.setShapesDraggable(false)
            this.setPinsDraggable(false)
            this.isDrawing = true
            const pos = this.startPosition
            const arrow = new Konva.Arrow({
              id: uuidv4(),
              x: pos.x,
              y: pos.y,
              points: [0, 0, 0, 0],
              pointerLength: 15,
              pointerWidth: 15,
              stroke: this.strokeColor,
              fill: this.strokeColor,
              strokeWidth: 4,
              draggable: false,
              strokeScaleEnabled: false,
              name: 'arrow'
            })
            this.shapes.arrows.push(arrow.attrs)
            this.lastCreatedShape =
              this.shapes.arrows[this.shapes.arrows.length - 1]
          }
        },
        text: {
          start (e) {
            if (e.target.attrs.draggable) return
            this.setShapesDraggable(false)
            this.setPinsDraggable(false)
            this.isDrawing = true
            const pos = this.startPosition
            const text = new Konva.Text({
              id: uuidv4(),
              x: pos.x,
              y: pos.y,
              text: 'Digite aqui',
              fontSize: 30,
              fontFamily: 'Calibri',
              fill: this.strokeColor,
              name: 'text',
              draggable: false,
              keepRatio: true,
              strokeScaleEnabled: false,
              scaleX: 1 / this.viewpoint.scale,
              scaleY: 1 / this.viewpoint.scale
            })
            this.shapes.texts.push(text.attrs)
            this.lastCreatedShape =
              this.shapes.texts[this.shapes.texts.length - 1]
          }
        },
        alignBackgroundImage: {
          start (e) {
            this.$emit('linePosition', this.getLinePosition(this.alignFace))
          }
        }
      }
    },

    // shapes handlers
    removeShape () {
      if (!this.selectedShape) return
      const shapeListName = this.selectedShape.name + 's'
      const shapeIndex = this.shapes[shapeListName].findIndex(
        (shape) => shape.id === this.selectedShape.id
      )
      if (shapeIndex === -1) return
      this.shapes[shapeListName].splice(shapeIndex, 1)
      this.clearSelection()
    },
    clearSelection () {
      this.selectedShape = null
      this.selectedShapeNode = null
      this.updateTransformer()
    },
    findShape (id) {
      for (const shapeType of Object.keys(this.shapes)) {
        const foundShape = this.shapes[shapeType].find(
          (shape) => shape.id === id
        )
        if (foundShape) return foundShape
      }
    },
    findShapeNode (id) {
      const shapes = this.$refs.layer
        .getNode()
        .getChildren((node) => node.attrs.id === id)
      return shapes[0]
    },

    changeShapeColor (type, color) {
      if (!this.selectedShape || !this.selectedShapeNode) return
      if (type === 1) {
        // Verifica se a cor que usuário deseja mudar é a primária.
        this.selectedShape.fill = color
        this.selectedShapeNode.fill(color)
      } else {
        this.selectedShape.stroke = color
        this.selectedShapeNode.stroke(color)
      }
      this.$refs.layer.getNode().batchDraw()
    },
    editingTextMethod (e) {
      // Esconder texto e transformador
      e.target.hide()
      this.$refs.transformer.getNode().hide()
      e.target.getLayer().draw()
      // criar textarea sobre tela com posição absoluta

      // at first lets find position of text node relative to the stage:
      const textPosition = e.target.absolutePosition()

      // then lets find position of stage container on the page:
      const stageBox = e.target.getStage().container().getBoundingClientRect()
      // so position of textarea will be the sum of positions above:
      const areaPosition = {
        x: stageBox.left + textPosition.x,
        y: stageBox.top + textPosition.y
      }

      // create textarea and style it
      const textarea = document.createElement('textarea')
      document.body.appendChild(textarea)
      this.textareaVisible = true
      // apply many styles to match text on canvas as close as possible
      // remember that text rendering on canvas and on the textarea can be different
      // and sometimes it is hard to make it 100% the same. But we will try...
      textarea.value = e.target.text()
      textarea.id = 'textArea'
      textarea.style.position = 'absolute'
      textarea.style.top = areaPosition.y + 'px'
      textarea.style.left = areaPosition.x + 'px'
      textarea.style.width = e.target.width() - e.target.padding() * 2 + 'px'
      textarea.style.height =
        e.target.height() - e.target.padding() * 2 + 5 + 'px'
      textarea.style.fontSize = e.target.fontSize() + 'px'
      textarea.style.border = 'none'
      textarea.style.padding = '0px'
      textarea.style.margin = '0px'
      textarea.style.overflow = 'hidden'
      textarea.style.background = 'none'
      textarea.style.outline = 'none'
      textarea.style.resize = 'none'
      textarea.style.lineHeight = e.target.lineHeight()
      textarea.style.fontFamily = e.target.fontFamily()
      textarea.style.transformOrigin = 'left top'
      textarea.style.textAlign = e.target.align()
      textarea.style.color = e.target.fill()
      textarea.style.zIndex = '9999'
      const rotation = e.target.rotation()
      let transform = ''
      if (rotation) {
        transform += 'rotateZ(' + rotation + 'deg)'
      }
      textarea.style.transform = transform

      // reset height
      textarea.style.height = 'auto'
      // after browsers resized it we can set actual value
      textarea.style.height = textarea.scrollHeight + 3 + 'px'

      textarea.focus()
      textarea.addEventListener('keydown', (t) => {
        const scale = e.target.getAbsoluteScale().x
        this.setTextareaWidth(e, e.target.width() * scale, textarea)
        textarea.style.height = 'auto'
        textarea.style.height =
          textarea.scrollHeight + e.target.fontSize() + 'px'
      })
      textarea.addEventListener('keydown', (t) => {
        // hide on enter
        // but don't hide on shift + enter
        if (t.keyCode === 13 && !t.shiftKey) {
          this.removeTextarea(e.target)
        }
        // on esc do not set value back to node
        if (t.keyCode === 27) {
          this.removeTextarea(e.target)
        }
      })
      textarea.addEventListener('focusout', (t) => {
        this.saveTextEdition(e, textarea)
        this.removeTextarea(e.target)
      })
    },
    saveTextEdition (e, textarea) {
      if (this.selectedShape && this.selectedShape.name === 'text') {
        e.target.text(textarea.value)
        this.selectedShape.text = textarea.value
      }
    },
    removeTextarea (target) {
      const textarea = document.querySelector('#textArea')
      if (!textarea) return
      try {
        textarea.remove()
      } catch (e) {}
      this.textareaVisible = false
      target.show()
      this.$refs.transformer.getNode().show()
      this.updateTransformer()
      target.getLayer().draw()
    },
    setTextareaWidth (e, newWidth) {
      const textarea = document.getElementById('textArea')
      if (!newWidth) {
        // set width for placeholder
        newWidth = e.target.placeholder.length * e.target.fontSize()
      }
      // some extra fixes on different browsers
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1
      if (isSafari || isFirefox) {
        newWidth = Math.ceil(newWidth)
      }

      const isEdge = document.documentMode || /Edge/.test(navigator.userAgent)
      if (isEdge) {
        newWidth += 1
      }
      textarea.style.width = newWidth + 'px'
    },

    updateTransformer () {
      if (!this.editing) return
      // ↓ Aqui, precisamos anexar ou desanexar manualmente o nó do transformador
      const transformerNode = this.$refs.transformer.getNode()
      transformerNode.nodes([])

      if (this.selectedShapeNode) {
        // ↓ Anexa o nó no transformador
        transformerNode.nodes([this.selectedShapeNode])
        this.selectedShapeNode.setAttr('draggable', true)
      }
    },
    handleTransformEnd (e) {
      if (!this.selectedShape) return
      this.selectedShape.x = e.target.x()
      this.selectedShape.y = e.target.y()
      this.selectedShape.rotation = e.target.rotation()
      this.selectedShape.scaleX = e.target.scaleX()
      this.selectedShape.scaleY = e.target.scaleY()
    }
  }
}
</script>

<style lang="scss" module>
.rightMenuContainer {
  grid-area: rightMenu;
  position: relative;
}
.rightMenuFloating {
  position: absolute;
  top: 10rem;
  left: -5rem;
  width: 10rem;
  display: grid;
  grid-auto-rows: min-content;
  row-gap: 1rem;
}
</style>

<style scoped>
.cf-image-editor-layout {
  display: grid;
  grid-template: 1fr / min-content 1fr min-content;
  grid-template-areas: 'tools stage rightMenu';
  overflow: hidden;
  margin: 0 1rem;
}

.cFlow-marker {
  grid-area: stage;
  cursor: crosshair;
  background-color: white;
  overflow: hidden;
}

.cFlow-markerController {
  grid-area: tools;
  width: 40px;
  background-color: white;
  padding-top: 8rem;
}
</style>
