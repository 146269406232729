const DocumentsView = () => import('../views/DocumentsView')
const BimwebView = () => import('../../issues/views/BimwebView.vue')

export default [
  {
    path: 'drive',
    name: 'projectDocuments',
    component: DocumentsView
  },
  {
    path: 'documentViewer/:documentId',
    name: 'documentViewer',
    component: BimwebView
  }
]
