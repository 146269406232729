<template>
  <div
    :class="$style.menuItem"
    :disabled="disabled"
    :active="active"
    @click="clicked"
  >
    <div>
      <cf-avatar
        v-if="showImage"
        :src="image"
        :type="imageType"
        imgStyle="max-height: 2rem; max-width: 2rem"
      />
    </div>
    <div>
      <cf-span
        v-if="icon"
        size="sm"
        :class="icon"
      />
    </div>
    <div>
      <cf-span size="sm">
        {{ label }}
      </cf-span>
    </div>
  </div>
</template>
<script>
import cfAvatar from './cf-avatar.vue'
export default {
  components: { cfAvatar },
  props: {
    label: {
      type: String,
      default: null
    },
    active: Boolean,
    showImage: Boolean,
    image: {
      type: String,
      default: null
    },
    imageType: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clicked () {
      if (!this.disabled) this.$emit('click')
    }
  }
}
</script>

<style lang="scss" module>
.menuItem {
  display: grid;
  grid-template: 1fr / auto auto 1fr;
  align-items: center;
  grid-gap: 0.5rem;
  margin: 0.5rem 0;
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: getColor('grey-lighter');
  }
  &[disabled] {
    cursor: not-allowed;
  }
  &[active] {
    background-color: getColor('primary-lighter');
  }
}
</style>
