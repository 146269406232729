<template>
  <SettingsLayout
    title="Selecionar a imagem"
    :search="false"
    style="height: 100%"
  >
    <div style="padding: 0 2rem">
      <cropper
        v-if="image"
        ref="cropper"
        :wheelResize="false"
        :stencilProps="{
          aspectRatio: cropRatio
        }"
        :class="$style.cfCropper"
        :src="cropSource"
      />
      <div
        v-else
        :class="[$style.cfCropper, $style.cfCropperButton]"
        @click.stop.prevent="$refs.file.click()"
      >
        <span :class="['icon-upload', $style.iconUpload]" />
      </div>
    </div>
    <div :class="$style.cfButtonWrapper">
      <el-button
        v-show="false"
        type="primary"
        @click="$refs.file.click()"
      >
        <input
          ref="file"
          type="file"
          style="display: none"
          accept="image/*"
          @change="uploadImage($event)"
        >
      </el-button>
      <div
        style="align-self: center; color: #a3a3a3"
        :style="{ visibility: image ? 'visible' : 'hidden' }"
      >
        <span
          style="margin-right: 3rem"
          :class="['icon-upload', $style.cfIcon]"
          @click="$refs.file.click()"
        />
        <span
          :class="['icon-trash', $style.cfIcon]"
          @click="reset()"
        />
      </div>
      <div style="display: flex">
        <cf-button
          secondary
          style="margin-right: 0.5rem"
          @click="cancel()"
        >
          Cancelar
        </cf-button>
        <cf-button
          v-loading="loading"
          :disabled="!image"
          @click="crop()"
        >
          Salvar
        </cf-button>
      </div>
    </div>
  </SettingsLayout>
</template>
<script>
import { Cropper } from 'vue-advanced-cropper'
import SettingsLayout from '@/layouts/SettingsLayout'

export default {
  components: {
    Cropper,
    SettingsLayout
  },
  props: {
    cropRatio: {
      type: Number,
      default: null
    },
    defaultImage: {
      default: null,
      type: String
    }
  },
  data () {
    return {
      image: null,
      croppedImg: null,
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0
      },
      loading: false
    }
  },
  computed: {
    source () {
      if (this.defaultImage) return this.defaultImage
      else return null
    },
    cropSource () {
      if (this.image) return this.image
      else return this.source
    }
  },
  methods: {
    reset () {
      this.$refs.file.value = null
      this.image = null
    },
    cancel () {
      this.$emit('close')
    },
    async crop () {
      this.loading = true
      const { coordinates, canvas } = this.$refs.cropper.getResult()
      this.coordinates = coordinates
      const compressedImage = await this.resizeImage(canvas)
      this.croppedImg = compressedImage.toDataURL()
      compressedImage.toBlob(async (blob) => {
        const img = { file: blob, url: this.croppedImg }
        this.loading = false
        this.$emit('cropped', img)
      })
    },

    uploadImage (event) {
      // Carregando a img do disco
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.image = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    async resizeImage (img) {
      if (this.cropTo === 'companyHorizontal') {
        const imgAspectRatio = img.width / img.height
        const finalAspectRatio = 800 / 200
        const calcAspectRatio = imgAspectRatio / finalAspectRatio
        let width, height
        if (calcAspectRatio >= 1) {
          height = 200
          width = imgAspectRatio * height
        } else {
          width = 800
          height = width / imgAspectRatio
        }
        img.crossOrigin = 'Anonymous'
        const elem = document.createElement('canvas')
        elem.width = width
        elem.height = height
        const ctx = elem.getContext('2d')
        ctx.drawImage(img, 0, 0, elem.width, elem.height)
        return ctx.canvas
      } else {
        img.crossOrigin = 'Anonymous'
        const elem = document.createElement('canvas')
        elem.width = 200
        elem.height = 200
        const ctx = elem.getContext('2d')
        ctx.drawImage(img, 0, 0, elem.width, elem.height)
        return ctx.canvas
      }
    }
  }
}
</script>
<style lang="scss" module>
.cfCropper {
  border: solid 0.2rem getColor('grey');
  height: 23rem;
  width: 33rem;
  border-image: url('./../assets/border.png');
  border-image-slice: 30%;
  border-image-width: 100%;
}
.cfCropperButton {
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}
.cfButtonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.cfIcon {
  font-size: 2rem;
  cursor: pointer;
}
.iconUpload {
  font-size: 2.5rem;
  color: getColor('grey');
}
</style>
