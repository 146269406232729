class Point {
  constructor (...args) {
    if (args.length === 2) {
      this.x = args[0]
      this.y = args[1]
    } else {
      this.x = args[0].x
      this.y = args[0].y
    }
  }

  add (point) {
    this.x += point.x
    this.y += point.y
    return this
  }

  sub (point) {
    this.x -= point.x
    this.y -= point.y
    return this
  }

  mult (number) {
    this.x = this.x * number
    this.y = this.y * number
    return this
  }

  div (number) {
    this.x = this.x / number
    this.y = this.y / number
    return this
  }
}

export default Point
