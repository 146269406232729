const BimwebView = () => import('../views/BimwebView')
const projectDashboardsView = () => import('../views/projectDashboardsView')

export default [
  {
    path: 'issues',
    name: 'projectIssues',
    component: BimwebView
  },
  {
    path: 'dashboards/:chartName',
    name: 'projectDashboards',
    component: projectDashboardsView
  }
]
