<template>
  <el-pagination
    layout="prev, pager, next"
    :total="value.length"
    :page-size="pageSize"
    hide-on-single-page
    @current-change="changePage"
  />
</template>

<script>
export default {
  props: {
    pageSize: {
      type: Number,
      default: 20
    },
    value: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      currentPage: 1
    }
  },
  watch: {
    pageSize () {
      this.changePage(1)
    },
    value (newValue, oldValue) {
      if (newValue.length !== oldValue.length) this.changePage(1)
    }
  },
  created () {
    this.calculateCurrentPageItems()
  },
  methods: {
    changePage (e) {
      this.currentPage = e
      this.calculateCurrentPageItems()
    },
    calculateCurrentPageItems () {
      const pageFirstItem = (this.currentPage - 1) * this.pageSize
      const pageLastItem = this.currentPage * this.pageSize
      const pageItems = this.value.slice(pageFirstItem, pageLastItem)
      this.$emit('input', pageItems)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-pager li,
::v-deep .btn-next,
::v-deep .btn-prev,
::v-deep .more {
  color: getColor('grey');
  font-weight: 400;
}
::v-deep .el-pager li.active {
  color: getColor('black');
}
// ::v-deep .el-pagination button:disabled {
//   color: getColor("grey-lighter");
// }
</style>
