import apollo from '@/plugins/apollo'
import getProjectForgeUploadAccessTokenQuery from './graphql/queries/getProjectForgeUploadAccessToken.gql'
import getProjectForgeUploadSignedUrlQuery from './graphql/queries/getProjectForgeUploadSignedUrl.gql'
import getProjectForgeDownloadSignedUrlQuery from './graphql/queries/getProjectForgeDownloadSignedUrl.gql'
import getProjectForgeFilesQuery from './graphql/queries/getProjectForgeFiles.gql'
import createProjectForgeFileMutation from './graphql/mutations/createProjectForgeFile.gql'
import updateProjectForgeFileMutation from './graphql/mutations/updateProjectForgeFile.gql'
import getProjectForgeReadAccessTokenQuery from './graphql/queries/getProjectForgeReadAccessToken.gql'

const getProjectForgeUploadAccessToken = async (projectId) => {
  const variables = { projectId }

  const response = await apollo.query({
    query: getProjectForgeUploadAccessTokenQuery,
    variables,
    fetchPolicy: 'network-only'
  })

  return response.data.getProjectForgeUploadAccessToken
}

const getProjectForgeUploadSignedUrl = async (projectId, filename) => {
  const variables = { projectId, filename }

  const response = await apollo.query({
    query: getProjectForgeUploadSignedUrlQuery,
    variables,
    fetchPolicy: 'network-only'
  })

  return response.data.getProjectForgeUploadSignedUrl
}

const getProjectForgeDownloadSignedUrl = async (projectId, modelId) => {
  const variables = { projectId, modelId }

  const response = await apollo.query({
    query: getProjectForgeDownloadSignedUrlQuery,
    variables,
    fetchPolicy: 'network-only'
  })

  return response.data.getProjectForgeDownloadSignedUrl
}

const getProjectForgeReadAccessToken = async (projectId) => {
  const variables = { projectId }

  const response = await apollo.query({
    query: getProjectForgeReadAccessTokenQuery,
    variables,
    fetchPolicy: 'network-only'
  })

  return response.data.getProjectForgeReadAccessToken
}

const getProjectForgeFiles = async (projectId) => {
  const variables = { projectId }

  const response = await apollo.query({
    query: getProjectForgeFilesQuery,
    variables,
    fetchPolicy: 'network-only'
  })

  return response.data.project.forgeFiles
}

const createProjectForgeFile = async (variables) => {
  const response = await apollo.mutate({
    mutation: createProjectForgeFileMutation,
    variables
  })

  return response.data.createProjectForgeFile
}

const updateProjectForgeFile = async (model) => {
  const response = await apollo.mutate({
    mutation: updateProjectForgeFileMutation,
    variables: model
  })

  return response.data.updateProjectModel
}

export default {
  getProjectForgeUploadAccessToken,
  getProjectForgeUploadSignedUrl,
  getProjectForgeDownloadSignedUrl,
  getProjectForgeFiles,
  createProjectForgeFile,
  getProjectForgeReadAccessToken,
  updateProjectForgeFile
}
