import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import defaultLocale from '@/translations/pt-BR.js'

Vue.use(VueI18n)
export const avaliableLocales = [
  { id: 'en', name: 'English' },
  { id: 'es', name: 'Español' },
  { id: 'pt-BR', name: 'Português (Brasil)' }
]
const avaliableLocaleIds = avaliableLocales.map((locale) => locale.id)
const avaliableLocalesIdsWithoutIcons = avaliableLocaleIds.map(
  (localeId) => localeId.split('-')[0]
)

const defaultLocaleId = getClosestAvailableLocaleId(
  navigator.language || navigator.userLanguage
)
const defaultLocale = require(`@/translations/${defaultLocaleId}.js`).default

export const i18n = new VueI18n({
  locale: defaultLocale.id,
  messages: { [defaultLocale.id]: defaultLocale.messages },
  dateTimeFormats: { [defaultLocale.id]: defaultLocale.dateTimeFormats }
})

setLocale(defaultLocaleId)

const loadedLanguages = [defaultLocale.id]

function setI18nLocale (localeId) {
  i18n.locale = localeId
  document.querySelector('html').setAttribute('lang', localeId)
  return localeId
}

export async function setLocale (desiredLocaleId) {
  const localeId = getClosestAvailableLocaleId(desiredLocaleId)
  // If the same language
  if (i18n.locale === localeId) {
    return Promise.resolve(setI18nLocale(localeId))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(localeId)) {
    return Promise.resolve(setI18nLocale(localeId))
  }

  // If the language hasn't been loaded yet
  const { default: locale } = await import('@/translations/' + localeId + '.js')
  i18n.setLocaleMessage(locale.id, locale.messages)
  i18n.setDateTimeFormat(locale.id, locale.dateTimeFormats)
  loadedLanguages.push(localeId)
  return setI18nLocale(localeId)
}

function getClosestAvailableLocaleId (desiredLocaleId) {
  const desiredLocaleIdWithoutIcons = desiredLocaleId.split('-')[0]

  let closestLocaleId = avaliableLocaleIds.find(
    (localeId) => localeId === desiredLocaleId
  )
  if (!closestLocaleId) {
    closestLocaleId = avaliableLocaleIds.find(
      (localeId) => localeId === desiredLocaleIdWithoutIcons
    )
  }
  if (!closestLocaleId) {
    const localeIdIndex = avaliableLocalesIdsWithoutIcons.findIndex(
      (localeId) => localeId === desiredLocaleIdWithoutIcons
    )
    closestLocaleId = avaliableLocaleIds[localeIdIndex]
  }
  if (!closestLocaleId) {
    closestLocaleId = 'en'
  }
  return closestLocaleId
}
