import apollo from '@/plugins/apollo'
import projectCompaniesQuery from './graphql/queries/projectCompanies.gql'
import systemRolesQuery from './graphql/queries/systemRoles.gql'
import allCompaniesQuery from './graphql/queries/allCompanies.gql'
import inviteCompanyToProjectMutation from './graphql/mutations/inviteCompanyToProject.gql'
import setProjectCompanyRoleAndDiscplinesMutation from './graphql/mutations/setProjectCompaniesRoleAndDisciplines.gql'
import updateProjectCompanyInviteMutation from './graphql/mutations/updateProjectCompanyInvite.gql'
import deleteProjectCompanyInviteMutation from './graphql/mutations/deleteProjectCompanyInvite.gql'
import removeCompanyFromProjectMutation from './graphql/mutations/removeCompanyFromProject.gql'

async function getProjectCompanies (projectId) {
  const variables = { projectId }
  const response = await apollo.query({
    query: projectCompaniesQuery,
    fetchPolicy: 'network-only',
    variables
  })
  const invites = response.data.project.pendingInvites
  invites.forEach((invite) => {
    invite.disciplines = invite.disciplines.filter(Boolean)
    invite.disciplinesId = invite.disciplinesId.filter((disciplineId) =>
      invite.disciplines.some((discipline) => discipline.id === disciplineId)
    )
  })
  return {
    companies: response.data.project.companies,
    invites,
    disciplines: response.data.project.disciplines
  }
}

const getSystemRoles = async () => {
  const response = await apollo.query({
    query: systemRolesQuery,
    fetchPolicy: 'network-only'
  })
  return response.data.systemRoles
}

const getAllCompanies = async () => {
  const response = await apollo.query({
    query: allCompaniesQuery,
    fetchPolicy: 'network-only'
  })
  return response.data.companies
}

/* MUTATIONS */

const setProjectCompanyRoleAndDiscplines = async ({
  projectId,
  companyId,
  projectRoleId,
  allDisciplines,
  disciplinesId
}) => {
  const variables = {
    projectId,
    companyId,
    projectRoleId,
    allDisciplines,
    disciplinesId
  }
  const response = await apollo.mutate({
    mutation: setProjectCompanyRoleAndDiscplinesMutation,
    variables
  })
  return response.data
}

const inviteCompanyToProject = async ({
  projectId,
  companyId,
  projectRoleId,
  allDisciplines,
  disciplinesId
}) => {
  const variables = {
    projectId,
    companyId,
    projectRoleId,
    allDisciplines,
    disciplinesId
  }
  const response = await apollo.mutate({
    mutation: inviteCompanyToProjectMutation,
    variables
  })
  return response.data
}

const updateProjectCompanyInvite = async ({
  projectId,
  companyId,
  projectRoleId,
  allDisciplines,
  disciplinesId
}) => {
  const variables = {
    projectId,
    companyId,
    projectRoleId,
    allDisciplines,
    disciplinesId
  }
  const response = await apollo.mutate({
    mutation: updateProjectCompanyInviteMutation,
    variables
  })
  return response.data
}

const deleteProjectCompanyInvite = async ({ projectId, companyId }) => {
  const variables = { projectId, companyId }
  const response = await apollo.mutate({
    mutation: deleteProjectCompanyInviteMutation,
    variables
  })
  return response.data
}

const removeCompanyFromProject = async ({ projectId, companyId }) => {
  const variables = { projectId, companyId }
  const response = await apollo.mutate({
    mutation: removeCompanyFromProjectMutation,
    variables
  })
  return response.data
}

export {
  getProjectCompanies,
  getSystemRoles,
  getAllCompanies,
  inviteCompanyToProject,
  setProjectCompanyRoleAndDiscplines,
  updateProjectCompanyInvite,
  deleteProjectCompanyInvite,
  removeCompanyFromProject
}
