<template>
  <div :class="$style.item">
    <div
      v-if="label"
      :class="$style.labelDiv"
    >
      <cf-span
        :size="size"
        :class="$style.labelClass"
        :color="inputError ? 'red' : 'grey'"
      >
        {{ label }}
      </cf-span>
      <cf-span
        v-show="required"
        size="lg"
        :color="inputError ? 'red' : 'grey'"
      >
        *
      </cf-span>
    </div>
    <div :class="$style.inputDiv">
      <input
        v-model="inputValue"
        :class="inputStyles"
        :type="showPassword ? 'text' : type"
        :placeholder="placeholder"
        :style="`width: ${width}; height: ${height}; line-height: ${height}`"
        :filled="inputValue"
        :password="type === 'password'"
        @focus="inputFocus"
        @blur="loseFocus"
        @input="$emit('input', $event.target.value)"
        @keyup="$emit('keyup', $event)"
      >

      <cf-span
        v-if="type === 'password'"
        clickable
        size="lg"
        color="grey"
        :class="[showPassIcon, $style.showPass]"
        @click.native="showPassword = !showPassword"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    width: {
      type: undefined,
      default: '-webkit-fill-available'
    },

    height: {
      type: undefined,
      default: '4rem'
    },

    type: {
      type: undefined,
      default: 'text'
    },

    mode: {
      type: String,
      default: 'default'
    },

    required: {
      type: Boolean,
      default: false
    },

    inputError: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: 'sm'
    }
  },
  data () {
    return {
      inputValue: this.value,
      focus: false,
      showPassword: false
    }
  },

  computed: {
    inputStyles () {
      const classes = [`cf-text__${this.size}`, 'cf-color__grey']
      if (this.mode === 'white') { classes.push(...[this.$style.inputLine, this.$style.white]) } else if (this.mode === 'line') classes.push(this.$style.inputLine)
      else if (this.mode === 'noBorder') classes.push(this.$style.noBorder)
      else classes.push(this.$style.inputDefault)
      return classes
    },

    labelColor () {
      if (focus) {
        if (this.mode === 'white') return 'white'
        else return 'black'
      } else return 'grey'
    },

    showPassIcon () {
      return this.showPassword ? 'icon-eye-off' : 'icon-eye-on'
    }
  },

  methods: {
    inputFocus () {
      this.focus = true
    },
    loseFocus () {
      this.$emit('loseFocus')
      this.focus = false
    }
  }
}
</script>

<style lang="scss" scoped>
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px getColor('grey-lighter') inset !important;
  -webkit-text-fill-color: getColor('black') !important;
}
</style>

<style lang="scss" module>
.item {
  width: auto;
  word-break: break-word;
}

.noBorder {
  border: none;
}

.labelDiv {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  column-gap: 0.2rem;
  grid-template-rows: 1.8rem;
  padding-bottom: 0.3rem;
}

.labelClass {
  transition: 0.3s ease-in-out;
  &:hover {
    color: getColor('black');
  }
}
.inputError {
  border: 0.1rem solid getColor('red') !important;
}
.inputDefault {
  border-radius: 4px;
  padding: 0 1.5rem;
  border: 0.1rem solid getColor('grey-lighter');
  -webkit-text-fill-color: getColor('grey');
  box-sizing: border-box;
  transition: 0.3s ease-in-out;

  &:hover {
    -webkit-text-fill-color: getColor('black');
    border: 0.1rem solid getColor('black');
  }
  &:focus {
    -webkit-text-fill-color: getColor('black');
    border: 0.1rem solid getColor('primary');
  }
  &[filled] {
    -webkit-text-fill-color: getColor('black');
  }
  &[password] {
    padding: 0 2rem 0 1.5rem;
  }

  @include device(mobile) {
    margin-top: 0.57rem;
  }
}

.inputLine {
  background: transparent;
  border: none;
  -webkit-text-fill-color: getColor('grey');
  border-bottom: 1px solid getColor('grey');
  box-sizing: border-box;
  height: 4rem;
  padding: 0.5rem;
  transition: all 0.3s ease-in-out;
  &:hover {
    -webkit-text-fill-color: getColor('black');
    border-bottom: 1px solid getColor('black');
  }
  &:focus {
    -webkit-text-fill-color: getColor('black');
    border-bottom: 1px solid getColor('primary');
  }
  &[filled] {
    -webkit-text-fill-color: getColor('black');
  }
  &[password] {
    padding: 0.5rem 2rem 0.5rem 0.5rem;
  }
}

.white {
  &:hover {
    -webkit-text-fill-color: getColor('white');
    border-bottom: 1px solid getColor('white');
  }
  &:focus {
    -webkit-text-fill-color: getColor('white');
    border-bottom: 1px solid getColor('primary');
  }
  &[filled] {
    -webkit-text-fill-color: getColor('white');
  }
}

.inputDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.showPass {
  position: absolute;
  right: 1rem;
  &:hover {
    color: getColor('black');
  }
}
::placeholder {
  color: getColor('grey');
  opacity: 0.5;
  font-style: italic;
  font-size: 1.2rem;
}
</style>
