const AdminView = () => import('./../views/AdminView.vue')
const AdminProjectsView = () => import('./../views/AdminProjectsView.vue')

const AdminCompaniesView = () => import('./../views/AdminCompaniesView.vue')
const CompanyView = () =>
  import('../../workspace/modules/company/views/CompanyView')
const CompanyProfileView = () =>
  import('../../workspace/modules/company/views/CompanyProfileView')
const CompanyMembersView = () =>
  import('../../workspace/modules/company/views/CompanyMembersView')

const CompanyProjectsView = () =>
  import('../../workspace/modules/company/views/CompanyProjectsView')
const ProjectView = () =>
  import('../../workspace/modules/project/views/ProjectView')
const ProjectSetupView = () =>
  import('../../workspace/modules/project/modules/setup/views/ProjectSetupView')
const ProjectProfileView = () =>
  import(
    '../../workspace/modules/project/modules/setup/views/ProjectProfileView'
  )
const ProjectLocalsView = () =>
  import(
    '../../workspace/modules/project/modules/setup/views/ProjectLocalsView'
  )
const ProjectLabelsView = () =>
  import(
    '../../workspace/modules/project/modules/setup/views/ProjectLabelsView'
  )
const ProjectCategoriesView = () =>
  import(
    '../../workspace/modules/project/modules/setup/views/ProjectCategoriesView'
  )
const ProjectDisciplinesStatusView = () =>
  import(
    '../../workspace/modules/project/modules/setup/views/ProjectDisciplinesStatusView'
  )
const ProjectPhasesView = () =>
  import(
    '../../workspace/modules/project/modules/setup/views/ProjectPhasesView'
  )
const ProjectDisciplinesView = () =>
  import(
    '../../workspace/modules/project/modules/setup/views/ProjectDisciplinesView'
  )
const ProjectCompaniesView = () =>
  import(
    '../../workspace/modules/project/modules/setup/views/ProjectCompaniesView'
  )
const ProjectIntegrationsView = () =>
  import(
    '../../workspace/modules/project/modules/setup/views/ProjectIntegrationsView'
  )
const ProjectImportSettingsView = () =>
  import(
    '../../workspace/modules/project/modules/setup/views/ProjectImportSettingsView'
  )
const ProjectExportSettingsView = () =>
  import(
    '../../workspace/modules/project/modules/setup/views/ProjectExportSettingsView'
  )
const ProjectDriveSetupView = () =>
  import(
    '../../workspace/modules/project/modules/setup/views/ProjectDriveSetupView'
  )

const AdminScriptsView = () => import('./../views/AdminScriptsView.vue')

export default [
  {
    path: '/admin',
    name: 'admin',
    component: AdminView,
    redirect: { name: 'adminCompanies' },
    meta: { needsAdmin: true },
    children: [
      {
        path: 'companies',
        name: 'adminCompanies',
        component: AdminCompaniesView
      },
      {
        path: 'projects',
        name: 'adminProjects',
        component: AdminProjectsView
      },
      {
        path: 'scripts',
        name: 'adminScripts',
        component: AdminScriptsView
      },
      {
        path: 'company/:companyId',
        name: 'adminCompany',
        redirect: { name: 'adminCompanyProfile' },
        component: CompanyView,
        children: [
          {
            path: 'members',
            name: 'adminCompanyMembers',
            component: CompanyMembersView
          },
          {
            path: 'profile',
            name: 'adminCompanyProfile',
            component: CompanyProfileView
          },
          {
            path: 'projects',
            name: 'adminCompanyProjects',
            component: CompanyProjectsView
          }
        ]
      },
      {
        path: 'project/:projectId',
        name: 'adminProject',
        component: ProjectView,
        redirect: { name: 'adminProjectSetup' },
        meta: { projectIcons: true },
        children: [
          {
            path: 'setup',
            name: 'adminProjectSetup',
            component: ProjectSetupView,
            redirect: { name: 'adminProjectProfile' },
            children: [
              {
                path: 'profile',
                name: 'adminProjectProfile',
                component: ProjectProfileView
              },
              {
                path: 'locals',
                name: 'adminProjectLocals',
                component: ProjectLocalsView
              },
              {
                path: 'labels',
                name: 'adminProjectLabels',
                component: ProjectLabelsView
              },
              {
                path: 'categories',
                name: 'adminProjectCategories',
                component: ProjectCategoriesView
              },
              {
                path: 'disciplinesStatus',
                name: 'adminProjectDisciplinesStatus',
                component: ProjectDisciplinesStatusView
              },
              {
                path: 'phases',
                name: 'adminProjectPhases',
                component: ProjectPhasesView
              },
              {
                path: 'disciplines',
                name: 'adminProjectDisciplines',
                component: ProjectDisciplinesView
              },
              {
                path: 'drive',
                name: 'adminProjectDriveSetup',
                component: ProjectDriveSetupView
              },
              {
                path: 'companies',
                name: 'adminProjectCompanies',
                component: ProjectCompaniesView
              },
              {
                path: 'integrations',
                name: 'adminProjectIntegrations',
                component: ProjectIntegrationsView
              },
              {
                path: 'import',
                name: 'adminProjectImportSettings',
                component: ProjectImportSettingsView
              },
              {
                path: 'export',
                name: 'adminProjectExportSettings',
                component: ProjectExportSettingsView
              }
            ]
          }
        ]
      }
    ]
  }
]
