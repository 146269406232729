<template>
  <div
    v-if="hidePopover"
    slot="reference"
  >
    <slot name="reference" />
  </div>
  <el-popover
    v-else
    :disabled="disabled"
    :placement="placement"
    :width="width"
    :trigger="trigger"
    :openDelay="openDelay"
    :value="visible"
  >
    <div class="cFlow-tooltip-break">
      <slot />
    </div>
    <div slot="reference">
      <slot name="reference" />
    </div>
  </el-popover>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    trigger: {
      type: String,
      default: 'hover'
    },
    placement: {
      type: String,
      default: 'top-start'
    },
    width: {
      type: undefined,
      default: 200
    },
    openDelay: {
      type: Number,
      default: 500
    },
    hidePopover: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style></style>

<style lang="scss" scoped>
.cFlow-tooltip-break {
  word-break: break-word;
  text-align: left;
}
</style>
