<template>
  <cf-tooltip placement="right">
    <cf-span
      multiline
      semibold
    >
      {{ title }}
    </cf-span>
    <template #reference>
      <div :class="$style.menuHeader">
        <cf-avatar
          :src="image"
          :type="imageType"
          imgStyle="max-height: 3rem; max-width: 3rem"
        />
        <cf-span
          size="lg"
          semibold
        >
          {{ title }}
        </cf-span>
      </div>
    </template>
  </cf-tooltip>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: null
    },
    imageType: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    }
  }
}
</script>
<style lang="scss" module>
.menuHeader {
  display: grid;
  grid-template: 4rem / 5rem 1fr;
  align-content: center;
  align-items: center;
  padding: 0 0.5rem;
}
</style>
