import Vue from 'vue'
import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: {
    mobile: 767,
    tablet: 959,
    hd: 1366,
    fullhd: Infinity
  }
})
