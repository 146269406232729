<template>
  <div
    id="app"
    v-loading="routerLoading"
    :class="$style.app"
  >
    <router-view />
    <div :class="$style.supportButtonContainer">
      <cf-tooltip
        v-if="showSupportIcon"
        width="auto"
        :class="$style.supportTooltip"
      >
        <SupportMenu />
        <template #reference>
          <cf-icon
            hoverable
            clickable
            icon="suporte"
          />
        </template>
      </cf-tooltip>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { setChatUser } from '@/plugins/zendesk'
import { setUserGuidingUser } from '@/plugins/userGuiding'
import bimPluginConnectionMixin from './modules/bim_plugins/mixins/bim-plugin-connection'
import erroHandler from './mixins/error-handler'
import loggedUserMixin from './mixins/logged-user'
import appData from '../app.json'
import Auth from '@/plugins/auth'
export default {
  components: {
    SupportMenu: () =>
      import('./modules/workspace/components/Navbar/SupportMenu.vue')
  },
  mixins: [erroHandler, bimPluginConnectionMixin, loggedUserMixin],
  computed: {
    ...mapState(['routerLoading']),
    ...mapState(['loggedUser']),
    showSupportIcon () {
      if (this.$route.query.clientId === '2qvshuend9668etklokeccg7lj') { return false }
      return this.$route.matched.some((route) => route.meta.showSupportIcon)
    }
  },
  watch: {
    loggedUser (newValue) {
      this.processLoggedUserData(newValue)
    }
  },

  async created () {
    console.info(`${appData.name}:${appData.version}`)
    await Auth.initialize()
    const wsSessionId = sessionStorage.getItem('wsSessionId')
    if (wsSessionId) {
      console.info('wsSessionId:', wsSessionId)
      this.processLoggedUserData(this.loggedUser)
    }
  },

  errorCaptured (errors, info) {
    this.errorCapturedMethod(errors, info)
  },
  methods: {
    processLoggedUserData (loggedUserData) {
      setChatUser(loggedUserData)
      setUserGuidingUser(loggedUserData)
      this.bimPluginConnection_handleNewLoggedUser(loggedUserData)
      // this.$apm.setUserContext({
      //   id: loggedUserData.id,
      //   email: loggedUserData.email
      // })
    }
  }
}
</script>
<style lang="scss">
@include standards;
</style>
<style lang="scss" module>
.app {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  display: grid;
  overflow: hidden;
  grid-template: 1fr min-content / 1fr;
}
.supportButtonContainer {
  position: relative;
}

.supportTooltip {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
</style>
