<template>
  <div
    :class="$style.option"
    :disabled="disabled"
    @click="toggle"
  >
    <cf-span
      multiline
      :size="size"
      :color="color"
    >
      <cf-span
        multiline
        style="padding-right: 0.5rem"
        :size="size"
        :color="color"
        :class="value ? 'icon-option-yes': 'icon-option-no'"
      />
      {{ text }}
    </cf-span>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    }
  },
  computed: {
    color () {
      if (this.disabled) return 'grey'
      else if (this.value) return 'primary'
      else return 'black'
    }
  },
  methods: {
    toggle () {
      if (!this.disabled) this.$emit('input', !this.value)
    }
  }
}
</script>

<style lang="scss" module>
.option {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
  }
}
</style>
