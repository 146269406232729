<template>
  <div
    v-if="!isMobile"
    :class="$style.backgroundModal"
    :hidden="!visible"
  >
    <div
      v-if="visible"
      :class="$style.modal"
      :style="modalStyle"
    >
      <slot />
    </div>
  </div>
  <el-drawer
    v-else
    :size="size"
    :direction="direction"
    :withHeader="false"
    :visible="visible"
    @close="$emit('changeVisible', false)"
  >
    <div :class="$style.drawerDiv">
      <slot />
    </div>
  </el-drawer>
</template>

<script>
export default {
  model: {
    prop: 'visible',
    event: 'changeVisible'
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },

    modalStyle: {
      type: String,
      default: ''
    },

    direction: {
      type: String,
      default: 'btt'
    },

    size: {
      type: undefined,
      default: 'auto'
    }
  }
}
</script>

<style lang="scss" module>
.backgroundModal {
  z-index: 1999;
  display: grid;
  align-content: center;
  position: fixed;
  left: 0;
  top: 0;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  transition: all 0.2s ease-in-out;
  &[hidden] {
    opacity: 0;
    visibility: hidden;
  }
}

.modal {
  z-index: 2000;
  max-height: calc(var(--vh, 1vh) * 90);
  max-width: 70vw;
  display: grid;
  overflow: hidden;
  background-color: getColor('white');
  border-radius: 0.5rem;
  border: 0.2rem solid getColor('grey-lighter');
  box-shadow: 0 0.3rem 1rem getColor('black');
  grid-template: 1fr / 1fr;
}

.drawerDiv {
  display: grid;
  max-height: calc(var(--vh, 1vh) * 90);
  max-width: 100vw;
  overflow: hidden;
  grid-template: 1fr / 1fr;
}
</style>
<style scoped>
::v-deep .el-drawer__body {
  overflow: hidden;
}
</style>
