<template>
  <cf-tooltip
    width="min-content"
    :hidePopover="!content"
  >
    <div
      slot="reference"
      :class="classes"
    >
      <cf-span
        size="2xs"
        color
        style="line-height: unset"
      >
        {{ tag }}
      </cf-span>
      <a
        v-if="closable"
        :class="['icon-delete', $style.delete]"
        @click="$emit('closeTag')"
      />
    </div>
    {{ content }}
  </cf-tooltip>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default'
    },
    closable: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      required: true
    },
    content: {
      type: String,
      default: ''
    }
  },
  computed: {
    classes: function () {
      const classes = []
      classes.push(this.$style.tag)
      if (this.type === 'default') classes.push(this.$style.primary)
      if (this.type === 'local') classes.push(this.$style['primary-bordered'])
      if (this.type === 'label') classes.push(this.$style.primary)
      if (this.type === 'disciplineVaries') { classes.push(this.$style['grey-bordered']) }
      if (this.type === 'disciplineParticipate') { classes.push(this.$style['grey-bordered']) }
      if (this.type === 'disciplineFollow') { classes.push(this.$style['grey-bordered']) }
      if (this.type === 'disciplineActive') classes.push(this.$style.yellow)
      if (this.type === 'disciplineLate') classes.push(this.$style.red)
      if (this.type === 'disciplineAlmostDone') { classes.push(this.$style.black) }
      if (this.type === 'disciplineDone') classes.push(this.$style.green)
      if (this.type === 'personal') classes.push(this.$style.grey)
      if (this.type === 'emailTag') classes.push(this.$style.emailTag)
      if (this.type === 'undefined') classes.push(this.$style.undefined)
      return classes
    }
  }
}
</script>

<style lang="scss" module>
.delete {
  cursor: pointer;
  padding: 0.1rem;
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5rem;
  min-width: 2.6rem;
  max-width: 4rem;
  height: 1.1rem;
  margin: 0.1rem;
  padding: 0 0.5rem;
}
.primary {
  background-color: getColor('primary');
  border: 0.1rem solid getColor('primary');
  color: getColor('white');
}
.primary-bordered {
  background-color: transparent;
  border: 0.1rem solid getColor('primary');
  color: getColor('primary');
}

.emailTag {
  display: flex;
  border-radius: 0.5rem;
  border: #0085ff solid 0.1rem;
  max-width: max-content;
  padding: 0.3rem;
  margin: 0.1rem;
  color: #0085ff;
}
.undefined {
  background-color: transparent;
  border: 0.1rem solid getColor('grey');
  color: getColor('grey');
}
.black {
  background-color: getColor('black');
  border: 0.1rem solid getColor('black');
  color: getColor('white');
}
.black-bordered {
  background-color: transparent;
  border: 0.1rem solid getColor('black');
  color: getColor('black');
}
.yellow {
  background-color: getColor('yellow');
  border: 0.1rem solid getColor('yellow');
  color: getColor('white');
}
.yellow-bordered {
  background-color: transparent;
  border: 0.1rem solid getColor('yellow');
  color: getColor('yellow');
}
.grey {
  background-color: getColor('grey');
  border: 0.1rem solid getColor('grey');
  color: getColor('white');
}
.grey-bordered {
  background-color: transparent;
  border: 0.1rem solid getColor('grey');
  color: getColor('grey');
}
.red {
  background-color: getColor('red');
  border: 0.1rem solid getColor('red');
  color: getColor('white');
}
.red-bordered {
  background-color: transparent;
  border: 0.1rem solid getColor('red');
  color: getColor('red');
}
.green {
  background-color: getColor('green');
  border: 0.1rem solid getColor('green');
  color: getColor('white');
}
.green-bordered {
  background-color: transparent;
  border: 0.1rem solid getColor('green');
  color: getColor('green');
}
</style>
