<template>
  <div style="overflow: hidden">
    <cf-tooltip
      v-if="user"
      :hidePopover="hidePopover"
    >
      <cf-profile-user-details :user="user" />
      <slot />
      <div slot="reference">
        <cf-profile
          :name="[user.name, user.lastName].join(' ')"
          :src="user.photo"
          :color="color"
          :italic="italic"
          :semibold="semibold"
          :size="size"
          :hideName="hideName"
          :imgStyle="imgStyle ? imgStyle : undefined"
          type="user"
        />
      </div>
    </cf-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: null,
      default: null
    },
    color: {
      type: String,
      default: 'black'
    },
    size: {
      type: String,
      default: 'sm'
    },
    italic: Boolean,
    semibold: Boolean,
    hideName: {
      type: Boolean,
      default: false
    },
    imgStyle: {
      type: String,
      default: ''
    },
    hidePopover: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module></style>
