import apollo from '@/plugins/apollo'
import projectDataQuery from './graphql/queries/projectData.gql'
import updateProjectMutation from './graphql/mutations/updateProject.gql'
import setProjectLocalsMutation from './graphql/mutations/setProjectLocals.gql'
import copyProjectSettingsMutation from './graphql/mutations/copyProjectSettings.gql'
import setProjectLabelsMutation from './graphql/mutations/setProjectLabels.gql'
import setProjectCategoriesMutation from './graphql/mutations/setProjectCategories.gql'
import setProjectDisciplinesStatusMutation from './graphql/mutations/setProjectDisciplinesStatus.gql'
import setProjectPhasesMutation from './graphql/mutations/setProjectPhases.gql'
import standardDisciplinesQuery from './graphql/queries/standardDisciplines.gql'
import setProjectDisciplinesMutation from './graphql/mutations/setProjectDisciplines.gql'
import setProjectDocumentsNamingRuleMutation from './graphql/mutations/setProjecDocumentsNamingRule.gql'

async function getProjectData (projectId) {
  const variables = { projectId }
  const response = await apollo.query({
    query: projectDataQuery,
    fetchPolicy: 'network-only',
    variables
  })

  response.data.project.disciplines.sort((a, b) =>
    a.abbreviation.toLowerCase().localeCompare(b.abbreviation.toLowerCase())
  )
  return response.data
}

async function updateProjectData (project) {
  const variables = project
  const response = await apollo.mutate({
    mutation: updateProjectMutation,
    variables
  })
  return response.data.updateProject
}

const setProjectLocals = async (projectId, locals) => {
  const variables = { projectId, locals }
  const response = await apollo.mutate({
    mutation: setProjectLocalsMutation,
    variables
  })
  return response.data.setProjectLocals
}

const setProjectLabels = async (projectId, labels) => {
  const variables = { projectId, labels }
  const response = await apollo.mutate({
    mutation: setProjectLabelsMutation,
    variables
  })
  return response.data.setProjectLabels
}

const setProjectCategories = async (projectId, categoriesIds) => {
  const variables = { projectId, categoriesIds }
  const response = await apollo.mutate({
    mutation: setProjectCategoriesMutation,
    variables
  })
  return response.data.setProjectCategories
}

const setProjectDisciplinesStatus = async (projectId, disciplinesStatus) => {
  const variables = { projectId, disciplinesStatus }
  const response = await apollo.mutate({
    mutation: setProjectDisciplinesStatusMutation,
    variables
  })
  return response.data.setProjectDisciplinesStatus
}

const setProjectPhases = async (projectId, phasesIds) => {
  const variables = { projectId, phasesIds }
  const response = await apollo.mutate({
    mutation: setProjectPhasesMutation,
    variables
  })
  return response.data.setProjectPhases
}

async function getStandardDisciplines () {
  const response = await apollo.query({
    query: standardDisciplinesQuery,
    fetchPolicy: 'network-only'
  })
  return response.data.standardDisciplines
}

async function setProjectDisciplines (projectId, disciplines) {
  const variables = { projectId, disciplines }
  const response = await apollo.mutate({
    mutation: setProjectDisciplinesMutation,
    variables
  })
  return response.data.setProjectDisciplines
}

async function setProjectDocumentsNamingRule (
  projectId,
  documentsNamingRule,
  documentsNamingRuleSeparator
) {
  const variables = {
    projectId,
    documentsNamingRule,
    documentsNamingRuleSeparator
  }
  const response = await apollo.mutate({
    mutation: setProjectDocumentsNamingRuleMutation,
    variables
  })
  return response.data.setProjectDocumentsNamingRule
}

const copyProjectSettings = async (
  sourceProjectId,
  targetProjectsIds,
  settings
) => {
  const variables = { sourceProjectId, targetProjectsIds, settings }

  const res = await apollo.mutate({
    mutation: copyProjectSettingsMutation,
    variables
  })
  return res
}

export {
  getProjectData,
  updateProjectData,
  setProjectLocals,
  setProjectLabels,
  setProjectCategories,
  setProjectDisciplinesStatus,
  setProjectPhases,
  getStandardDisciplines,
  setProjectDisciplines,
  copyProjectSettings,
  setProjectDocumentsNamingRule
}
