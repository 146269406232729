<template>
  <div :class="$style.item">
    <div :class="$style.labelDiv">
      <cf-span
        v-if="label"
        size="sm"
        color="grey"
        :class="$style.label"
      >
        {{ label }}
      </cf-span>
      <cf-span
        v-show="required"
        size="lg"
        color="red"
      >
        *
      </cf-span>
    </div>
    <el-input-number
      :value="value"
      :min="min"
      :max="max"
      :disabled="disabled"
      :size="size"
      :precision="precision"
      :step="step"
      style="z-index: 0"
      :controls-position="controlPosition"
      @change="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: null
    },
    controlPosition: {
      type: String,
      default: null
    },
    min: {
      type: Number,
      default: -Infinity
    },
    max: {
      type: Number,
      default: Infinity
    },
    precision: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputValue: this.value ? this.value : 1
    }
  }
}
</script>

<style lang="scss" module>
.item {
  width: 100%;
  word-break: break-word;
}

.labelDiv {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  column-gap: 0.2rem;
}

.label {
  transition: 0.3s;
  margin-bottom: 0.7rem;
  &:hover {
    color: getColor('black');
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-input__inner {
  border: 0.1rem solid getColor('grey-lighter') !important;
  color: getColor('black') !important;
  transition: 0.3s;
  &:hover {
    border: 0.1rem solid getColor('black') !important;
  }
  &:focus {
    border: 0.1rem solid getColor('primary') !important;
  }
}

::v-deep .el-input-number--mini {
  width: 13rem;
}
</style>
