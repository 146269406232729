export default {
  statuses: [
    'analysing',
    'approved-with-notes',
    'approved',
    'reproved',
    'obsolete'
  ],

  visibilities: ['management', 'public'],

  userDocumentsSettings: null
}
