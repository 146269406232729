<template>
  <cf-tooltip>
    <template #reference>
      <div :class="$style.flexDiv">
        <cf-span
          :size="size"
          :color="computedVisibility.color"
          :class="[computedVisibility.icon]"
        />
        <cf-span
          v-if="showLabel"
          :size="labelSizes[size]"
          :color="computedVisibility.color"
        >
          &nbsp;{{ $t('visibilityOptions.' + visibility) }}
        </cf-span>
      </div>
    </template>
    <cf-span multiline>
      {{
        $t('visibilityOptions.' + visibility) +
          (visibility === 'group' && group ? ` (${group})` : '')
      }}
    </cf-span>
    <cf-span
      v-if="visibility === 'semi_public'"
      size="xs"
      multiline
    >
      <br>{{ $t('visibilityOptions.semiPublicMessage') }}
    </cf-span>
  </cf-tooltip>
</template>

<script>
export default {
  props: {
    visibility: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'sm'
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    group: {
      type: String,
      default: undefined,
      required: false
    }
  },
  data () {
    return {
      labelSizes: {
        '2xs': '2xs',
        xs: '2xs',
        sm: 'xs',
        md: 'sm',
        lg: 'sm',
        xl: 'lg',
        '2xl': 'xl'
      }
    }
  },

  computed: {
    computedVisibility () {
      switch (this.visibility) {
        case 'group':
          return { icon: 'icon-team', color: 'yellow' }

        case 'management':
          return { icon: 'icon-management', color: 'yellow' }

        case 'project_management':
          return { icon: 'icon-management', color: 'yellow' }

        case 'semi_public':
          return { icon: 'icon-public', color: 'yellow' }

        case 'public':
          return { icon: 'icon-public', color: 'primary' }

        default:
          return { icon: 'icon-user', color: 'grey' }
      }
    }
  }
}
</script>

<style lang="scss" module>
.flexDiv {
  display: flex;
  flex-direction: row;
}
</style>
