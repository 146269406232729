<template>
  <div :class="$style.container">
    <cf-avatar
      :src="src"
      :border="border"
      :editable="editable"
      :circle="circle"
      :type="type"
      :imgStyle="imgStyle"
    />
    <cf-span
      v-if="!hideName"
      :size="size"
      :color="color"
      :italic="italic"
      :semibold="semibold"
      :multiline="false"
      :uppercase="uppercase"
      :lowercase="lowercase"
      :disabled="disabled"
      :clickable="clickable"
      style="margin-left: 0.5rem"
    >
      {{ name }}
    </cf-span>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },

    // AVATAR
    src: {
      type: undefined,
      required: true
    },
    border: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      required: true
    },
    imgStyle: {
      type: String,
      default: 'height: 2rem; width:2rem'
    },

    // SPAN
    size: {
      type: String,
      default: 'sm'
    },
    color: {
      type: String,
      default: 'black'
    },
    italic: Boolean,
    semibold: Boolean,
    multiline: Boolean,
    uppercase: Boolean,
    lowercase: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    },
    hideName: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
}
</style>
