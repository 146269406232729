function userGuidingInit (g, u, i, d, e, s) {
  g[e] = g[e] || []
  const f = u.getElementsByTagName(i)[0]
  const k = u.createElement(i)
  k.async = true
  k.src =
    'https://static.userguiding.com/media/user-guiding-' + s + '-embedded.js'
  f.parentNode.insertBefore(k, f)
  if (g[d]) return
  const ug = (g[d] = { q: [] })
  ug.c = function (n) {
    return function () {
      ug.q.push([n, arguments])
    }
  }
  const m = [
    'previewGuide',
    'finishPreview',
    'track',
    'identify',
    'triggerNps',
    'hideChecklist',
    'launchChecklist'
  ]
  for (let j = 0; j < m.length; j += 1) {
    ug[m[j]] = ug.c(m[j])
  }
}

userGuidingInit(
  window,
  document,
  'script',
  'userGuiding',
  'userGuidingLayer',
  '393788555ID'
)

function setUserGuidingUser (user) {
  if (user) {
    window.userGuiding.identify(user.id, {
      name: user.name,
      lastName: user.lastName,
      email: user.email,
      companyId: user.company ? user.company.id : 'no_company',
      companyName: user.company ? user.company.name : 'no_company',
      isDemo: user.isDemo
    })
  } else {
    window.userGuiding.identify('anonymous', {})
  }
}

export { setUserGuidingUser }
