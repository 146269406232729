const ProjectSetupView = () => import('../views/ProjectSetupView')
const ProjectDisciplines = () => import('../views/ProjectDisciplinesView')
const ProjectProfileView = () => import('../views/ProjectProfileView')
const ProjectLocalsView = () => import('../views/ProjectLocalsView')
const ProjectLabelsView = () => import('../views/ProjectLabelsView')
const ProjectCategoriesView = () => import('../views/ProjectCategoriesView')
const ProjectDisciplinesStatusView = () =>
  import('../views/ProjectDisciplinesStatusView')
const ProjectPhasesView = () => import('../views/ProjectPhasesView')
const ProjectCompaniesView = () => import('../views/ProjectCompaniesView')
const ProjectIntegrationsView = () => import('../views/ProjectIntegrationsView')
const ProjectImportSettingsView = () =>
  import('../views/ProjectImportSettingsView')
const ProjectExportSettingsView = () =>
  import('../views/ProjectExportSettingsView')
const ProjectDriveSetupView = () => import('../views/ProjectDriveSetupView')

export default [
  {
    path: 'setup',
    name: 'projectSetup',
    component: ProjectSetupView,
    redirect: { name: 'projectProfile' },
    children: [
      {
        path: 'profile',
        name: 'projectProfile',
        component: ProjectProfileView
      },
      {
        path: 'locals',
        name: 'projectLocals',
        component: ProjectLocalsView
      },
      {
        path: 'labels',
        name: 'projectLabels',
        component: ProjectLabelsView
      },
      {
        path: 'categories',
        name: 'projectCategories',
        component: ProjectCategoriesView
      },
      {
        path: 'disciplinesStatus',
        name: 'projectDisciplinesStatus',
        component: ProjectDisciplinesStatusView
      },
      {
        path: 'phases',
        name: 'projectPhases',
        component: ProjectPhasesView
      },
      {
        path: 'disciplines',
        name: 'projectDisciplines',
        component: ProjectDisciplines
      },
      {
        path: 'drive',
        name: 'projectDriveSetup',
        component: ProjectDriveSetupView
      },
      {
        path: 'companies',
        name: 'projectCompanies',
        component: ProjectCompaniesView
      },
      {
        path: 'integrations',
        name: 'projectIntegrations',
        component: ProjectIntegrationsView
      },
      {
        path: 'importSettings',
        name: 'projectImportSettings',
        component: ProjectImportSettingsView
      },
      {
        path: 'exportSettings',
        name: 'projectExportSettings',
        component: ProjectExportSettingsView
      }
    ]
  }
]
