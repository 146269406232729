<template>
  <div :class="$style.content">
    <cf-avatar
      :src="user.photo"
      imgStyle="max-height: 6rem"
      style="grid-area: avatar"
      type="user"
    />
    <cf-span
      semibold
      style="grid-area: name"
      :color="color"
    >
      {{ [user.name, user.lastName].filter(Boolean).join(' ') }}
    </cf-span>
    <cf-profile
      v-if="user.company"
      style="grid-area: company"
      :name="user.company.name"
      :src="user.company.logo"
      :color="color"
      type="company"
    />
    <div
      v-if="user.email"
      style="grid-area: email"
      :class="$style.emailContainer"
    >
      <cf-span :color="color">
        {{ user.email }}
      </cf-span>
      <cf-span
        :class="['icon-copy', $style.copyButton]"
        clickable
        multiline
        @click.prevent.stop="copyEmailToClipboard"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: null,
      default: null
    },
    color: {
      type: String,
      default: 'black'
    }
  },
  methods: {
    async copyEmailToClipboard () {
      await navigator.clipboard.writeText(this.user.email)
    }
  }
}
</script>

<style lang="scss" module>
.content {
  display: grid;
  column-gap: 1rem;
  grid-template: min-content min-content min-content / min-content 1fr;
  grid-template-areas:
    'avatar name'
    'avatar email'
    'avatar company';
  overflow: hidden;
}

.emailContainer {
  display: flex;
  gap: 1rem;
  overflow: hidden;

  & .copyButton {
    display: none;
  }

  &:hover {
    & .copyButton {
      display: block;
    }
  }
}
</style>
