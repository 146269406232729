import axios from '@/plugins/axios'

export default {
  async getUploadSignedUrl (parameters) {
    const { projectId, ...payload } = parameters
    try {
      const { data } = await axios.post(
        `/cde-server/document-management/projects/${projectId}/upload-file`,
        payload
      )
      return data
    } catch (e) {
      throw new Error(e.response.data.message)
    }
  },

  async confirmUpload (parameters) {
    const { projectId, ...payload } = parameters
    try {
      const { data } = await axios.post(
        `/cde-server/document-management/projects/${projectId}/confirm-upload`,
        payload
      )
      return data
    } catch (e) {
      throw new Error(e.response.data.message)
    }
  },

  async getUserDocumentsSettings ({ projectId }) {
    try {
      const { data } = await axios.get(
        `/cde-server/document-management/projects/${projectId}/user-permissions`
      )
      return data
    } catch (e) {
      throw new Error(e.response.data.message)
    }
  },

  async getDocuments (parameters, config) {
    try {
      const { signal } = config
      const { projectId, ...params } = parameters
      const { data } = await axios.get(
        `/cde-server/document-management/projects/${projectId}/documents`,
        {
          params,
          signal
        }
      )
      return data
    } catch (e) {
      throw new Error(e.response.data.message)
    }
  },

  async getDownloadSignedUrl (parameters) {
    const { projectId, ...payload } = parameters

    try {
      const { data } = await axios.post(
        `/cde-server/document-management/projects/${projectId}/download-file`,
        payload
      )
      return data
    } catch (e) {
      throw new Error(e.response.data.message)
    }
  },

  async updateDocument (parameters) {
    const { projectId, documentId, ...payload } = parameters

    try {
      const { data } = await axios.patch(
        `/cde-server/document-management/projects/${projectId}/documents/${documentId}`,
        payload
      )
      return data
    } catch (e) {
      throw new Error(e.response.data.message)
    }
  },
  async getDocument ({ projectId, documentId }) {
    try {
      const { data } = await axios.get(
        `/cde-server/document-management/projects/${projectId}/documents/${documentId}/show-document`
      )
      return data
    } catch (e) {
      throw new Error(e.response.data.message)
    }

    // await new Promise((resolve) => setTimeout(resolve, 500))
    // return {
    //   id: documentId,
    //   name: '2532-ARQ-TE-PB',
    //   revisions: [
    //     {
    //       id: 'R03',
    //       name: 'R03',
    //       images: [
    //         { fileName: '2532-ARQ-TE-PB-R03_page0001.jpg' },
    //         { fileName: '2532-ARQ-TE-PB-R03_page0002.jpg' },
    //         { fileName: '2532-ARQ-TE-PB-R03_page0003.jpg' }
    //       ]
    //     },
    //     {
    //       id: 'R02',
    //       name: 'R02',
    //       images: [
    //         { fileName: '2532-ARQ-TE-PB-R02_page0001.jpg' },
    //         { fileName: '2532-ARQ-TE-PB-R02_page0002.jpg' },
    //         { fileName: '2532-ARQ-TE-PB-R02_page0003.jpg' }
    //       ]
    //     },
    //     {
    //       id: 'R01',
    //       name: 'R01',
    //       images: [
    //         { fileName: '2532-ARQ-TE-PB-R01_page0001.jpg' },
    //         { fileName: '2532-ARQ-TE-PB-R01_page0002.jpg' },
    //         { fileName: '2532-ARQ-TE-PB-R01_page0003.jpg' }
    //       ]
    //     }
    //   ]
    // }
  },

  async getDocumentPageUrl ({ projectId, documentId, revisionId, page }) {
    try {
      const { data } = await axios.get(
        `/cde-server/document-management/projects/${projectId}/documents/${documentId}/revisions/${revisionId}/${page}/get-revision-image-signed-url`
      )
      return data
    } catch (e) {
      throw new Error(e.response.data.message)
    }
    // await new Promise((resolve) => setTimeout(resolve, 500))
    // return 'https://construflow-assets.s3.sa-east-1.amazonaws.com/pdf_samples/COL-GPJ181-EX-004-PBTERREO-R10_page0001.jpg'
  }
}
