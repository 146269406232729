<template>
  <div style="display: grid; width: 100%">
    <div
      v-if="label"
      :class="$style.labelDiv"
    >
      <cf-span
        size="sm"
        :class="$style.labelClass"
        :color="inputError ? 'red' : 'grey'"
      >
        {{ label }}
      </cf-span>
      <cf-span
        v-show="required"
        size="lg"
        :color="inputError ? 'red' : 'grey'"
      >
        *
      </cf-span>
      <a
        v-if="!!infoUrl"
        :href="infoUrl"
        target="_blank"
      >
        <cf-span
          class="icon-manual"
          color="grey"
        />
      </a>
    </div>
    <el-select
      v-model="option"
      :placeholder="placeHolder"
      :disabled="disabled"
      :multiple="multiple"
      :collapse-tags="collapseTags"
      :no-data-text="noDataText"
      :no-match-text="noMatchText"
      :size="size"
      :valueKey="valueKey"
      :style="'width: ' + width"
      :filterable="filterable"
      :loading="loading"
      :clearable="clearable"
      :inputError="inputError"
      :class="noBorder ? 'noBorder' : null"
      :remote="remote"
      :remote-method="remoteMethod"
      @change="$emit('change', $event)"
    >
      <slot />
    </el-select>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'defaultOption',
    event: 'option'
  },
  props: {
    width: {
      type: String,
      default: null
    },
    placeHolder: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    collapseTags: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'small'
    },
    defaultOption: {
      type: undefined,
      default: null
    },
    valueKey: {
      type: String,
      default: null
    },
    filterable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inputError: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    remote: {
      type: Boolean,
      default: false
    },
    remoteMethod: {
      type: null,
      default: undefined
    },
    noDataText: {
      type: null,
      default: undefined
    },
    noMatchText: {
      type: null,
      default: undefined
    },
    infoUrl: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      option: null
    }
  },
  watch: {
    option: function () {
      this.$emit('option', this.option)
    },
    defaultOption: function (newValue) {
      this.option = newValue
    }
  },

  created () {
    this.option = this.defaultOption
  }
}
</script>

<style lang="scss" module>
.labelDiv {
  display: grid;
  grid-template-columns: repeat(3, min-content);
  column-gap: 0.2rem;
  grid-template-rows: 1.8rem;
}
.labelClass {
  transition: 0.3s ease-in-out;
  &:hover {
    color: getColor('black');
  }
}
</style>
<style scoped>
::v-deep .el-input--small {
  font-size: 1.2rem;
}

::v-deep .noBorder .el-input__inner {
  border: none;
  padding: 0 !important;
}

::v-deep .noBorder .el-input__suffix {
  display: none;
}

::v-deep .el-input.is-disabled {
  background-color: transparent;
}
</style>
