const AcceptPolicies = () => import('./../views/AcceptPolicies')
const AuthView = () => import('../views/AuthView')
const SignOutView = () => import('../views/SignOutView')
const ProcessFederatedSigin = () => import('../views/ProcessFederatedSigin.vue')

export default [
  {
    path: '/policies',
    name: 'acceptPolicies',
    meta: { needsUserData: true, showSupportIcon: true },
    component: AcceptPolicies
  },
  {
    path: '/login',
    name: 'Authenticate',
    meta: { showSupportIcon: true },
    component: AuthView
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { showSupportIcon: true },
    component: SignOutView
  },
  {
    path: '/process-federated-sign-in',
    name: 'ProcessFederatedSignIn',
    meta: { showSupportIcon: true },
    component: ProcessFederatedSigin
  }
]
