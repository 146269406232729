import { ApolloClient, InMemoryCache } from 'apollo-boost'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import Auth from '@/plugins/auth'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { split } from 'apollo-link'
import { onError } from 'apollo-link-error'
/* Configurações do Apollo Client */

const linkError = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 503) {
    window.location.href = '/maintenance'
  }
})

const httpLink = createUploadLink({
  uri: `${process.env.VUE_APP_API_URL}/graphql`,
  credentials: 'include'
})

const authLink = setContext(async (_, { headers = {} }) => {
  let session
  try {
    session = await Auth.currentSession()
  } catch (err) {}
  if (session) {
    Object.assign(headers, {
      authorization: `Bearer ${session.accessToken.jwtToken}`
    })
  }

  return {
    headers
  }
})
const wsUri = `${process.env.VUE_APP_API_URL}/graphql`
  .replace('https://', 'wss://')
  .replace('http://', 'ws://')
const wsLink = new WebSocketLink({
  uri: wsUri,
  options: {
    reconnect: true,
    lazy: true
  }
})

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  authLink.concat(linkError.concat(httpLink))
)

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache({
    addTypename: false
  }),
  connectToDevTools: process.env.APOLLO_DEV_TOOL === 'true'
})

export default apolloClient
