<template>
  <el-collapse>
    <slot />
  </el-collapse>
</template>

<script>
export default {}
</script>

<style></style>
