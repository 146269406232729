import cdeService from '../services/cde-service'

export default {
  async fetchUserDocumentsSettings (context, { projectId }) {
    context.commit('setUserDocumentsSettings', null)

    const response = await cdeService.getUserDocumentsSettings({ projectId })

    context.commit('setUserDocumentsSettings', response)
  }
}
