<template>
  <div :class="$style.layout">
    <ImageEditor
      v-if="imageEditorValue"
      :value="imageEditorValue"
    />
  </div>
</template>

<script>
import ImageEditor from '@/components/images-handler/image-editor.vue'
export default {
  components: {
    ImageEditor
  },
  data () {
    return {
      loading: false,
      imageEditorValue: null
    }
  },
  created () {
    const documentsQueryString = this.$route.query.documents
    if (documentsQueryString) {
      const documents = JSON.parse(atob(documentsQueryString))
      this.imageEditorValue = { images: documents }
    }
  }
}
</script>
<style lang="scss" module>
.layout {
  display: grid;
  grid-template: 1fr / 1fr;
}
</style>
