<template>
  <div style="width: fit-content">
    <cf-tooltip
      width="23.5rem"
      trigger="click"
      :placement="placement"
    >
      <div style="max-height: 30rem; overflow-y: scroll; padding: 0.5rem">
        <cf-span
          semibold
          size="lg"
          color="black"
        >
          {{ tooltipTitle }}
        </cf-span>
        <div :class="$style.selectorDiv">
          <cf-span
            style="margin-right: 0.5rem"
            :class="$style.selector"
            @click.native="selectAll()"
          >
            {{ $t('all') }}
          </cf-span>
          <cf-span
            :class="$style.selector"
            @click.native="clearSelection()"
          >
            {{ $t('none') }}
          </cf-span>
        </div>
        <div v-if="showSearchInput">
          <el-input
            v-model="search"
            suffixIcon="el-icon-search"
            size="mini"
          />
        </div>
        <div
          v-if="showOptionType"
          style="display: flex; margin-bottom: 1rem; align-items: center"
        >
          <cf-span>
            {{ $t('issuesModule.selectionType') }}
          </cf-span>
          <div
            style="display: flex; margin-left: 0.8rem; align-items: center"
            @click="$emit('changeFilterType', 'and')"
          >
            <cf-span
              :color="optionType === 'and' ? 'primary' : 'black'"
              :class="[$style.filterOptionText, filterOptionTypeClass('and')]"
            />
            <cf-span :color="optionType === 'and' ? 'primary' : 'black'">
              {{ $t('and') }}
            </cf-span>
          </div>
          <div
            style="display: flex; margin-left: 0.8rem; align-items: center"
            @click="$emit('changeFilterType', 'or')"
          >
            <cf-span
              :color="optionType === 'or' ? 'primary' : 'black'"
              :class="[$style.filterOptionText, filterOptionTypeClass('or')]"
            />
            <cf-span :color="optionType === 'or' ? 'primary' : 'black'">
              {{ $t('or') }}
            </cf-span>
          </div>
        </div>
        <div
          v-if="dontContains !== undefined"
          style="display: flex; margin-bottom: 1rem; align-items: center"
        >
          <div
            style="display: flex; align-items: center"
            @click="$emit('toggleDontContains')"
          >
            <cf-span
              :color="dontContains ? 'primary' : 'black'"
              :class="[$style.filterOptionText, dontContains ? 'icon-option-yes' : 'icon-option-no']"
            />
            <cf-span :color="dontContains ? 'primary' : 'black'">
              {{ $t('dontContains') }}
            </cf-span>
          </div>
        </div>
        <div
          v-for="item in dataToShow"
          :key="item.key"
          :class="$style.itemIntern"
          @click="emit(item.key, item.item)"
        >
          <cf-span
            clickable
            size="sm"
            :color="isActive(item.key) ? 'primary' : 'black'"
          >
            {{ item.label }}
          </cf-span>
          <div
            v-if="isActive(item.key)"
            style="float: right; margin-right: 0.5rem"
          >
            <cf-span class="icon-yes" />
          </div>
        </div>
      </div>

      <template #reference>
        <div :class="$style.referenceTitleDiv">
          <cf-span :color="selectedColor">
            {{ reference }}
          </cf-span>
          <cf-span
            v-if="showIcon"
            :color="selectedColor"
            class="icon-arrow-down"
          />
        </div>
        <div :class="$style.selectedLabel">
          <div v-if="showLabel">
            <cf-span
              v-if="items.length === dataComputed.length"
              multiline
              color="black"
              size="xs"
            >
              {{ $t('all') }}
            </cf-span>
            <cf-span
              v-else-if="items.length === 0"
              color="grey"
              size="xs"
            >
              {{ noneNotEqualAll ? $t('none') : $t('select') }}
            </cf-span>
            <template v-else>
              <cf-span
                multiline
                color="black"
                size="xs"
              >
                {{ labelText }}
              </cf-span>
            </template>
          </div>
        </div>
      </template>
    </cf-tooltip>

    <slot />
  </div>
</template>

<script>
export default {
  props: {
    translate: Boolean,
    name: {
      type: String,
      default: null
    },
    data: {
      type: Array,
      required: true
    },
    labelAttrib: {
      type: String,
      default: ''
    },
    keyAttrib: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      required: true
    },
    noneNotEqualAll: {
      type: Boolean,
      default: false
    },
    reference: {
      type: String,
      required: true
    },
    optionType: {
      type: String,
      default: 'or'
    },
    showOptionType: {
      type: Boolean,
      default: false
    },
    showSearchInput: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    simpleLabel: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    tooltipTitle: {
      type: String,
      default: function () {
        return this.reference
      }
    },
    showSelectEmptyOption: {
      type: Boolean,
      default: false
    },
    dontContains: {
      type: Boolean,
      default: undefined
    }
  },
  data () {
    return {
      items: [],
      pureItems: [],
      search: null
    }
  },
  computed: {
    dataComputed () {
      const emptyItem = !this.keyAttrib
        ? 'empty'
        : {
            [this.keyAttrib]: 'empty',
            [this.labelAttrib]: 'empty'
          }
      if (this.showSelectEmptyOption) {
        return [
          emptyItem,
          ...this.data
        ]
      } else {
        return this.data
      }
    },
    showedItems () {
      const items = this.pureItems.map((item) => {
        const key = item[this.labelAttrib] ? item[this.labelAttrib] : item
        return this.textToShow(key)
      })
      if (items.length > 2) {
        return [items[0], items[1], '+' + (items.length - 2)]
      } else return items
    },
    labelText () {
      if (this.simpleLabel) return this.$t('custom')
      return this.showedItems.join(', ')
    },
    selectedColor () {
      if (this.items.length > 0 && this.items.length < this.dataComputed.length) { return 'primary' }
      if (this.items.length === 0 && this.noneNotEqualAll) return 'grey'
      else return 'black'
    },
    dataToShow () {
      const mappedData = this.dataComputed.map((item) => {
        if (this.keyAttrib) {
          return {
            item: item,
            key: item[this.keyAttrib],
            label: this.textToShow(item[this.labelAttrib])
          }
        } else return { item: item, key: item, label: this.textToShow(item) }
      })
      const filteredData = mappedData.filter(
        (item) =>
          !this.search ||
          item.label.toLowerCase().includes(this.search.toLowerCase())
      )
      return filteredData
    }
  },
  watch: {
    value: function (after) {
      this.items = JSON.parse(JSON.stringify(after))
      if (this.keyAttrib) {
        this.pureItems = this.items
          .map((item) => {
            return this.dataComputed.find(
              (pureItem) => pureItem[this.keyAttrib] === item
            )
          })
          .filter((item) => item)
      } else {
        this.pureItems = JSON.parse(JSON.stringify(this.items))
      }
    }
  },
  created () {
    this.items = JSON.parse(JSON.stringify(this.value))
    if (this.keyAttrib) {
      this.pureItems = this.items
        .map((item) => {
          return this.dataComputed.find((pureItem) => pureItem[this.keyAttrib] === item)
        })
        .filter((item) => item)
    } else {
      this.pureItems = JSON.parse(JSON.stringify(this.items))
    }
  },
  methods: {
    emit (item, pureItem) {
      if (this.items.includes(item)) {
        this.items.splice(this.items.indexOf(item), 1)
        this.pureItems.splice(this.pureItems.indexOf(pureItem), 1)
      } else {
        this.items.push(item)
        this.pureItems.push(pureItem)
      }
      this.$emit('input', this.items)
    },
    isActive (item) {
      return this.items.includes(item)
    },
    selectAll () {
      this.pureItems = JSON.parse(JSON.stringify(this.dataComputed))
      if (!this.keyAttrib) {
        this.items = JSON.parse(JSON.stringify(this.dataComputed))
      } else {
        this.items = this.dataComputed.map((item) => {
          return item[this.keyAttrib]
        })
      }
      this.$emit('input', this.items)
    },
    clearSelection () {
      this.items = []
      this.pureItems = []
      this.$emit('input', this.items)
    },
    filterOptionTypeClass (type) {
      if (type === this.optionType) {
        return 'icon-option-yes'
      } else {
        return 'icon-option-no'
      }
    },
    textToShow (key) {
      if (key === 'empty') return this.$t('empty')
      if (this.translate) {
        const translationKey = this.name ? this.name + '.' + key : key
        return this.$t(translationKey)
      }
      return key
    }
  }
}
</script>

<style lang="scss" module>
.itemIntern {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.4rem 0;
  margin-left: 0.8rem;
  cursor: pointer;
}

.selectorDiv {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  margin-bottom: 1rem;
}

.selector {
  cursor: pointer;
  &:hover {
    text-decoration: underline currentColor;
  }
}
.filterOptionText {
  margin-right: 0.5rem;
}

.items {
  display: grid;
  grid-template-areas: 'a a';
}

.referenceTitleDiv {
  display: flex;
  column-gap: 0.5rem;
  cursor: pointer;
}

.selectedLabel {
  overflow: hidden;
  cursor: pointer;
}

.selectedLabel {
  overflow: hidden;
  cursor: pointer;
}
</style>
