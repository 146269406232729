<template>
  <div
    :collapsed="collapsed"
    :class="$style.layout"
  >
    <div
      v-show="collapsed"
      style="overflow: hidden; cursor: pointer"
      @click="collapsed = false"
    >
      <slot name="collapsedHeader" />
    </div>
    <div
      v-show="!collapsed"
      style="overflow: hidden; cursor: pointer"
      @click="collapsed = true"
    >
      <slot name="expandedHeader" />
    </div>
    <div
      v-show="!collapsed"
      style="display: grid; overflow: hidden"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    collapsed: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" module>
.layout {
  display: grid;
  grid-template: min-content 1fr / 1fr;
  gap: 1rem;
  overflow: hidden;
  &[collapsed] {
    grid-template: min-content / min-content;
    gap: 0;
  }
}
</style>
