import { filterFunctions } from '../utils/filter-utils'
import { sortIssues } from '../utils/sort-utils'
import { getDefaultState } from './state'

function applyIssuesFiltersFunction (state) {
  const issues = state.issues

  if (state.standardFilter !== 'custom') {
    state.filteredIssues = issues
  } else {
    const filters = state.customFilters

    state.filteredIssues = issues.filter((issue) => {
      for (const filter in filters) {
        const visible = filterFunctions[filter]
          ? filterFunctions[filter](issue, filters, filter)
          : true
        if (!visible) return false
      }
      return true
    })
  }
}

export default {
  resetProjectIssuesState (state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },

  setIssues: (state, issues) => {
    state.issues = issues
    sortIssues(state)
  },

  setStandardFilter: (state, filter) => {
    state.standardFilter = filter
  },

  setCustomFilters: (state, customFilters) => {
    state.customFilters = customFilters
    applyIssuesFiltersFunction(state)
  },

  setSearchIssues: (state, search) => {
    state.searchIssues = search
  },

  setStarredIssue: (state, { issueId, starred }) => {
    const issue = state.issues.find((issue) => issue.id === issueId)
    if (issue) issue.userPersonalData.starred = starred
  },

  setProjectIssueCommentUnread: (state, { issueId, commentId }) => {
    const issue = state.issues.find((issue) => issue.id === issueId)
    if (issue) {
      issue.notificationsSummary.count++
      issue.notificationsSummary.last = new Date()
    }
    const comment = state.selectedIssueComments.find(
      (comment) => comment.id === commentId
    )
    if (comment) issue.hasNotification = true
  },

  setIssueComments: (state, comments) => {
    state.selectedIssueComments = comments
  },

  setIssueHistory: (state, history) => {
    state.selectedIssueHistory = history
  },

  setCommentsFilters: (state, commentsFilters) => {
    state.commentsFilters = commentsFilters
  },

  setHistoryFilters: (state, historyFilters) => {
    state.historyFilters = historyFilters
  },

  setProjectEnums: (state, projectEnums) => {
    state.projectEnums = projectEnums
  },

  setSelectedIssues: (state, selectedIssues) => {
    state.selectedIssues = selectedIssues
  },

  setUserGroups: (state, userGroups) => {
    state.userGroups = userGroups
  },

  pushNewUserGroup: (state, group) => {
    state.userGroups.push(group)
  },

  updateUserGroup: (state, group) => {
    const index = state.userGroups.indexOf(
      state.userGroups.find((userGroup) => userGroup.id === group.groupId)
    )
    state.userGroups.splice(index, 1)
    state.userGroups.push({ id: group.groupId, ...group })
  },

  removeUserGroup: (state, groupId) => {
    const index = state.userGroups.indexOf(
      state.userGroups.find((userGroup) => userGroup.id === groupId)
    )
    state.userGroups.splice(index, 1)
  },

  removeIssue: (state, issueId) => {
    const filteredIssuesIndex = state.filteredIssues.findIndex(
      (issue) => issue.id === issueId
    )
    if (filteredIssuesIndex !== -1) { state.filteredIssues.splice(filteredIssuesIndex, 1) }

    const issuesIndex = state.issues.findIndex((issue) => issue.id === issueId)
    if (issuesIndex !== -1) state.issues.splice(issuesIndex, 1)
  },

  updateIssues: (state, updatedIssues) => {
    updatedIssues.forEach((updatedIssue) => {
      const currentIssue = state.issues.find(
        (issue) => issue.id === updatedIssue.id
      )
      if (currentIssue) Object.assign(currentIssue, updatedIssue)
    })
  },

  addIssue: (state, issue) => {
    let addAtPosition = 0
    if (state.selectedIssues.length === 1) {
      const selectedIssuePosition = state.filteredIssues.findIndex(
        (listedIssue) => listedIssue.id === state.selectedIssues[0]
      )
      addAtPosition = selectedIssuePosition + 1
    }
    state.filteredIssues.splice(addAtPosition, 0, issue)
  },

  setIssuesNotifications: (state, { issuesId, count }) => {
    issuesId.forEach((issueId) => {
      const issue = state.issues.find((issue) => issue.id === issueId)
      if (issue) {
        issue.notificationsSummary = {
          count,
          last: count ? new Date() : null
        }
      }
    })
    if (count === 0) {
      state.selectedIssueComments.forEach((comment) => {
        comment.hasNotification = false
      })
      state.selectedIssueHistory.forEach((history) => {
        history.hasNotification = false
      })
    }
  },

  setUserTags: (state, tags) => {
    state.userTags = tags
  },
  setMentionableIssues: (state, value) => {
    state.mentionableIssues = value
  },
  setCcodeDocumentsReferences: (state, ccodeDocumentsReferences) => {
    state.ccodeDocumentsReferences = ccodeDocumentsReferences
  },

  setMentionedUsersIds: (state, mentionedUsersIds) => {
    state.mentionedUsersIds = mentionedUsersIds
  },
  setCreatorUsersIds: (state, creatorUsersIds) => {
    state.creatorUsersIds = creatorUsersIds
  },
  setEditorUsersIds: (state, editorUsersIds) => {
    state.editorUsersIds = editorUsersIds
  },
  setPublisherUsersIds: (state, publisherUsersIds) => {
    state.publisherUsersIds = publisherUsersIds
  },

  setIssueTags: (state, issueTags) => {
    const issue = state.issues.find((issue) => issue.id === issueTags.issueId)
    if (issue) {
      issue.userPersonalData.tags = []
      issueTags.tags.forEach((tagId) => {
        const tag = state.userTags.find((userTag) => +userTag.id === +tagId)
        if (tag) issue.userPersonalData.tags.push({ tagId, tag })
      })
    }
  },

  applyIssuesFilters: (state) => {
    applyIssuesFiltersFunction(state)
  },

  setSortSettings: (state, sortSettings) => {
    state.sortSettings = sortSettings
    sortIssues(state)
    applyIssuesFiltersFunction(state)
  },
  setSmartFilterIssuesIds: (state, issuesIds) => {
    if (issuesIds && issuesIds.length) {
      state.issues.sort(
        (a, b) => issuesIds.indexOf(a.id) - issuesIds.indexOf(b.id)
      )
    } else {
      sortIssues(state)
    }

    state.smartFilterIssuesIds = issuesIds
  }
}
