<template>
  <cf-menu-layout :backTo="backTo">
    <template #header>
      <cf-menu-header
        v-if="header"
        :title="header.title"
        :image="header.image"
        :imageType="header.imageType"
      />
    </template>
    <router-link
      v-for="(item, index) in items"
      :key="index"
      v-slot="{ isActive, navigate }"
      :to="{ name: item.to }"
      custom
      exact
    >
      <cf-menu-item
        v-if="item.to === 'logout'"
        :label="item.label"
        :icon="item.icon"
        :image="item.image"
        :imageType="item.imageType"
        :showImage="item.showImage"
        :active="isActive"
        @click.native="$router.push({ name: 'logout' })"
      />
      <cf-menu-item
        v-else
        :label="item.label"
        :icon="item.icon"
        :image="item.image"
        :imageType="item.imageType"
        :showImage="item.showImage"
        :active="isActive"
        @click.native="navigate"
      />
    </router-link>
  </cf-menu-layout>
</template>
<script>
import loggedUserMixin from '@/mixins/logged-user.js'

export default {
  mixins: [loggedUserMixin],
  props: {
    header: {
      type: Object,
      default: null
    },
    items: {
      type: Array,
      default: null
    },
    backTo: {
      type: String,
      default: null
    }
  }
}
</script>
