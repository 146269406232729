<template>
  <div :class="$style.cfPwdValidation">
    <cf-span
      v-for="rule in passwordValidation.rules"
      :key="rule.message"
      size="sm"
      :class="$style.transition"
      :color="rule.valid ? 'primary' : 'grey'"
    >
      {{ rule.message }}
    </cf-span>
    <cf-span
      size="sm"
      :class="$style.transition"
      :color="samePasswords ? 'primary' : 'grey'"
    >
      {{ $t('samePasswords') }}<br>
    </cf-span>
  </div>
</template>
<script>
export default {
  props: {
    password: { type: undefined, required: true },
    checkPassword: { type: undefined, required: true }
  },
  data () {
    return {
      rules: [
        { message: this.$t('upperLetter'), regex: /[A-Z]+/, valid: false },
        { message: this.$t('lowerLetter'), regex: /[a-z]+/, valid: false },
        {
          message: this.$t('specialChar'),
          regex: /^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/,
          valid: false
        },
        { message: this.$t('number'), regex: /[0-9]+/, valid: false },
        { message: this.$t('8char'), regex: /.{8,}/, valid: false }
      ]
    }
  },
  computed: {
    isPasswordValid () {
      if (
        this.passwordsFilled &&
        this.samePasswords &&
        this.passwordValidation.valid
      ) {
        return true
      } else {
        return false
      }
    },
    passwordValidation () {
      const errors = []
      if (this.password === null) return { valid: false, rules: this.rules }
      for (const condition of this.rules) {
        if (!condition.regex.test(this.password)) {
          errors.push(condition.message)
          condition.valid = false
        } else {
          condition.valid = true
        }
      }
      if (errors.length === 0) {
        return { valid: true, rules: this.rules }
      } else {
        return { valid: false, rules: this.rules }
      }
    },
    passwordsFilled () {
      return !!this.password && !!this.checkPassword
    },
    samePasswords () {
      if (this.password) {
        return this.password === this.checkPassword
      } else {
        return false
      }
    }
  },

  watch: {
    password () {
      this.$emit('valid', this.isPasswordValid)
    },
    checkPassword () {
      this.$emit('valid', this.isPasswordValid)
    }
  }
}
</script>
<style lang="scss" module>
.container {
  display: grid;
  grid-template-rows: repeat(2, min-content);
  grid-gap: 2.5rem;
  @include device(mobile) {
    grid-gap: 2rem;
  }
}

.inputDiv {
  display: flex;
  flex-direction: column;
}

.transition {
  transition: 0.3s;
}

.cfPwdValidation {
  display: grid;
  grid-template-columns: 60% 40%;
}
</style>
