<template>
  <div style="display: grid">
    <div
      v-if="label"
      :class="$style.labelDiv"
    >
      <cf-span
        size="sm"
        :class="$style.labelClass"
        :color="inputError ? 'red' : 'grey'"
      >
        {{ label }}
      </cf-span>
      <cf-span
        v-show="required"
        size="lg"
        :color="inputError ? 'red' : 'grey'"
      >
        *
      </cf-span>
    </div>
    <el-cascader
      v-model="itemValue"
      filterable
      size="mini"
      :props="{
        multiple: true,
        value: 'id',
        label: 'abbreviation'
      }"
      :options="optionsTree"
      placeholder
      :collapseTags="true"
      :filter-method="filter"
    >
      <template slot-scope="{ node, data }">
        <span>{{ data.name }}</span>
        <span v-if="!node.isLeaf">{{ data.children.name }}</span>
      </template>
    </el-cascader>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: null
    },
    optionsTree: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    inputError: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      itemValue: null
    }
  },
  watch: {
    itemValue: {
      deep: true,
      handler: function () {
        this.$emit('input', this.itemValue)
        this.transformItems(this.itemValue)
      }
    }
  },
  created () {
    this.itemValue = this.value
  },
  methods: {
    transformItems (issueItems) {
      const itemsArray = []
      issueItems.forEach((l) => {
        if (!Array.isArray(l)) itemsArray.push(l)
        else itemsArray.push(l[l.length - 1])
      })
      this.$emit('transformedItems', itemsArray)
    },

    filter (node, keyword) {
      return (
        !keyword ||
        node.data.abbreviation.toLowerCase().includes(keyword.toLowerCase()) ||
        node.data.name.toLowerCase().includes(keyword.toLowerCase())
      )
    }
  }
}
</script>

<style lang="scss" module>
.labelDiv {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  column-gap: 0.2rem;
  grid-template-rows: 1.8rem;
}
.labelClass {
  transition: 0.3s ease-in-out;
  &:hover {
    color: getColor('black');
  }
}
</style>
