<template>
  <button
    :disabled="disabled"
    :showIconOnMobile="!!mobileIcon"
    :class="buttonClass"
    :rectangular="rectangular"
    :slim="slim"
    @click="buttonEvent"
  >
    <div
      v-if="!mobileIcon || !isMobile"
      :class="$style.slot"
    >
      <slot name="default" />
    </div>
    <cf-span
      v-if="!!mobileIcon && isMobile"
      :size="mobileIconSize"
      :class="[mobileIcon, $style.mobileIcon]"
    />
  </button>
</template>

<script>
export default {
  props: {
    primary: {
      type: Boolean,
      default: true
    },
    secondary: {
      type: Boolean,
      default: false
    },
    aiPrimary: {
      type: Boolean,
      default: false
    },
    danger: {
      type: Boolean,
      default: false
    },
    dangerWireframe: {
      type: Boolean,
      default: false
    },
    green: {
      type: Boolean,
      default: false
    },
    auth: {
      type: Boolean,
      default: false
    },
    ccode: {
      type: Boolean,
      default: false
    },

    mobileIcon: {
      type: String,
      default: null
    },
    mobileIconSize: { type: String, default: 'md' },
    disabled: {
      type: Boolean,
      default: false
    },

    fontSize: {
      type: String,
      default: 'sm'
    },

    rectangular: {
      type: Boolean,
      default: false
    },

    slim: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    buttonClass () {
      const classes = [
        this.$style.button,
        `cf-text__${this.fontSize}`,
        this.$style[`buttonPadding__${this.fontSize}`]
      ]

      if (this.disabled) classes.push(this.$style.disabled)

      if (this.secondary) classes.push(this.$style.secondary)
      else if (this.aiPrimary) classes.push(this.$style.aiPrimary)
      else if (this.green) classes.push(this.$style.green)
      else if (this.danger) classes.push(this.$style.danger)
      else if (this.dangerWireframe) classes.push(this.$style.dangerWireframe)
      else if (this.auth) classes.push(this.$style.auth)
      else if (this.ccode) classes.push(this.$style.ccode)
      else classes.push(this.$style.primary)

      return classes
    }
  },

  methods: {
    buttonEvent (e) {
      if (this.disabled) return
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" module>
.button {
  padding: 1rem 2rem;
  border-radius: 2.5rem;
  border: none;
  white-space: nowrap;
  height: fit-content;
  cursor: pointer;
  &[showIconOnMobile] {
    @include device(mobile) {
      padding: 0rem;
    }
  }
  &[rectangular] {
    border-radius: 0.5rem;
  }
  &[slim] {
    padding: 0.5rem 1rem;
  }
}

.buttonPadding__2xs {
  padding: 0.3rem 0.6rem;
}

.buttonPadding__xs {
  padding: 0.5rem 1rem;
}

.buttonPadding__sm {
  padding: 1rem 2rem;
}
.buttonPadding__md {
  padding: 1.1rem 2.2rem;
}
.buttonPadding__lg {
  padding: 1.2rem 2.4rem;
}
.buttonPadding__xl {
  padding: 1.5rem 2.9rem;
}

.buttonPadding__2xl {
  padding: 2rem 4srem;
}

.primary {
  background-color: getColor('primary');
  color: getColor('white');
  transition: 0.3s;

  &:hover {
    background-color: getColor('primary-darker');
  }
  .slot {
    * {
      color: getColor('white');
    }
  }
  &[showIconOnMobile] {
    @include device(mobile) {
      background-color: transparent;
      color: getColor('grey');
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.secondary {
  border: 0.1rem solid getColor('grey');
  color: getColor('grey');
  background-color: getColor('white');
  transition: 0.3s;

  &:hover {
    background-color: getColor('grey-lighter');
  }
  .slot {
    * {
      color: getColor('grey');
    }
  }
  &[showIconOnMobile] {
    @include device(mobile) {
      background-color: transparent;
      border: 0;
      color: getColor('grey');
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.aiPrimary {
  background-color: getColor('ai-primary');
  color: getColor('white');
  transition: 0.3s;

  &:hover {
    background-color: getColor('ai-darker');
  }
  .slot {
    * {
      color: getColor('white');
    }
  }
}

.dangerWireframe {
  border: 0.1rem solid getColor('red');
  color: getColor('red');
  background-color: getColor('white');
  transition: 0.3s;

  &:hover {
    background-color: getColor('red-lighter');
  }
  .slot {
    * {
      color: getColor('red');
    }
  }
  &[showIconOnMobile] {
    @include device(mobile) {
      background-color: transparent;
      border: 0;
      color: getColor('red');
      &:hover {
        background-color: transparent;
      }
    }
  }
}
.danger {
  background-color: getColor('red');
  color: getColor('white');
  transition: 0.3s;

  &:hover {
    background-color: getColor('red-darker');
  }
  .slot {
    * {
      color: getColor('white');
    }
  }
  &[showIconOnMobile] {
    @include device(mobile) {
      background-color: transparent;
      color: getColor('red');
      &:hover {
        background-color: transparent;
      }
    }
  }
}
.green {
  background-color: getColor('green');
  color: getColor('white');
  transition: 0.3s;

  &:hover {
    background-color: getColor('green-darker');
  }
  .slot {
    * {
      color: getColor('white');
    }
  }
}

.auth {
  padding: 0.9rem 2rem;
  border: 0.1rem solid getColor('grey');
  color: getColor('grey');
  background-color: getColor('construflow');
  transition: 0.3s;

  &:hover {
    background-color: getColor('construflow-darker');
  }
  .slot {
    * {
      color: getColor('grey');
    }
  }
  &[showIconOnMobile] {
    @include device(mobile) {
      background-color: transparent;
      border: 0;
      color: getColor('grey');
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.mobileIcon {
  padding: 0.5rem;
  color: getColor('grey');
  background-color: getColor('white');
  transition: 0.3s;
}

.disabled {
  background-color: getColor('grey') !important;
  color: getColor('white') !important;
  cursor: not-allowed !important;
  transition: 0.3s !important;
}

.ccode {
  background-color: getColor('ccode-yellow');
  color: getColor('black');
  transition: 1s;
  border-radius: 0.5rem;
  .slot {
    * {
      color: getColor('black');
    }
  }
  &:hover {
    background-color: getColor('ccode-black');
    color: getColor('white');
    .slot {
      * {
        color: getColor('white');
      }
    }
  }
}
</style>
