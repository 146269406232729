const AiChatView = () => import('../views/AiChatView.vue')

export default [
  {
    path: '/ai/chat',
    redirect: { name: 'AiChatView' }
  },
  {
    path: '/caio',
    name: 'AiChatView',
    component: AiChatView
  }
]
