import Vue from 'vue'
import Vuex from 'vuex'
// Modules
import company from '../modules/workspace/modules/company/store/index'
import project from '../modules/workspace/modules/project/store/index'
import projectSetup from '../modules/workspace/modules/project/modules/setup/store/index'
import projectIssues from '../modules/workspace/modules/project/modules/issues/store/index'
import projectDocuments from '../modules/workspace/modules/project/modules/documents/store/index'
import projectModelViewer from '../modules/workspace/modules/project/modules/modelViewer/store/index'
import authService from '../modules/auth/services/auth-service'

Vue.use(Vuex)

const state = {
  loggedUser: null,
  routerLoading: false
}

const mutations = {
  setLoggedUser: (state, loggedUser) => {
    state.loggedUser = loggedUser
  }
}

const actions = {
  async getLoggedUserAction (context) {
    const response = await authService.getLoggedUser()
    let { loggedUserData } = response
    const newSignIn = sessionStorage.getItem('newSignIn')
    if (loggedUserData && loggedUserData.isDemo && !newSignIn) { loggedUserData = null }
    context.commit('setLoggedUser', loggedUserData)
    return loggedUserData
  }
}

const getters = {}

const modules = {
  company,
  project,
  projectSetup,
  projectIssues,
  projectDocuments,
  projectModelViewer
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules
})
