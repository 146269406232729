import CompanyRoutes from './../modules/company/routes'
import UserRoutes from './../modules/userSettings/routes'
import projectRoutes from './../modules/project/routes'
const UserProjects = () => import('../views/UserProjectsView')
const DownloadsView = () => import('../views/DownloadsView')
const WorkspaceView = () => import('../views/WorkspaceView.vue')

export default [
  {
    path: '/workspace',
    name: 'workspace',
    component: WorkspaceView,
    redirect: { name: 'WorkspaceHome' },
    meta: { needsCompany: true },
    children: [
      {
        path: 'home',
        name: 'WorkspaceHome',
        component: UserProjects
      },
      {
        path: 'downloads',
        name: 'Downloads',
        component: DownloadsView
      },
      {
        path: 'smart-docs',
        name: 'SmartDocs',
        component: () => import('../modules/smart-docs/views/SmartDocsView.vue')
      },
      ...projectRoutes,
      ...CompanyRoutes,
      ...UserRoutes
    ]
  }
]
