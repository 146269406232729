import * as projectService from '../services/project-service'

export default {
  async getProjectData (context, projectId) {
    try {
      const response = await projectService.getProjectData(projectId)
      context.commit('setProjectData', response.project)
    } catch (e) {
      context.commit('setProjectData', {})
      throw e
    }
  },

  async updateProjectData (context, project) {
    await projectService.updateProjectData(project)
    await context.dispatch('getProjectData', project.id)
  },
  async setProjectLocals (context, { projectId, locals }) {
    await projectService.setProjectLocals(projectId, locals)
    await context.dispatch('getProjectData', projectId)
  },

  async setProjectLabels (context, { projectId, labels }) {
    await projectService.setProjectLabels(projectId, labels)
    await context.dispatch('getProjectData', projectId)
  },

  async setProjectCategories (context, { projectId, categoriesIds }) {
    await projectService.setProjectCategories(projectId, categoriesIds)
    await context.dispatch('getProjectData', projectId)
  },

  async setProjectDisciplinesStatus (context, { projectId, disciplinesStatus }) {
    await projectService.setProjectDisciplinesStatus(
      projectId,
      disciplinesStatus
    )
    await context.dispatch('getProjectData', projectId)
  },

  async setProjectPhases (context, { projectId, phasesIds }) {
    await projectService.setProjectPhases(projectId, phasesIds)
    await context.dispatch('getProjectData', projectId)
  },

  async setProjectDisciplines (context, { projectId, disciplines }) {
    await projectService.setProjectDisciplines(projectId, disciplines)
    await context.dispatch('getProjectData', projectId)
  },
  async getStandardDisciplines (context) {
    const response = await projectService.getStandardDisciplines()
    context.commit('setStandardDisciplines', response)
  },
  async setProjectDocumentsNamingRule (
    context,
    { projectId, documentsNamingRule, documentsNamingRuleSeparator }
  ) {
    await projectService.setProjectDocumentsNamingRule(
      projectId,
      documentsNamingRule,
      documentsNamingRuleSeparator
    )
    await context.dispatch('getProjectData', projectId)
  }
}
