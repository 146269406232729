import apollo from '@/plugins/apollo'
import issuesQuery from './graphql/queries/issues.gql'
import issuesReportQuery from './graphql/queries/issuesReport.gql'
import projectIssuesGeneralDataQuery from './graphql/queries/projectIssuesGeneralData.gql'
import projectEnums from './graphql/queries/projectEnums.gql'
import projectIssueDetailsQuery from './graphql/queries/issueDetails.gql'
import commentsAndHistoricQuery from './graphql/queries/commentsAndHistoric.gql'
import projectIssuesImagesQuery from './graphql/queries/issueImages.gql'
import projectUsersQuery from './graphql/queries/projectUsers.gql'
import getUserGroupsQuery from './graphql/queries/projectGroups.gql'
import commentHistory from './graphql/queries/commentHistory.gql'

import createIssueMutation from './graphql/mutations/createIssue.gql'
import setProjectIssueStarringMutation from './graphql/mutations/setProjectIssueStarring.gql'
import updateIssueMutation from './graphql/mutations/updateIssue.gql'
import createProjectIssueCommentMutation from './graphql/mutations/createProjectIssueCommentMutation.gql'
import updateProjectIssueCommentMutation from './graphql/mutations/updateProjectIssueCommentMutation.gql'
import markIssuesAsReadMutation from './graphql/mutations/markIssuesAsRead.gql'
import SetProjectUserTagsMutation from './graphql/mutations/setUserTags.gql'
import setProjectIssueUserTagsMutation from './graphql/mutations/setProjectIssueUserTags.gql'
import setIssuesBulkUpdateMutation from './graphql/mutations/issuesBulkUpdate.gql'
import markIssuesAsUnreadMutation from './graphql/mutations/markIssuesAsUnread.gql'
import createGroupMutation from './graphql/mutations/createProjectGroup.gql'
import updateUserGroupMutation from './graphql/mutations/updateProjectGroup.gql'
import leaveUserGroupMutation from './graphql/mutations/leaveProjectGroup.gql'
import deleteUserGroupMutation from './graphql/mutations/deleteProjectGroup.gql'
import deleteIssueMutation from './graphql/mutations/deleteIssue.gql'
import deleteProjectIssueCommentMutation from './graphql/mutations/deleteProjectIssueComment.gql'
import setProjectIssueCommentUnreadMutation from './graphql/mutations/setProjectIssueCommentUnread.gql'
import copyProjectIssuesMutation from './graphql/mutations/copyProjectIssues.gql'
import createProjectIssueFileUploadSignedUrlMutation from './graphql/mutations/createProjectIssueFileUploadSignedUrlMutation.gql'

function treatIssueIncomingData (issue) {
  if (issue.images) issue.images.sort((a, b) => a.index - b.index)
  if (issue.ccodeDocumentsReferences) { issue.ccodeDocumentsReferences.sort((a, b) => a.index - b.index) }

  if (!issue.locals) issue.locals = []
  issue.locals = issue.locals.filter((l) => l.local)

  if (!issue.labels) issue.labels = []
  issue.labels = issue.labels.filter((l) => l.label)

  if (!issue.disciplines) issue.disciplines = []
  issue.disciplines = issue.disciplines.filter((d) => d.discipline)

  if (
    issue.userPersonalData &&
    issue.userPersonalData.tags &&
    issue.userPersonalData.tags.length
  ) {
    issue.userPersonalData.tags = issue.userPersonalData.tags.filter(
      (t) => t.tag
    )
  }

  issue.disciplines.sort((a, b) =>
    a.discipline.abbreviation
      .toLowerCase()
      .localeCompare(b.discipline.abbreviation.toLowerCase())
  )
}

async function getProjectIssuesGeneralData (variables) {
  const response = await apollo.query({
    query: projectIssuesGeneralDataQuery,
    fetchPolicy: 'network-only',
    variables
  })

  return response.data
}

async function getProjectIssues (variables) {
  const first = 500
  let after
  let hasNextPage = true
  const issues = []
  while (hasNextPage) {
    const { data } = await apollo.query({
      query: issuesQuery,
      fetchPolicy: 'network-only',
      variables: { first, after, ...variables }
    })
    issues.push(...data.project.issues.issues)
    hasNextPage = data.project.issues.pageInfo.hasNextPage
    after = data.project.issues.pageInfo.endCursor
  }

  issues.forEach(treatIssueIncomingData)

  return issues
}

async function getProjectIssuesReport (variables) {
  const first = 500
  let after
  let hasNextPage = true
  const issues = []
  while (hasNextPage) {
    const { data } = await apollo.query({
      query: issuesReportQuery,
      fetchPolicy: 'network-only',
      variables: { first, after, ...variables }
    })
    issues.push(...data.project.issues.issues)
    hasNextPage = data.project.issues.pageInfo.hasNextPage
    after = data.project.issues.pageInfo.endCursor
  }

  issues.forEach(treatIssueIncomingData)

  return issues
}

async function getProjectEnums () {
  const response = await apollo.query({
    query: projectEnums,
    fetchPolicy: 'cache-first'
  })
  return response.data
}

async function getProjectIssueDetails ({ projectId, issueId }) {
  const variables = { projectId, issueId }
  const response = await apollo.query({
    query: projectIssueDetailsQuery,
    fetchPolicy: 'network-only',
    variables
  })

  treatIssueIncomingData(response.data.issue)

  return response.data.issue
}

async function getIssueCommentsAndHistoric ({ projectId, issueId }) {
  const variables = { projectId, issueId }
  const response = await apollo.query({
    query: commentsAndHistoricQuery,
    fetchPolicy: 'network-only',
    variables
  })
  response.data.issue.comments.forEach((comment) => {
    comment.images.sort((a, b) => a.index - b.index)
  })

  return {
    comments: response.data.issue.comments,
    notifications: response.data.issue.notifications,
    history: response.data.issue.history
  }
}

async function getProjectIssuesImages (projectId) {
  const variables = { projectId }
  const response = await apollo.query({
    query: projectIssuesImagesQuery,
    fetchPolicy: 'network-only',
    variables
  })
  return response.data.project.issuesImages
}

async function getProjectUsers (projectId) {
  const variables = { projectId }
  const response = await apollo.query({
    query: projectUsersQuery,
    fetchPolicy: 'network-only',
    variables
  })
  return response.data.project.users
}

async function getUserGroups (projectId) {
  const variables = { projectId }
  const response = await apollo.query({
    query: getUserGroupsQuery,
    fetchPolicy: 'network-only',
    variables
  })
  return response.data
}

async function getCommentHistory ({ projectId, commentId }) {
  const variables = { projectId, commentId }
  const response = await apollo.query({
    query: commentHistory,
    fetchPolicy: 'network-only',
    variables
  })
  return response.data.getProjectIssueCommentHistory
}
// Mutations

async function createIssue (issue) {
  const variables = issue
  const response = await apollo.mutate({
    mutation: createIssueMutation,
    variables
  })
  return response.data.createIssue
}

const setProjectIssueStarring = async ({ projectId, issueId, starred }) => {
  const variables = { projectId, issueId, starred }
  const response = await apollo.mutate({
    mutation: setProjectIssueStarringMutation,
    variables
  })
  return response.data
}

const setProjectIssueCommentUnread = async ({
  projectId,
  issueId,
  commentId
}) => {
  const variables = { projectId, issueId, commentId }
  await apollo.mutate({
    mutation: setProjectIssueCommentUnreadMutation,
    variables
  })
}

async function updateIssue (issue) {
  const variables = issue
  const response = await apollo.mutate({
    mutation: updateIssueMutation,
    variables
  })
  return response.data.updateIssue
}

async function createProjectIssueComment (comment) {
  const variables = comment

  const response = await apollo.mutate({
    mutation: createProjectIssueCommentMutation,
    variables
  })

  return response.data.createProjectIssueComment
}

async function updateProjectIssueComment (comment) {
  const variables = comment

  const response = await apollo.mutate({
    mutation: updateProjectIssueCommentMutation,
    variables
  })

  return response.data.updateProjectIssueComment
}

async function deleteProjectIssueComment ({ projectId, commentId }) {
  const variables = { projectId, commentId }

  const response = await apollo.mutate({
    mutation: deleteProjectIssueCommentMutation,
    variables
  })

  return response.data.deleteProjectIssueComment
}

async function createUserGroup ({ name, projectId, usersId }) {
  const variables = { name, projectId, usersId }
  const response = await apollo.mutate({
    mutation: createGroupMutation,
    variables
  })
  return response.data
}

async function leaveUserGroup ({ projectId, groupId }) {
  const variables = { projectId, groupId }
  const response = await apollo.mutate({
    mutation: leaveUserGroupMutation,
    variables
  })
  return response.data
}

async function deleteUserGroup ({ projectId, groupId }) {
  const variables = { projectId, groupId }
  const response = await apollo.mutate({
    mutation: deleteUserGroupMutation,
    variables
  })
  return response.data
}

async function deleteIssue ({ projectId, issueId }) {
  const variables = { projectId, issueId }
  const response = await apollo.mutate({
    mutation: deleteIssueMutation,
    variables
  })
  return response.data
}

async function updateUserGroup ({ projectId, name, groupId, usersId }) {
  const variables = { projectId, name, groupId, usersId }
  await apollo.mutate({
    mutation: updateUserGroupMutation,
    variables
  })
  return { name, groupId, usersId }
}

async function setIssuesAsRead (issuesId) {
  const variables = { issuesId }
  const response = await apollo.mutate({
    mutation: markIssuesAsReadMutation,
    variables
  })
  return response.data
}

async function setIssuesAsUnread ({ projectId, issuesId }) {
  const variables = { projectId, issuesId }
  const response = await apollo.mutate({
    mutation: markIssuesAsUnreadMutation,
    variables
  })
  return response.data
}

const setProjectUserTags = async ({ projectId, tags }) => {
  const variables = { projectId, tags }
  const response = await apollo.mutate({
    mutation: SetProjectUserTagsMutation,
    variables
  })
  return response.data.setProjectUserTags
}

const setProjectIssueUserTags = async ({ projectId, issueId, tags }) => {
  const variables = { projectId, issueId, tags }
  const response = await apollo.mutate({
    mutation: setProjectIssueUserTagsMutation,
    variables
  })
  return response.data
}

const setIssuesBulkUpdate = async ({ projectId, issuesIds, newValues }) => {
  const variables = { projectId, issuesIds, newValues }
  const response = await apollo.mutate({
    mutation: setIssuesBulkUpdateMutation,
    variables
  })
  return response.data
}

const copyProjectIssues = async (
  sourceProjectId,
  targetProjectId,
  issuesIds,
  visibility,
  groupId,
  replacements
) => {
  const variables = {
    sourceProjectId,
    targetProjectId,
    issuesIds,
    visibility,
    groupId,
    replacements
  }
  const res = await apollo.mutate({
    mutation: copyProjectIssuesMutation,
    variables
  })
  return res
}

const createProjectIssueFileUploadSignedUrl = async (projectId, filename) => {
  const variables = { projectId, filename }
  const response = await apollo.mutate({
    mutation: createProjectIssueFileUploadSignedUrlMutation,
    variables
  })
  return response.data.createProjectIssueFileUploadSignedUrl
}

export {
  getProjectIssues,
  getProjectIssuesReport,
  getProjectEnums,
  getProjectIssueDetails,
  getIssueCommentsAndHistoric,
  setProjectIssueStarring,
  createIssue,
  getProjectIssuesImages,
  getProjectUsers,
  updateIssue,
  createProjectIssueComment,
  getUserGroups,
  createUserGroup,
  updateUserGroup,
  setIssuesAsRead,
  setProjectUserTags,
  setProjectIssueUserTags,
  setIssuesBulkUpdate,
  setIssuesAsUnread,
  leaveUserGroup,
  deleteUserGroup,
  updateProjectIssueComment,
  getCommentHistory,
  deleteProjectIssueComment,
  deleteIssue,
  setProjectIssueCommentUnread,
  getProjectIssuesGeneralData,
  copyProjectIssues,
  createProjectIssueFileUploadSignedUrl
}
