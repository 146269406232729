const UserView = () => import('./../views/UserView')
const UserProfile = () => import('./../views/UserProfileView')
const UserNotifications = () => import('./../views/UserNotificationsView')
const ChangePassword = () => import('./../views/ChangePasswordView')
const UserAccessKeysView = () => import('./../views/UserAccessKeysView')
export default [
  {
    path: 'user',
    name: 'userSettings',
    component: UserView,
    redirect: { name: 'userProfile' },
    children: [
      {
        path: 'profile',
        name: 'userProfile',
        component: UserProfile
      },
      {
        path: 'changePassword',
        name: 'changePassword',
        component: ChangePassword
      },
      {
        path: 'notifications',
        name: 'userNotifications',
        component: UserNotifications
      },
      {
        path: 'accessKeys',
        name: 'userAccessKeys',
        component: UserAccessKeysView
      }
    ]
  }
]
