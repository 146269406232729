<template>
  <cf-tooltip :disabled="!showVariesText">
    <cf-span>{{ $t('clickToChange') }}</cf-span>
    <template #reference>
      <cf-span
        v-if="showVariesText"
        :disabled="disabled"
        clickable
        :color="'grey'"
        size="xs"
        @click="
          changeVariableValue = true
          $emit('startChanging')
        "
      >
        ({{ $t('varies') }})
      </cf-span>
      <slot v-else />
    </template>
  </cf-tooltip>
</template>

<script>
export default {
  props: {
    value: {
      type: undefined,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      changeVariableValue: false
    }
  },
  computed: {
    showVariesText () {
      return this.value === 'varies' && !this.changeVariableValue
    }
  },
  watch: {
    value: function (newValue) {
      if (newValue === 'varies') this.changeVariableValue = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
