import forgeService from '../services/forge-service'

export default {
  async getProjectForgeFiles (context, projectId) {
    const models = await forgeService.getProjectForgeFiles(projectId)
    models.sort(
      (a, b) =>
        new Date(b.visibilityUpdatedAt) - new Date(a.visibilityUpdatedAt)
    )

    context.commit('setModelsList', models)
  },

  async createProjectForgeFile (context, params) {
    await forgeService.createProjectForgeFile(params)
  },

  async updateForgeFile (context, { id, projectId, title, visibility }) {
    await forgeService.updateProjectForgeFile(id, projectId, title, visibility)
  }
}
