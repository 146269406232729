<template>
  <el-collapse-item :name="name">
    <template slot="title">
      <cf-span
        semibold
        multiline
        :color="titleColor"
        size="lg"
      >
        <slot name="title" />
        {{ title }}
      </cf-span>
    </template>
    <slot />
  </el-collapse-item>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    titleColor: {
      type: String,
      default: 'grey'
    }
  }
}
</script>

<style lang="scss" module></style>
