const sortOptions = [
  { id: 'starred', functionName: 'starred', order: -1 },
  {
    id: 'mentionNotifications',
    functionName: 'mentionNotifications',
    order: -1
  },
  { id: 'notifications', functionName: 'lastNotificationDate', order: -1 },
  { id: 'visibilityUpdatedAt', functionName: 'visibilityUpdatedAt', order: -1 },
  { id: 'priority', functionName: 'priority', order: -1 },
  { id: 'status', functionName: 'status', order: 1 },
  { id: 'deadline', functionName: 'deadline', order: 1 },
  { id: 'createdAt', functionName: 'createdAt', order: -1 },
  { id: 'title', functionName: 'title', order: 1 }
]

const clearedSortSettings = ['notifications', 'visibilityUpdatedAt']

function sortIssues (state) {
  const sortSettings = state.sortSettings
  const issues = state.issues

  issues.sort((a, b) => {
    for (const optionId of sortSettings) {
      const sortSetting = sortOptions.find((option) => option.id === optionId)
      const comparerFunction = sortFunctions[sortSetting.functionName]
      if (!comparerFunction) continue
      const compareResult = comparerFunction(a, b, state)
      if (compareResult !== 0) return compareResult * sortSetting.order
    }
    return a.id - b.id
  })
}

const sortFunctions = {
  lastNotificationDate (a, b) {
    if (!a.notificationsSummary.last && !b.notificationsSummary.last) return 0
    if (!b.notificationsSummary.last) return 1
    if (!a.notificationsSummary.last) return -1
    return (
      new Date(a.notificationsSummary.last) -
      new Date(b.notificationsSummary.last)
    )
  },

  priority (a, b, state) {
    const priorities = state.projectEnums.systemPriorities
    return priorities.indexOf(a.priority) - priorities.indexOf(b.priority)
  },

  status (a, b, state) {
    const statusList = state.projectEnums.systemIssueStatus
    return statusList.indexOf(a.status) - statusList.indexOf(b.status)
  },

  deadline (a, b) {
    const aDeadline =
      a.computedDeadlines &&
      a.computedDeadlines.cardDeadline &&
      a.computedDeadlines.cardDeadline.deadline
    const bDeadline =
      b.computedDeadlines &&
      b.computedDeadlines.cardDeadline &&
      b.computedDeadlines.cardDeadline.deadline
    if (!aDeadline && !bDeadline) return 0
    if (!bDeadline) return -1
    if (!aDeadline) return 1
    return new Date(aDeadline) - new Date(bDeadline)
  },

  visibilityUpdatedAt (a, b) {
    return new Date(a.visibilityUpdatedAt) - new Date(b.visibilityUpdatedAt)
  },

  createdAt (a, b) {
    return new Date(a.createdAt) - new Date(b.createdAt)
  },

  starred (a, b) {
    return a.userPersonalData.starred - b.userPersonalData.starred
  },

  mentionNotifications (a, b) {
    return a.notificationsSummary.mentions - b.notificationsSummary.mentions
  },

  title (a, b) {
    return a.title.localeCompare(b.title)
  }
}

export { sortOptions, clearedSortSettings, sortIssues }
