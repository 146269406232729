<template>
  <cf-tooltip>
    <template #reference>
      <div :class="$style.flexDiv">
        <cf-span
          :size="size"
          :class="computedPriority.icon"
          :color="computedPriority.color"
        />
      </div>
    </template>
    <cf-span multiline>
      {{ $t('priority') }}: {{ $t(priority) }}
    </cf-span>
  </cf-tooltip>
</template>

<script>
export default {
  props: {
    priority: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'sm'
    }
  },

  computed: {
    computedPriority () {
      switch (this.priority) {
        case 'high':
          return {
            icon: 'icon-high',
            color: 'red'
          }

        case 'medium':
          return {
            icon: 'icon-medium',
            color: 'yellow'
          }

        default:
          return {
            icon: 'icon-low',
            color: 'green'
          }
      }
    }
  }
}
</script>

<style lang="scss" module>
.flexDiv {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
</style>
