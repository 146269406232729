import * as issuesService from '../services/issues-service'
import { calculateUserIssuePendencieAndDeadline } from '../utils/issues-deadline'

const cloneDeep = require('clone-deep')

export default {
  async getIssuesGeneralData (context, variables) {
    context.commit('setUserTags', [])
    context.commit('setUserGroups', [])
    context.commit('setMentionableIssues', [])

    const response = await issuesService.getProjectIssuesGeneralData(variables)

    context.commit('setUserTags', response.project.userTags)
    context.commit('setUserGroups', response.project.groups)
    context.commit('setMentionableIssues', response.project.mentionableIssues)
  },

  async getIssues (context, variables) {
    context.commit('setIssues', [])
    context.commit('setCcodeDocumentsReferences', [])
    context.commit('setMentionedUsersIds', [])
    context.commit('setCreatorUsersIds', [])
    context.commit('setEditorUsersIds', [])
    context.commit('setPublisherUsersIds', [])

    const issues = await issuesService.getProjectIssues(variables)
    const ccodeDocumentsReferences = new Set(
      context.state.ccodeDocumentsReferences
    )
    const mentionedUsersIds = new Set()
    const creatorUsersIds = new Set()
    const editorUsersIds = new Set()
    const publisherUsersIds = new Set()

    issues.forEach((issue) => {
      if (issue.ccodeDocumentsReferences) {
        issue.ccodeDocumentsReferences.forEach((ccodeDocumentReference) =>
          ccodeDocumentsReferences.add(ccodeDocumentReference.title)
        )
      }
      issue.mentionedUsers.forEach((userId) => mentionedUsersIds.add(userId))
      creatorUsersIds.add(issue.createdByUserId)
      editorUsersIds.add(issue.editedByUserId)
      publisherUsersIds.add(issue.visibilityUpdatedByUserId)
      issue.disciplines.sort((a, b) =>
        a.discipline.abbreviation
          .toLowerCase()
          .localeCompare(b.discipline.abbreviation.toLowerCase())
      )

      calculateUserIssuePendencieAndDeadline(
        issue,
        context.rootState.project.projectData.permissions,
        context.state.projectEnums &&
          context.state.projectEnums.systemIssueDisciplineStatus
      )
    })

    context.commit('setIssues', issues)
    context.commit(
      'setCcodeDocumentsReferences',
      [...ccodeDocumentsReferences].sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
      )
    )
    context.commit('setMentionedUsersIds', [...mentionedUsersIds])
    context.commit('setCreatorUsersIds', [...creatorUsersIds])
    context.commit('setEditorUsersIds', [...editorUsersIds])
    context.commit('setPublisherUsersIds', [...publisherUsersIds])
    context.commit('applyIssuesFilters')
  },

  async updateIssue (context, issue) {
    await issuesService.updateIssue(issue)
    await context.dispatch('getUpdatedIssues', {
      projectId: issue.projectId,
      ids: [issue.id]
    })
  },

  async createIssue (context, issue) {
    const createdIssue = await issuesService.createIssue(issue)
    const issueToAddToList = await issuesService.getProjectIssues({
      projectId: createdIssue.projectId,
      filter: { ids: [createdIssue.id] }
    })
    issueToAddToList[0].disciplines.sort((a, b) =>
      a.discipline.abbreviation
        .toLowerCase()
        .localeCompare(b.discipline.abbreviation.toLowerCase())
    )
    calculateUserIssuePendencieAndDeadline(
      issueToAddToList[0],
      context.rootState.project.projectData.permissions,
      context.state.projectEnums &&
        context.state.projectEnums.systemIssueDisciplineStatus
    )
    context.commit('addIssue', issueToAddToList[0])
    return createdIssue
  },

  async getUpdatedIssues (context, { projectId, ids }) {
    const updatedIssues = await issuesService.getProjectIssues({
      projectId,
      filter: { ids }
    })
    updatedIssues.forEach((issue) => {
      issue.disciplines.sort((a, b) =>
        a.discipline.abbreviation
          .toLowerCase()
          .localeCompare(b.discipline.abbreviation.toLowerCase())
      )
      calculateUserIssuePendencieAndDeadline(
        issue,
        context.rootState.project.projectData.permissions,
        context.state.projectEnums &&
          context.state.projectEnums.systemIssueDisciplineStatus
      )
    })
    context.commit('updateIssues', updatedIssues)
  },

  async getProjectEnums (context) {
    context.commit('setProjectEnums', [])
    const response = await issuesService.getProjectEnums()
    context.commit('setProjectEnums', response)
  },

  async setProjectIssueStarring (context, { projectId, issueId, starred }) {
    await issuesService.setProjectIssueStarring({ projectId, issueId, starred })
    context.commit('setStarredIssue', { issueId: issueId, starred: starred })
  },

  async setProjectIssueCommentUnread (
    context,
    { projectId, issueId, commentId }
  ) {
    await issuesService.setProjectIssueCommentUnread({
      projectId,
      issueId,
      commentId
    })
    context.commit('setProjectIssueCommentUnread', { issueId, commentId })
  },

  async getIssueCommentsAndHistoric (context, { projectId, issueId }) {
    context.commit('setIssueComments', [])
    context.commit('setIssueHistory', [])
    const response = await issuesService.getIssueCommentsAndHistoric({
      projectId,
      issueId
    })

    response.comments.forEach((comment) => {
      const hasNotification = response.notifications.some(
        (notification) => +notification.commentId === +comment.id
      )
      comment.hasNotification = hasNotification
    })

    response.history.forEach((history) => {
      const hasNotification = response.notifications.some(
        (notification) => notification.historicId === history._id
      )
      history.hasNotification = hasNotification
    })

    context.commit('setIssueComments', response.comments)
    context.commit('setIssueHistory', response.history)
  },

  async createProjectIssueComment (context, comment) {
    const commentClone = cloneDeep(comment)
    if (commentClone.visibility !== 'group') delete commentClone.group
    const response = await issuesService.createProjectIssueComment(commentClone)
    const commentsArrayCopy = JSON.parse(
      JSON.stringify(context.state.selectedIssueComments)
    )
    commentsArrayCopy.push(response)
    context.commit('setIssueComments', commentsArrayCopy)
  },

  async updateProjectIssueComment (context, comment) {
    const commentClone = cloneDeep(comment)
    commentClone.commentId = commentClone.id
    if (commentClone.visibility === 'creator') delete commentClone.group

    const updatedComment =
      await issuesService.updateProjectIssueComment(commentClone)
    const commentsArrayCopy = JSON.parse(
      JSON.stringify(context.state.selectedIssueComments)
    )
    const oldCommentIndex = commentsArrayCopy.findIndex(
      (oldComment) => oldComment.id === updatedComment.id
    )
    commentsArrayCopy[oldCommentIndex] = updatedComment
    context.commit('setIssueComments', commentsArrayCopy)
  },

  async deleteProjectIssueComment (context, { projectId, commentId }) {
    await issuesService.deleteProjectIssueComment({ projectId, commentId })
    const commentsArrayCopy = JSON.parse(
      JSON.stringify(context.state.selectedIssueComments)
    )
    const oldComment = commentsArrayCopy.find(
      (oldComment) => oldComment.id === commentId
    )
    oldComment.deletedAt = new Date()
    context.commit('setIssueComments', commentsArrayCopy)
  },

  async getUserGroups (context, projectId) {
    context.commit('setUserGroups', [])
    const response = await issuesService.getUserGroups(projectId)
    context.commit('setUserGroups', response.project.groups)
  },

  async createUserGroup (context, group) {
    const response = await issuesService.createUserGroup(group)
    context.commit('pushNewUserGroup', response.createProjectGroup)
  },

  async leaveUserGroup (context, { projectId, groupId }) {
    await issuesService.leaveUserGroup({ projectId, groupId })
    context.commit('removeUserGroup', groupId)
  },

  async deleteProjectGroup (context, { projectId, groupId }) {
    await issuesService.deleteUserGroup({ projectId, groupId })
    context.commit('removeUserGroup', groupId)
  },

  async deleteIssue (context, { projectId, issueId }) {
    await issuesService.deleteIssue({ projectId, issueId })
    context.commit('removeIssue', issueId)
  },

  async updateUserGroup (context, { projectId, name, groupId, usersId }) {
    const response = await issuesService.updateUserGroup({
      projectId,
      name,
      groupId,
      usersId
    })
    context.commit('updateUserGroup', response)
  },

  async setIssuesAsRead (context, issuesId) {
    await issuesService.setIssuesAsRead(issuesId)
    context.commit('setIssuesNotifications', { issuesId: issuesId, count: 0 })
  },

  async setIssuesAsUnread (context, { projectId, issuesId }) {
    await issuesService.setIssuesAsUnread({ projectId, issuesId })
    context.commit('setIssuesNotifications', { issuesId: issuesId, count: 1 })
  },

  async setProjectUserTags (context, { projectId, tags }) {
    const response = await issuesService.setProjectUserTags({ projectId, tags })
    context.commit('setUserTags', response)
  },

  async setProjectIssueUserTags (context, { projectId, issueId, tags }) {
    await issuesService.setProjectIssueUserTags({ projectId, issueId, tags })
    context.commit('setIssueTags', { issueId, tags })
  },

  async setIssuesBulkUpdate (context, { projectId, issuesIds, newValues }) {
    const response = await issuesService.setIssuesBulkUpdate({
      projectId,
      issuesIds,
      newValues
    })
    const rejectedIssues = response.updateIssues.rejectedIssues.map(
      (rejectedIssue) => {
        const projectIssue = context.state.issues.find(
          (projectIssue) => projectIssue.id === rejectedIssue.id
        )
        return {
          ...projectIssue,
          errorCode: rejectedIssue.error
        }
      }
    )
    const updatedIssuesIds = response.updateIssues.updatedIssues.map(
      (issue) => issue.id
    )
    await context.dispatch('getUpdatedIssues', {
      projectId,
      ids: updatedIssuesIds
    })

    return rejectedIssues
  }
}
