import { emitBrowserCommand } from '../services/bim-plugins-service'
import Auth from '@/plugins/auth'

export default {
  created () {
    window.addEventListener('beforeunload', this.onTabClose)
  },

  methods: {
    bimPluginConnection_handleNewLoggedUser (loggedUser) {
      const wsSessionId = sessionStorage.getItem('wsSessionId')
      if (wsSessionId) {
        const authenticated = loggedUser && loggedUser.id
        if (authenticated) this.emitPluginAuthenticate(wsSessionId, loggedUser)
        else this.emitPluginUnauthenticate(wsSessionId)
      }
    },

    async emitPluginAuthenticate (wsSessionId, loggedUser) {
      const session = await Auth.currentSession()
      emitBrowserCommand({
        wsSessionId,
        command: 'authenticate',
        data: {
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
          username: loggedUser.email,
          userId: loggedUser.id
        }
      })
    },

    emitPluginUnauthenticate (wsSessionId) {
      emitBrowserCommand({
        wsSessionId,
        command: 'unauthenticate'
      })
    },

    emitPluginDisconnect (wsSessionId) {
      emitBrowserCommand({
        wsSessionId,
        command: 'disconnect'
      })
    },

    onTabClose (e) {
      const wsSessionId = sessionStorage.getItem('wsSessionId')
      if (wsSessionId) this.emitPluginDisconnect(wsSessionId)
    }
  }
}
