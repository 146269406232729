<template>
  <div :class="$style.projectCard__Company">
    <cf-avatar
      :src="image"
      type="standard"
      imgStyle="height: auto; width: 2rem"
    />
    <cf-span size="sm">
      {{ name }}
    </cf-span>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    image: {
      type: String,
      default: null
    }
  }
}
</script>
<style lang="scss" module>
.projectCard__Company {
  overflow: hidden;
  height: 2.9rem;
  display: grid;
  grid-template: 1fr / auto 1fr;
  align-items: center;
  column-gap: 1rem;
}
</style>
