<template>
  <div
    :class="$style.loader"
    :color="color"
  ></div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'grey'
    }
  }
}
</script>

  <style lang="scss" module>

  $colorGrey: getColor('grey');
  $colorAiPrimary: getColor('ai-primary');
  .loader {
    &[color='grey'] {
      --circleColor: #{$colorGrey};
    }
    &[color='ai-primary'] {
      --circleColor: #{$colorAiPrimary};
    }
    width: 4rem;
    aspect-ratio: 4;
    --_g: no-repeat radial-gradient(circle closest-side, var(--circleColor) 90%, #0000);
    background:
      var(--_g) 0% 50%,
      var(--_g) 50% 50%,
      var(--_g) 100% 50%;
    background-size: calc(100% / 3) 100%;
    animation: l7 1s infinite linear;
  }
  @keyframes l7 {
    33% {
      background-size:
        calc(100% / 3) 0%,
        calc(100% / 3) 100%,
        calc(100% / 3) 100%;
    }
    50% {
      background-size:
        calc(100% / 3) 100%,
        calc(100% / 3) 0%,
        calc(100% / 3) 100%;
    }
    66% {
      background-size:
        calc(100% / 3) 100%,
        calc(100% / 3) 100%,
        calc(100% / 3) 0%;
    }
  }
  </style>
