const CompanyView = () => import('../views/CompanyView.vue')
const CompanyProfileView = () => import('../views/CompanyProfileView.vue')
const CompanyProjectsView = () => import('../views/CompanyProjectsView.vue')
const Members = () => import('../views/CompanyMembersView.vue')

export default [
  {
    path: 'company',
    name: 'company',
    redirect: { name: 'companyProfile' },
    component: CompanyView,
    children: [
      {
        path: 'members',
        name: 'companyMembers',
        component: Members
      },
      {
        path: 'profile',
        name: 'companyProfile',
        component: CompanyProfileView
      },
      {
        path: 'projects',
        name: 'companyProjects',
        component: CompanyProjectsView
      }
    ]
  }
]
