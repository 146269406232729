<template>
  <el-input
    v-model="inputValue"
    type="textarea"
    :autosize="autosize"
    :class="inputStyles"
    :placeholder="placeholder"
    :filled="!!inputValue"
    @focus.native="inputFocus"
    @blur.native="loseFocus"
    @input.native="onInput"
    @keyup.native="$emit('keyup', $event)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'default'
    },

    size: {
      type: String,
      default: 'sm'
    },

    autosize: {
      type: null,
      default: null
    },

    disableUserResize: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      focus: false
    }
  },

  computed: {
    inputValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    inputStyles () {
      const classes = [
        this.$style.inputDefault,
        `cf-text__${this.size}`,
        'cf-color__grey'
      ]
      if (this.disableUserResize) classes.push(this.$style.disableUserResize)
      return classes
    }
  },

  methods: {
    inputFocus () {
      this.focus = true
    },
    loseFocus () {
      this.$emit('loseFocus')
      this.focus = false
    },
    onInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss" module>
.inputDefault {
  & textarea {
    // -webkit-text-fill-color: getColor('black');
    transition: 0.3s ease-in-out;
    border: none;
    padding: 0;

    &:hover {
      -webkit-text-fill-color: getColor('black');
    }
    &:focus {
      -webkit-text-fill-color: getColor('black');
    }
    &[filled] {
      -webkit-text-fill-color: getColor('black');
    }
    &::placeholder {
      color: getColor('grey') !important;
      opacity: 0.9;
      font-style: italic;
      font-size: inherit;

    }
  }
}

.disableUserResize {
  & textarea {
    resize: none;
  }
}
</style>
