import Vue from 'vue'
import Router from 'vue-router'
import Auth from '../plugins/auth'
import store from '../store'

import WorkspaceRoutes from '@/modules/workspace/routes'
import AuthRoutes from '@/modules/auth/routes'
import OnboardingRoutes from '@/modules/onboarding/routes'
import AdminRoutes from '@/modules/admin/routes'
import ToolsRoutes from '@/modules/tools/routes'
import AiRoutes from '@/modules/AI/routes'

import cookiesFunctions from '@/utils/cookiesFunctions'

import { setLocale } from '@/plugins/i18n'

const TestView = () => import('@/TestView.vue')
const MaintenanceView = () => import('@/MaintenanceView.vue')

Vue.use(Router)

function redirectToAuthenticate (to, from, next) {
  const { clientId, clientUser } = to.query
  const query = Object.assign(
    {},
    { clientId, clientUser },
    { redirect: to.fullPath }
  )
  next({ name: 'Authenticate', query })
}

async function needsAuth (to, from, next) {
  try {
    await Auth.currentSession()
  } catch (err) {
    // permite utilizar hardcoded_user sem sessão ativa do cognito
    if (cookiesFunctions.getCookie('hardcoded_user')) return false

    redirectToAuthenticate(to, from, next)

    return true
  }
  return false
}

async function needsUserData (to, from, next) {
  if (!store.state.loggedUser) await store.dispatch('getLoggedUserAction')
  if (!store.state.loggedUser) {
    redirectToAuthenticate(to, from, next)
    return true
  }

  if (store.state.loggedUser.localeId) { await setLocale(store.state.loggedUser.localeId) }

  if (store.state.loggedUser.isAdmin) {
    const isAdminRoute = to.matched.some((route) => route.name === 'admin')
    if (!isAdminRoute) {
      next({ name: 'admin' })
      return true
    }
  }
  return false
}

async function needsAcceptedPolicies (to, from, next) {
  if (!store.state.loggedUser.acceptedPolicies) {
    next({ name: 'acceptPolicies', query: { redirect: to.fullPath } })
    return true
  }
  return false
}

async function needsAdmin (to, from, next) {
  if (!store.state.loggedUser.isAdmin) {
    next({ name: 'workspace' })
    return true
  }
  return false
}

async function needsProfile (to, from, next) {
  if (!store.state.loggedUser.confirmedRegister) {
    next({ name: 'onboarding', query: { redirect: to.fullPath } })
    return true
  }
  return false
}

async function needsCompany (to, from, next) {
  if (!store.state.loggedUser.company) {
    next({ name: 'OnboardingCompany', query: { redirect: to.fullPath } })
    return true
  }
  return false
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...AuthRoutes,
    ...WorkspaceRoutes,
    ...OnboardingRoutes,
    ...AdminRoutes,
    ...ToolsRoutes,
    ...AiRoutes,
    {
      path: '/test',
      component: TestView
    },
    {
      path: '/maintenance',
      component: MaintenanceView
    },
    {
      path: '/issues-report',
      name: 'projectIssuesReport',
      component: () => import('@/modules/workspace/modules/project/modules/issues/views/IssuesReportView.vue')

    },
    { path: '', redirect: { name: 'workspace' } }
  ]
})

router.beforeEach(async (to, from, next) => {
  try {
    const locale = to.query.locale
    if (locale) {
      localStorage.setItem('locale', locale)
    }

    const wsSessionId = to.query.wsSessionId
    if (wsSessionId) {
      sessionStorage.setItem('wsSessionId', wsSessionId)
    }
    store.state.routerLoading = true
    if (to.matched.some((route) => route.meta.needsAuth)) {
      await needsAuth(to, from, next)
    }
    if (to.matched.some((route) => route.meta.needsUserData)) {
      await needsAuth(to, from, next)
      await needsUserData(to, from, next)
    }
    if (to.matched.some((route) => route.meta.needsAdmin)) {
      if (
        (await needsAuth(to, from, next)) ||
        (await needsUserData(to, from, next)) ||
        (await needsAdmin(to, from, next))
      ) { return }
    }
    if (to.matched.some((route) => route.meta.needsProfile)) {
      if (
        (await needsAuth(to, from, next)) ||
        (await needsUserData(to, from, next)) ||
        (await needsAcceptedPolicies(to, from, next)) ||
        (await needsProfile(to, from, next))
      ) { return }
    }
    if (to.matched.some((route) => route.meta.needsCompany)) {
      if (
        (await needsAuth(to, from, next)) ||
        (await needsUserData(to, from, next)) ||
        (await needsProfile(to, from, next)) ||
        (await needsAcceptedPolicies(to, from, next)) ||
        (await needsCompany(to, from, next))
      ) { return }
    }
    next()
  } finally {
    store.state.routerLoading = false
  }
})

export default router
