<template>
  <div
    v-if="hasImages"
    style="position: relative"
    :class="$style.container"
  >
    <div :class="$style.carousel">
      <div :class="$style.arrowContainer">
        <div :class="$style.leftArrowContainer">
          <cf-icon
            v-if="images.length > 1"
            icon="arrow-left"
            color="primary"
            clickable
            @click="previousImage"
          />
        </div>
      </div>
      <ImageEditor
        v-if="selectedImage.type === 'image'"
        :value="selectedImage"
      />

      <div
        v-else-if="selectedImage.type === 'gif'"
        style="
          display: grid;
          align-content: center;
          justify-content: center;
          min-width: 100%;
          min-height: 100%;
          max-width: 100%;
          max-height: 100%;
        "
      >
        <img
          :src="selectedImage.original"
          style="max-width: 100%; max-height: 100%; overflow: hidden"
        >
      </div>

      <video
        v-else-if="selectedImage.type === 'media'"
        :src="selectedImage.original"
        preload="metadata"
        controls
        :class="$style.videoPlayer"
      >
        Seu navegador não suporta vídeos incorporados, mas você pode
        <a :href="selectedImage.original">baixá-lo</a>
        e assistir pelo seu reprodutor de mídia favorito!
      </video>

      <div
        v-else
        :class="$style.genericAttachmentContainer"
      >
        <div style="text-align: center">
          <cf-span>Visualização indisponível</cf-span>
          <cf-span
            clickable
            @click="openFile(selectedImage.original)"
          >
            Clique aqui para abrir
          </cf-span>
        </div>
      </div>
      <div :class="$style.arrowContainer">
        <div :class="$style.rightArrowContainer">
          <cf-icon
            v-if="images.length > 1"
            icon="arrow-right"
            color="primary"
            clickable
            @click="nextImage"
          />
        </div>
        <div
          v-if="selectedImage.type === 'image'"
          :class="$style.imageMenu"
        >
          <slot name="buttons" />
          <cf-tooltip>
            <cf-span>{{ $t('copyToClipboard') }}</cf-span>
            <template #reference>
              <cf-icon
                icon="copy"
                color="primary"
                clickable
                @click="copyImageToClipboard"
              />
            </template>
          </cf-tooltip>
          <cf-tooltip>
            <cf-span>{{ $t('download') }}</cf-span>
            <template #reference>
              <a
                :href="selectedImage.markedUp"
                style="text-decoration: none"
              >
                <cf-icon
                  icon="download"
                  color="primary"
                  clickable
                />
              </a>
            </template>
          </cf-tooltip>
        </div>
      </div>
    </div>
    <ol :class="$style.carouselThumbs">
      <div
        v-for="image in orderedImages"
        :key="image.index"
        style="
          display: grid;
          grid-template: min-content min-content / 8rem;
          row-gap: 1rem;
        "
      >
        <li
          :class="
            findIndex(image.index)
              ? $style.carouselIndicator__active
              : $style.carouselIndicator
          "
          @click="manuallySwitch(image.index)"
        >
          <img
            v-if="image.type === 'image'"
            :class="findIndex(image.index) ? $style.thumbActive : ''"
            :src="image.markedUpThumb || image.markedUp"
          >
          <cf-span
            v-else-if="image.type === 'media'"
            color="grey"
            class="icon-play-circle"
            size="xl"
          />
          <cf-span
            v-else
            color="grey"
          >
            {{ image.type }}
          </cf-span>
        </li>
        <cf-tooltip>
          <cf-span
            multiline
            size="2xs"
            color="grey"
          >
            {{ image.title }}
          </cf-span>
          <template #reference>
            <cf-span
              size="2xs"
              color="grey"
              style="text-align: center"
            >
              {{ image.title }}
            </cf-span>
          </template>
        </cf-tooltip>
      </div>
    </ol>
  </div>
  <div
    v-else
    style="display: grid; align-content: center; justify-content: center"
  >
    <cf-span>{{ $t('noAttachments') }}</cf-span>
  </div>
</template>
<script>
import cfTooltip from './cf-tooltip.vue'
import ImageEditor from '@/components/images-handler/image-editor'
import imgFunctions from '../utils/ImgFunctions'

export default {
  components: { cfTooltip, ImageEditor },
  props: {
    images: {
      type: Array,
      default: null
    },
    carouselHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      selectedImageIndex: 0,
      isActive: true
    }
  },
  computed: {
    orderedImages () {
      const imgs = JSON.parse(JSON.stringify(this.images))
      imgs.forEach((img) => {
        img.type = imgFunctions.getFileType(img.original)
      })
      if (imgs) {
        imgs.sort((a, b) => a.index - b.index)
        imgs.forEach((image, index) => {
          image.index = index
        })
      }
      return imgs
    },
    hasImages () {
      // Verifica se o objeto images possuem alguma imagem ou está vazio
      if (this.images && this.images[this.selectedImageIndex]) {
        return true
      } else {
        return false // Se não houver, esconde o componente
      }
    },
    getImgTitle () {
      if (this.orderedImages[this.selectedImageIndex].title) {
        return this.orderedImages[this.selectedImageIndex].title
      } else {
        return ' '
      }
    },
    selectedImage () {
      const image = this.orderedImages[this.selectedImageIndex]
      this.$emit('selectedImage', this.selectedImageIndex)
      return {
        ...image,
        images: [
          { url: image.original + '?x-request=anonymous', title: image.title }
        ],
        state: image.markUp,
        viewpoint: image.viewpoint
      }
    }
  },

  methods: {
    findIndex (index) {
      // Sincroniza imagens do preview com a do carrousel através do index
      if (index === this.selectedImageIndex) {
        return true
      } else {
        return false
      }
    },
    manuallySwitch (index) {
      // Altera a imagem do carrousel através do preview de imagens
      this.selectedImageIndex = index
    },
    previousImage () {
      if (this.selectedImageIndex <= 0) { this.selectedImageIndex = this.images.length - 1 } else this.selectedImageIndex--
    },
    nextImage () {
      if (this.selectedImageIndex >= this.images.length - 1) { this.selectedImageIndex = 0 } else this.selectedImageIndex++
    },
    copyImageToClipboard () {
      imgFunctions.copyImageToClipboard(
        this.selectedImage.markedUp + '?x-request=anonymous'
      )
    },
    openFile (url) {
      window.open(url, '_blank').focus()
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: grid;
  grid-template-rows: 1fr auto;
  row-gap: 2rem;
  margin-bottom: 2rem;
  overflow: hidden;
}
.carousel {
  margin: 0 2rem 0;
  padding: 0.5rem;
  display: grid;
  grid-template: 1fr / min-content 1fr min-content;
  // justify-items: center;
  // align-items: center;
  overflow: hidden;

  &:hover {
    // box-shadow: 0 0.4rem 0.8rem rgba(30, 60, 90, 0.2);
    & .arrowContainer {
      visibility: visible;
    }
  }
  @include device(mobile) {
    margin: 0;
    padding: 0;
  }
}
.arrowContainer {
  position: relative;
  visibility: visible;
}

.leftArrowContainer {
  position: absolute;
  left: 2.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 100;
}
.rightArrowContainer {
  position: absolute;
  right: 2.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 100;
}
.imageMenu {
  position: absolute;
  right: 2.5rem;
  top: 1rem;
  display: flex;
  flex-direction: row;
  z-index: 100;
  column-gap: 0.5rem;
}
.carouselThumbs {
  display: grid;
  grid-template-rows: min-content;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  align-items: start;
  max-width: 90%;
  overflow: auto;
  justify-self: center;
  column-gap: 1rem;
}

.carouselIndicator {
  position: relative;
  display: flex;
  width: 7.5rem;
  height: 5rem;
  margin-right: 1rem;
  border: 0.2rem solid getColor('grey-lighter');
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  &:hover {
    opacity: 0.75;
    cursor: pointer;
  }
  &__active {
    @extend .carouselIndicator;
    border: 0.2rem solid getColor('primary');
  }
}
.carouselIndicator img {
  max-width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  object-fit: contain;
}

.genericAttachmentContainer {
  display: grid;
  grid-template: 1fr / 1fr;
  align-items: center;
  justify-items: center;
}

.videoPlayer {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;

  overflow: hidden;
}
</style>
