<template>
  <cf-tooltip>
    <cf-span
      size="sm"
      semibold
      multiline
    >
      {{ name }}
    </cf-span>
    <template #reference>
      <div :class="$style.projectCard__heading">
        <cf-span
          size="sm"
          semibold
        >
          {{ name }}
        </cf-span>
      </div>
    </template>
  </cf-tooltip>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" module>
.projectCard__heading {
  height: 2.5rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  align-items: center;
}
</style>

<style lang="scss" scoped>
::v-deep .el-badge__content {
  font-size: 0.9rem;
  height: 1.8rem;
  line-height: 2rem;
  padding: 0 0.6rem;
}
</style>
