import apollo from '@/plugins/apollo'
import userQuery from './graphql/queries/user.gql'
import requestDemoAccessQuery from './graphql/queries/requestDemoAccess.gql'

const getLoggedUser = async () => {
  const response = await apollo.query({
    query: userQuery,
    fetchPolicy: 'network-only'
  })

  return response.data
}

const requestDemoAccess = async ({ name, lastName, company, email, phone }) => {
  const variables = { name, lastName, company, email, phone }
  const response = await apollo.query({
    query: requestDemoAccessQuery,
    fetchPolicy: 'network-only',
    variables
  })

  return response.data.requestDemoAccess
}

export default { getLoggedUser, requestDemoAccess }
