import apollo from '@/plugins/apollo'

import emitBrowserCommandMutation from './graphql/mutations/emitBrowserCommand.gql'

const emitBrowserCommand = async (variables) => {
  const response = await apollo.mutate({
    mutation: emitBrowserCommandMutation,
    variables
  })
  return response.data
}

export { emitBrowserCommand }
