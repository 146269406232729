<template>
  <el-badge
    :value="value"
    :max="max"
    :hidden="hide"
  >
    <slot />
  </el-badge>
</template>

<script>
export default {
  props: {
    value: undefined,
    max: {
      type: Number,
      default: 99
    },
    hidden: undefined
  },
  computed: {
    hide () {
      if (this.value <= 0) return true
      return this.hidden
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-badge__content {
  font-size: 0.9rem;
  height: 1.8rem;
  line-height: 2rem;
  padding: 0 0.6rem;
}
</style>
