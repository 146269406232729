<template>
  <div :class="$style.layout">
    <cf-span color="white">
      {{ $t('newFeature') }}
    </cf-span>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" module>
.layout {
  background-color: getColor('primary');
  padding: 0.1rem 0.5rem 0 0.5rem;
  border-radius: 0.5rem;
}
</style>
