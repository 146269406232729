import './utils/mobileHeight'

import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from './store'
import VueApollo from 'vue-apollo'
import VueGtag from 'vue-gtag'

import { i18n } from './plugins/i18n'
import './plugins/vuemq'
import './plugins/vmask'
import './plugins/element'
import './plugins/auth'
import './plugins/zendesk'
import apolloClient from './plugins/apollo'
import './styles/Construflow_Icons/style.css'

import ModuleStylesMixin from '@/mixins/module-styles-mixin'
import BreakpointsMixin from '@/mixins/breakpoints-mixin'
import remToPx from '@/mixins/rem-to-px-mixin'

import Vue2TouchEvents from 'vue2-touch-events'
import importConstruflowComponents from '@/components'
import VueKonva from 'vue-konva'
import VueZoomer from 'vue-zoomer'
// import { ApmVuePlugin } from '@elastic/apm-rum-vue'

Vue.use(VueGtag, {
  config: { id: 'G-D3F3PWHNB4' }
})

importConstruflowComponents()

Vue.use(Vue2TouchEvents, {
  touchHoldTolerance: 400,
  disableClick: true
})
Vue.use(VueKonva)
Vue.mixin(ModuleStylesMixin)
Vue.mixin(BreakpointsMixin)
Vue.mixin(remToPx)
Vue.use(VueZoomer)
Vue.use(VueApollo)

Vue.config.productionTip = false

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

new Vue({
  apolloProvider,
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
