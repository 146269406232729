<template>
  <span
    :class="classes"
    @click="clicked"
  >
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'sm'
    },
    color: {
      type: String,
      default: 'black'
    },
    italic: Boolean,
    semibold: Boolean,
    multiline: Boolean,
    uppercase: Boolean,
    lowercase: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes: function () {
      const classes = []
      classes.push(`cf-text__${this.size}`)
      classes.push('cf-color__' + this.color)
      classes.push(this.$style.keepAll)
      if (this.semibold) classes.push('cf-text__semibold')
      if (this.italic) classes.push('cf-text__italic')
      if (this.uppercase) classes.push('cf-text__uppercase')
      if (this.lowercase) classes.push('cf-text__lowercase')
      if (!this.multiline) classes.push(this.$style.ellipsisOverflow)
      if (this.clickable) classes.push(this.$style.clickable)
      if (this.disabled) classes.push(this.$style.disabled)
      if (this.icon) classes.push(this.$style.icon)
      return classes
    }
  },
  methods: {
    clicked (e) {
      if (!this.disabled) this.$emit('click', e)
    }
  }
}
</script>
<style lang="scss" module>
.ellipsisOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}

.keepAll {
  word-break: keep-all;
}

.icon {
  line-height: 100%;
}
</style>
